import Unit from '@/globals/javascript/models/proUnit/Unit'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import buildingTypeCodes from '@/globals/database/building-type-codes.json'
import ownerAssociations from '@/globals/database/owner-associations.json'
import { translationsStore } from '@/store'

interface IBuildingManualData {
  title: string
  cadastralNumber: string
  landOwnerAssosiationName: string
  yearBuilt: string
  remodelingYear: string
  builtArea: string
  totalLivingArea: string
  totalBusinessArea: string
  basementArea: string
  utilizedAtticArea: string
  buildingTypeCode: string | number
}

interface IBuildingConstructor {
  id: string
  isSelected: boolean
  bbrID: string | null
  bbrData: unknown
  type: string
  bbrFloors: string[]
  manualData: IBuildingManualData
  floorIDs: string[]
  apartmentIDs: string[]
}

export default class Building extends Unit {
  // Building
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bbrFloors: any[] = []
  data = {}
  floorIDs: string[] = []
  apartmentIDs: string[] = []

  // Manual data
  manualData = {} as IBuildingManualData

  constructor(
    {
      id,
      isSelected,
      bbrID,
      bbrData,
      type,
      bbrFloors,
      manualData,
      floorIDs,
      apartmentIDs,
    }: IBuildingConstructor,
    projectID: string
  ) {
    super({
      id,
      projectID,
      isSelected,
      bbrID,
      bbrData,
      type,
    })

    this.bbrFloors = bbrFloors
    this.manualData = {
      ...manualData,
    }
    this.floorIDs = floorIDs
    this.apartmentIDs = apartmentIDs
  }

  getTitle() {
    if (this.bbrID) {
      return `${mixWB('BUILDING')} ${this.bbrData.Bygningsnr}`
    }
    if (this.manualData.title) {
      return this.manualData.title
    }
    return mixWB('UNKNOWN')
  }

  getFullTitle() {
    return this.getTitle()
  }

  getShortTitle() {
    if (this.bbrID) {
      return `B${this.bbrData.Bygningsnr}`
    }
    if (this.manualData.title) {
      return this.manualData.title
    }
    return 'N/A'
  }

  getBuildingID() {
    return this.id
  }

  getProfileImages() {
    const addressImage = this.getAddressImage()

    if (!addressImage) {
      return []
    }

    return addressImage.units[this.id].profile ?? []
  }

  getBuildingTypeCode() {
    if (this.bbrID) {
      return this.bbrData.BYG_ANVEND_KODE
    }
    if (this.manualData.buildingTypeCode) {
      return this.manualData.buildingTypeCode
    }
    return null
  }

  getBuildingTypeCodeText() {
    const { selectedLanguage } = translationsStore()

    if (!selectedLanguage.countryCode) {
      return ''
    }

    const countryBuildingCodes = buildingTypeCodes[selectedLanguage.countryCode]
    if (!countryBuildingCodes) {
      return ''
    }

    if (this.bbrID) {
      return countryBuildingCodes[this.bbrData.BYG_ANVEND_KODE]
    }

    if (this.manualData.buildingTypeCode) {
      return countryBuildingCodes[this.manualData.buildingTypeCode] || null
    }

    return null
  }

  getLandRegistryNumber(): string | null {
    return this.bbrData?.MatrNr ?? null
  }

  getLandOwnerAssosiationCode(): number | null {
    return this.bbrData?.Landsejerlavkode ?? null
  }

  getLandOwnerAssosiationName() {
    if (this.bbrID) {
      return ownerAssociations[this.bbrData.Landsejerlavkode]
    }
    if (this.manualData.landOwnerAssosiationName) {
      return this.manualData.landOwnerAssosiationName
    }
    return null
  }

  getYearBuilt() {
    if (this.bbrID) {
      return this.bbrData.OPFOERELSE_AAR
    }
    if (this.manualData.yearBuilt) {
      return this.manualData.yearBuilt
    }
    return null
  }

  getRemodelingYear() {
    if (this.bbrID) {
      return this.bbrData.OMBYG_AAR
    }
    if (this.manualData.remodelingYear) {
      return this.manualData.remodelingYear
    }
    return null
  }

  getBuiltArea() {
    if (this.bbrID) {
      return `${this.bbrData.BYG_BEBYG_ARL} ${mixWB('M2')}`
    }

    if (this.manualData.builtArea) {
      return `${this.manualData.builtArea} ${mixWB('M2')}`
    }

    return null
  }

  getTotalLivingArea() {
    if (this.bbrID && this.bbrData.BYG_BOLIG_ARL_SAML) {
      return `${this.bbrData.BYG_BOLIG_ARL_SAML} ${mixWB('M2')}`
    }

    if (this.manualData.totalLivingArea) {
      return `${this.manualData.totalLivingArea} ${mixWB('M2')}`
    }

    return null
  }

  getTotalBusinessArea() {
    if (this.bbrID) {
      return `${this.bbrData.ERHV_ARL_SAML} ${mixWB('M2')}`
    }

    if (this.manualData.totalBusinessArea) {
      return `${this.manualData.totalBusinessArea} ${mixWB('M2')}`
    }

    return null
  }

  getBasementArea() {
    if (this.bbrID && this.bbrFloors.length) {
      const basement = this.bbrFloors.find((x) => x.Etagebetegn === 'KL')

      if (!basement || !basement.SamletAreal) {
        return null
      }

      return `${basement.SamletAreal} ${mixWB('M2')}`
    }

    if (this.manualData.basementArea) {
      return `${this.manualData.basementArea} ${mixWB('M2')}`
    }

    return null
  }

  getUtilizedAtticArea() {
    if (this.bbrID && this.bbrFloors.length) {
      const attic = this.bbrFloors.find((x) => x.TAGETAGE_ARL_UDNYT > 0)

      if (!attic || !attic.TAGETAGE_ARL_UDNYT) {
        return null
      }

      return `${attic.TAGETAGE_ARL_UDNYT} ${mixWB('M2')}`
    }
    if (this.manualData.utilizedAtticArea) {
      return `${this.manualData.utilizedAtticArea} ${mixWB('M2')}`
    }
    return null
  }
}
