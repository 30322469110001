/* eslint-disable no-use-before-define */
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import {
  getOnlineTypesSumData,
  getOTypeCountText,
} from '@/globals/javascript/_utils/pane-util'
import { uniqueStrings } from '@/globals/javascript/_utils/util'
import { IFractionGroup } from '@/__types__/fractionGroup'
import { orderBy } from 'lodash-es'

export const compileFractionGroups = (
  onlineTypes: OnlineType[]
): {
  all: IFractionGroup[]
  unknown: IFractionGroup[]
  prevention: IFractionGroup[]
  reuse: IFractionGroup[]
  remaining: IFractionGroup[]
  resale: IFractionGroup[]
} => {
  const unknownID = 'F-unknown'
  const preventionID = 'F-prevention'
  const reuseID = 'F-reuse'

  const usedIDs = onlineTypes
    .map((oType) => oType.fraction?.id ?? '')
    .filter(uniqueStrings)
    .filter(Boolean)

  const staticIDs = [unknownID, preventionID, reuseID]
  const ids = [...staticIDs, ...usedIDs]

  const onlineTypesCollection =
    compileFractionOnlineTypeCollections(onlineTypes)

  const { all, prevention, unknown, reuse, remaining, resale } = ids.reduce(
    (prev, id) => {
      let oTypes = [] as OnlineType[]

      // Online type based fraction grouping or fraction ID
      switch (id) {
        case preventionID:
          oTypes = onlineTypesCollection.prevention
          break

        case reuseID:
          oTypes = onlineTypesCollection.reuse
          break

        case unknownID:
          oTypes = onlineTypesCollection.unknown
          break

        default:
          oTypes = onlineTypesCollection.remaining.filter(
            (x) => x.fractionID === id
          )
          break
      }

      // Get current fractions resale online types
      const resaleOnlineTypes = oTypes.filter((x) => x.options.isRecycable)

      // Build fraction group
      const fractionGroup = compileFractionGroup(id, oTypes)

      // Add fraction group to corresponding selection
      if (oTypes.length) {
        prev.all.push(fractionGroup)
      }

      // If resale item present add to resale collection
      if (resaleOnlineTypes.length) {
        prev.resale.push(compileFractionGroup(id, resaleOnlineTypes))
      }

      // Add to static fractions
      if (id === unknownID) {
        prev.unknown.push(fractionGroup)
        return prev
      }

      if (id === preventionID) {
        prev.prevention.push(fractionGroup)
        return prev
      }

      if (id === reuseID) {
        prev.reuse.push(fractionGroup)
        return prev
      }

      // Add to dynamic fraction (based on material)
      prev.remaining.push(fractionGroup)
      return prev
    },
    {
      all: [] as IFractionGroup[],
      unknown: [] as IFractionGroup[],
      prevention: [] as IFractionGroup[],
      reuse: [] as IFractionGroup[],
      remaining: [] as IFractionGroup[],
      resale: [] as IFractionGroup[],
    }
  )

  return {
    all,
    unknown,
    prevention,
    reuse,
    remaining,
    resale,
  }
}

const compileFractionGroup = (
  fractionID: string,
  onlineTypes: OnlineType[]
) => {
  const { tons, sum } = getOnlineTypesSumData(onlineTypes)

  // Build fraction group
  const fractionGroup: IFractionGroup = {
    title: onlineTypes[0]?.titles.fraction,
    hashID: fractionID,
    oTypes: orderBy(
      onlineTypes,
      ['index.primary', 'index.secondary', 'title'],
      'asc'
    ),
    countText: getOTypeCountText(onlineTypes),
    tons: tons,
    disposalCost: sum,
  }

  return fractionGroup
}

const compileFractionOnlineTypeCollections = (onlineTypes: OnlineType[]) => {
  return onlineTypes.reduce(
    (prev, oType) => {
      // Prevention fraction
      if (oType.courseOfAction === 'prevention') {
        prev.prevention.push(oType)
        return prev
      }

      // Reuse fraction
      if (oType.courseOfAction === 'reuse') {
        prev.reuse.push(oType)
        return prev
      }

      // Unknown fraction
      if (oType.courseOfAction === 'demolition' && !oType.fraction) {
        prev.unknown.push(oType)
        return prev
      }

      // All other onlineTypes
      prev.remaining.push(oType)

      return prev
    },
    {
      unknown: [] as OnlineType[],
      prevention: [] as OnlineType[],
      reuse: [] as OnlineType[],
      remaining: [] as OnlineType[],
    }
  )
}
