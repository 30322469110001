import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { orderOTypesByFullTitle } from '@/store/modules/current/helpers/orderOTypesByFullTitle'

export const compileResale = (
  onlineTypes: OnlineType[]
): {
  all: OnlineType[]
  normal: OnlineType[]
  highlighted: OnlineType[]
} => {
  const all: OnlineType[] = []
  const normal: OnlineType[] = []
  const highlighted: OnlineType[] = []

  onlineTypes.forEach((x) => {
    const { isRecycable, isHighlighted } = x.options

    if (!isRecycable || x.courseOfAction === 'prevention') {
      return
    }

    all.push(x)

    isHighlighted ? highlighted.push(x) : normal.push(x)
  })

  return {
    all: orderOTypesByFullTitle(all),
    normal: orderOTypesByFullTitle(normal),
    highlighted: orderOTypesByFullTitle(highlighted),
  }
}
