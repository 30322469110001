import {
  IProRawProjectTestResult,
  IProRawProjectTestResultsItem,
} from '@/__types__/_pro/raw-project/pro-raw-project-testResult'

export default class ProTestResult {
  id
  projectID
  userID
  wdcID
  sampleNumber
  results: IProRawProjectTestResultsItem[]
  metalsAboveThreshold
  uploadDate

  // Outdated
  cpScreeningAutoProven // Only in use on 1.14.0 and older.

  constructor(obj: IProRawProjectTestResult, projectID: string) {
    this.id = obj.id
    this.projectID = projectID
    this.userID = obj.userID
    this.wdcID = obj.wdcID
    this.sampleNumber = obj.sampleNumber
    this.results = obj.results
    this.metalsAboveThreshold = obj.metalsAboveThreshold
    this.cpScreeningAutoProven = obj.cpScreeningAutoProven
    this.uploadDate = obj.uploadDate
  }
}
