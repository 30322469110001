import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { isProCoatingType } from '@/globals/javascript/_utils/util'
import { IInfoText } from '@/__types__/utils/infoText'

/**
 * Returns the comments from all pro types that makes of this online type
 */
export function getTypeComments(this: OnlineType): IInfoText[] {
  return this.types.reduce((prev, pType, index, arr) => {
    // If no comments available
    if (!pType.comments) {
      return prev
    }

    // Handle coating comments - combine title with origin ProType
    if (isProCoatingType(pType)) {
      // Ignore coating comments if the coatings origin material is in same array
      if (arr.find((x) => x.originID === pType.id)) {
        return prev
      }

      // Retrive origin proType
      const proType = arr.find((x) => x.id === pType.originID)

      // Add header
      prev.push({
        type: 'header',
        text: proType
          ? `${proType.getTitle()} + ${pType.getTitle()}`
          : pType.getTitle(),
      })

      prev.push({
        text: pType.comments,
      })

      return prev
    }

    // Add comment from normal pro type
    prev.push({
      type: 'header',
      text: pType.getTitle(),
    })
    prev.push({
      text: pType.comments,
    })

    return prev
  }, [] as IInfoText[])
}
