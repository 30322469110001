import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { isProType } from '@/globals/javascript/_utils/util'

export function setRecycableState(this: OnlineType) {
  this.options.isRecycable = false

  const { hasMissingTestResults, isPotentiallyContaminated } = this.options

  // Is potentially contaminated
  if (isPotentiallyContaminated) {
    return
  }

  // Missing testresulst
  if (hasMissingTestResults) {
    return
  }

  // Is choosen to stay in the building
  if (this.courseOfAction === 'prevention') {
    return
  }

  // Contains asbestos
  if (['WDC-4', 'WDC-5'].includes(this.finalWDCID)) {
    return
  }

  // Check special cases
  // - Soot
  // if (this.specialCases.find((x) => x.caseID === 'special-case-6')) {
  if (
    this.specialCases.find((x) =>
      ['special-case-6', 'special-case-11'].includes(x.caseID)
    )
  ) {
    return
  }

  // Blacklisted sample types (assessed, existing or postponed)
  const blacklistedSamples = this.samples.filter((x) =>
    ['assessed', 'existing', 'postponed'].includes(x.sampleType)
  )
  if (blacklistedSamples.length) {
    return
  }

  // Chcking proTypes
  const proTypes = this.types.filter((x) => isProType(x)) as ProType[]

  // If only coating
  if (proTypes.length === 0) {
    return
  }

  // Set default protype
  let proType = proTypes[0]

  // If multiple protypes present
  if (proTypes.length > 1) {
    const { normalPTypes, neverAlonePTypes } = proTypes.reduce(
      (prev, pType) => {
        pType.options.isNeverAlone
          ? prev.neverAlonePTypes.push(pType)
          : prev.normalPTypes.push(pType)

        return prev
      },
      {
        normalPTypes: [] as ProType[],
        neverAlonePTypes: [] as ProType[],
      }
    )

    // Only allow if 1 normal pType
    if (normalPTypes.length !== 1) {
      return
    }

    // Ensure the normal pType is cleanable
    if (normalPTypes[0].getWDGID() !== 'WDG-2') {
      return
    }

    // Check that all neverAlone pTypes is plaster or screed
    // MORTAR ISSUE (T-87): Only works as intended if mortar is clean
    const allGood = neverAlonePTypes.every((pType) => {
      const rType = pType.getResourceType()
      return ['T-56', 'T-12', 'T-87'].includes(rType?.id || '')
    })

    if (!allGood) {
      return
    }

    // Set protype from multitype
    proType = normalPTypes[0]
  }

  // Is marked as non-recycable
  const isNotRecyclable =
    proType.getResourceType()?.options.isNotRecyclable ?? false

  if (isNotRecyclable) {
    return
  }

  // Check test results
  const {
    hasHazardousPCB,
    isPAHAboveContaminatedLevel,
    isCHAboveContaminatedLevel,
  } = this.samples.reduce(
    (prev, sample) => {
      const results = sample.getAnalysisTestListResults()

      // Check PCB
      const PCBTest = results.find((x) => x.id === 'PCB')
      if (PCBTest?.result === 2) {
        prev.hasHazardousPCB = true
      }

      // Check PAH
      const PAHTest = results.find((x) => x.id === 'PAH')
      if ((PAHTest?.result ?? NaN) > 0) {
        prev.isPAHAboveContaminatedLevel = true
      }

      // Check CH
      const CHTest = results.find((x) => x.id === 'CH')
      if ((CHTest?.result ?? NaN) > 0) {
        prev.isCHAboveContaminatedLevel = true
      }

      return prev
    },
    {
      hasHazardousPCB: false,
      isPAHAboveContaminatedLevel: false,
      isCHAboveContaminatedLevel: false,
    }
  )

  if (hasHazardousPCB) {
    return
  }

  if (isPAHAboveContaminatedLevel && isCHAboveContaminatedLevel) {
    this.recycleCases.push('recycle-case-pah-and-ch')
  } else if (isPAHAboveContaminatedLevel) {
    this.recycleCases.push('recycle-case-pah')
  } else if (isCHAboveContaminatedLevel) {
    this.recycleCases.push('recycle-case-ch')
  }

  this.options.isRecycable = true
  this.options.isHighlighted = proTypes.some(
    (p) => p.recycling?.isHighlighted ?? false
  )
}
