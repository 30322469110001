import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { proProjectsStore } from '@/store'

export default class ProSplitSample extends ProSample {
  data = {} as ProSample
  sampleIDs = [] as string[]
  originalTypeID = ''

  constructor(
    data: ProSample,
    projectID: string,
    sampleIDs: string[],
    originalTypeID: string
  ) {
    super(data, projectID)
    this.sampleIDs = sampleIDs
    this.originalTypeID = originalTypeID
  }

  setWebData() {
    this.setMissingTestResultsStatus()
    this.setProSplitSampleWDCID()
  }

  setProSplitSampleWDCID() {
    if (this.options.hasMissingTestResult) {
      this.wdcID = ''
      return
    }

    // Standard or equivalent sample
    if (['standard', 'equivalent'].includes(this.sampleType)) {
      // When standard samples status data is available
      const statusListItem = this.getProjectStandardSamplesStatusSampleItem()

      // Set WDC ID for inner adhesive type
      // - Only the adhesive part will ever have asbestos
      if (this.typeID.includes('adhesive-inner')) {
        const wdcID = statusListItem?.wdcID || ''

        if (['WDC-4', 'WDC-5'].includes(wdcID)) {
          this.wdcID = 'WDC-4'
          return
        }

        this.wdcID = 'WDC-1'
        return
      }

      // Set WDC ID for outer adhesive type
      if (this.typeID.includes('adhesive-outer')) {
        const result = statusListItem?.analyses.reduce((prev, analysis) => {
          if (['ASB', 'ASB_GEL_TAPE'].includes(analysis.groupID)) {
            return prev
          }

          if (!analysis.result) {
            return prev
          }

          if (analysis.result > prev) {
            prev = analysis.result
          }

          return prev
        }, 0)

        if (result === 0) {
          this.wdcID = 'WDC-1'
        }
        if (result === 1) {
          this.wdcID = 'WDC-2'
        }
        if (result === 2) {
          this.wdcID = 'WDC-3'
        }
      }

      return
    }

    // Assessment
    if (this.sampleType === 'assessed') {
      const assessedSamplesItem =
        this.getProjectAssessedSamplesStatusSampleItem()

      const wdcID = assessedSamplesItem?.wdcID || ''

      // Set WDC ID for inner adhesive type
      // - Only the adhesive part will ever have asbestos
      if (this.typeID.includes('adhesive-inner')) {
        if (['WDC-4', 'WDC-5'].includes(wdcID)) {
          this.wdcID = 'WDC-4'
          return
        }

        this.wdcID = 'WDC-1'
        return
      }

      // Set WDC ID for outer adhesive type
      if (this.typeID.includes('adhesive-outer')) {
        if (wdcID === 'WDC-5') {
          this.wdcID = 'WDC-3'
          return
        }
        if (wdcID === 'WDC-4') {
          this.wdcID = 'WDC-1'
          return
        }
        this.wdcID = wdcID
      }

      return
    }

    // Existing result
    if (this.sampleType === 'existing') {
      this.wdcID = this.existingResultWDCID
    }
  }

  getProType(useOriginalType = false) {
    const { allPTypes } = proProjectsStore()

    // Get original pType
    if (useOriginalType) {
      return allPTypes.find((x) => x.id === this.originalTypeID) || null
    }

    return allPTypes.find((x) => x.id === this.typeID) || null
  }

  getKindOfSampleText() {
    const { allPTypes } = proProjectsStore()

    let text = ''
    if (this.kindOfSample === 'coating') {
      text = mixWB('COATING')
    }

    text = mixWB('MATERIAL')

    const splitPType = allPTypes.find((x) => x.id === this.typeID)

    return `${text} (${mixWB('ONLY_X', [
      splitPType?.getTitle().toLowerCase() || '',
    ])})`
  }
}
