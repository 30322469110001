/* eslint-disable no-use-before-define */
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import {
  mixFormatNumber,
  mixFormatPrice,
  mixWB,
} from '@/globals/javascript/_utils/mixins'

export const getOTypeCountText = (onlineTypes: OnlineType[]) => {
  let countText = ''

  if (onlineTypes.length === 0) {
    countText = mixWB('NO_TYPES_REGISTERED')
  } else {
    countText =
      onlineTypes.length === 1
        ? mixWB('1_TYPE')
        : mixWB('X_TYPES', [onlineTypes.length.toString()])
  }

  return countText
}

export const getOnlineTypesSumData = (
  onlineTypes: OnlineType[]
): {
  tons: { total: number; hasMissingAmounts: boolean; asText: string }
  sum: { total: number; hasMissingAmounts: boolean; asText: string }
} => {
  const { tons, sum } = onlineTypes.reduce(
    (prev, oType) => {
      if (oType.amounts.totalTons) {
        prev.tons.total += oType.amounts.totalTons
      }

      if (oType.amounts.hasMissingAmounts) {
        prev.tons.hasMissingAmounts = true
      }

      if (oType.disposalCost.totalCost) {
        prev.sum.total += oType.disposalCost.totalCost
      }

      if (oType.disposalCost.hasMissingAmounts) {
        prev.sum.hasMissingAmounts = true
      }

      return prev
    },
    {
      tons: {
        total: 0,
        hasMissingAmounts: false,
        asText: '',
      },
      sum: {
        total: 0,
        hasMissingAmounts: false,
        asText: '',
      },
    }
  )

  tons.asText = getTonsSumAsText(tons)
  sum.asText = getDisposalCostSumAsText(sum)

  return {
    tons,
    sum,
  }
}

type ISumAsText = { total: number; hasMissingAmounts: boolean }
export const getTonsSumAsText = ({ total, hasMissingAmounts }: ISumAsText) => {
  if (!total) {
    return '-'
  }

  if (hasMissingAmounts) {
    return `${mixFormatNumber(total, 3)} *`
  }

  return mixFormatNumber(total, 3).toString()
}

export const getDisposalCostSumAsText = ({
  total,
  hasMissingAmounts,
}: ISumAsText) => {
  if (!total) {
    return '-'
  }

  if (hasMissingAmounts) {
    return `${mixFormatPrice(total)} *`
  }

  return mixFormatPrice(total)
}

// Derive from this guide:
// https://newbedev.com/generate-a-hash-from-string-in-javascript
export const createHashID = (
  prefix: string,
  id: string,
  digetsCount = 5,
  charCount = 2
): string => {
  if (!id) {
    throw new Error('createHashID: ID must be set')
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const mmHash = require('murmurhash-js')
  const hash = mmHash.murmur3(id)
  const digets = hash.toString().substr(0, digetsCount)
  const chars = id.substring(0, charCount).toUpperCase()

  return `${prefix}-${chars}${digets}`
}
