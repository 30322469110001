
import Intro from '@/components/pageGuide/slides/Intro.vue'
import Fractions from '@/components/pageGuide/slides/Fractions.vue'
import Remediations from '@/components/pageGuide/slides/Remediations.vue'
import Types from '@/components/pageGuide/slides/Types.vue'
import Samples from '@/components/pageGuide/slides/Samples.vue'
import Resale from '@/components/pageGuide/slides/Resale.vue'
import Outro from '@/components/pageGuide/slides/Outro.vue'
import { appStore } from '@/store'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import storageKeys from '@/globals/javascript/constants/storageKeys'

export default defineComponent({
  name: 'PageGuide',
  data() {
    return {
      currentIndex: 0,
      endIndex: 0,
      contentHeight: 0,
      slides: {
        items: [] as HTMLElement[],
        count: 0,
      },
    }
  },
  methods: {
    ...mapActions(appStore, ['setShownDialog']),
    goToIndex(index: number) {
      if (index > this.endIndex) {
        this.currentIndex = this.endIndex
        return
      }

      if (index < 0) {
        this.currentIndex = 0
        return
      }

      this.currentIndex = index
    },
    onNextButtonClick() {
      if (this.currentIndex < this.endIndex) {
        this.goToIndex(this.currentIndex + 1)
        return
      }

      localStorage.setItem(storageKeys.LS_PAGE_GUIDE_SEEN, 'true')
      this.setShownDialog()
    },
    onPrevButtonClick() {
      this.goToIndex(this.currentIndex - 1)
    },
    setSlideData() {
      const slides = this.$el.querySelectorAll('.Slide')
      this.slides.items = [...slides]
      this.slides.count = slides.length
      this.endIndex = this.slides.count - 1
    },
  },
  components: {
    Intro,
    Fractions,
    Remediations,
    Types,
    Samples,
    Resale,
    Outro,
  },
  mounted() {
    this.$nextTick(this.setSlideData)
  },
})
