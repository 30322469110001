import { db, PRO_COLLECTIONS } from '@/firebase'
import { doc, getDoc, collection, getDocs } from '@firebase/firestore'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import 'dayjs/locale/da'
import 'dayjs/locale/en'
import {
  ILanguage,
  ITranslationMap,
} from '@/__types__/_pro/translations/translations'

interface ITranslationsState {
  translationsFethched: boolean
  selectedLanguage: ILanguage
  languages: ILanguage[]
  translations: ITranslationMap
  preRenderTranslations: {
    [key: string]: {
      [key: string]: string
    }
  }
}

export default defineStore('translations', {
  state: () =>
    ({
      translationsFethched: false,
      selectedLanguage: {
        id: '',
        countryCode: '',
        locale: '',
        isActive: false,
      },
      languages: [],
      translations: {},
      preRenderTranslations: {
        ONLINE_REPORT: {
          DK: 'Online rapport',
          EN: 'Online report',
        },
        GETTING_DATA: {
          DK: 'Henter data',
          EN: 'Getting data',
        },
        PREPARING_PROJECT: {
          DK: 'Klargører projekt',
          EN: 'Preparing project',
        },
        THE_PAGE_DOES_NOT_EXIST: {
          DK: 'Siden findes ikke',
          EN: 'The page does not exist',
        },
        AN_ERROR_OCCURRED_PLEASE_TRY_AGAIN_LATER: {
          DK: 'Der skete en fejl - prøv igen senere.',
          EN: 'An error occurred - please try again later.',
        },
        SORRY_YOUR_SCREEN_IS_TOO_SMALL: {
          DK: 'Vi beklager, din skærm er for lille',
          EN: 'Sorry, your screen is too small',
        },
        SCREEN_TOO_SMALL_TEXT_1: {
          DK: 'Tilgå Milva Online via en enhed med bredere skærm - læg evt. din enhed ned.',
          EN: 'Access Milva via a broader screen - you may need to flip your device.',
        },
        SCREEN_TOO_SMALL_TEXT_2: {
          DK: 'Tilgå Milva Online via en enhed med bredere skærm.',
          EN: 'Access Milva via a broader screen.',
        },
      },
    } as ITranslationsState),

  actions: {
    // Setters
    setSelectedLanguage(lang: ILanguage) {
      // Set lang in store
      this.$patch({
        selectedLanguage: lang,
      })

      // Set DayJS lang
      const localeForFormat = lang.locale.slice(0, 2)
      dayjs.locale(localeForFormat)
    },

    // Getters
    async getLanguages() {
      const langCol = collection(db, PRO_COLLECTIONS.DB_LANGUAGES)

      const langSnapshot = await getDocs(langCol)

      const languages = langSnapshot.docs.map((x) => x.data())

      this.$patch({
        languages,
      })
    },
    async getTranslations() {
      const docRef = await doc(
        db,
        PRO_COLLECTIONS.DB_TRANSLATIONS,
        this.selectedLanguage.locale
      )

      const data = (await getDoc(docRef)).data() as ITranslationMap

      this.$patch({
        translations: data,
      })

      this.translationsFethched = true
    },
    getTranslationPromise() {
      return new Promise((resolve) => {
        const waitForTranslations = setInterval(() => {
          if (!this.$state.translationsFethched) {
            return
          }

          clearInterval(waitForTranslations)

          resolve(null)
        }, 500)
      })
    },
  },

  getters: {
    activeLanguages(state) {
      return state.languages.filter((lang) => lang.isActive)
    },
  },
})
