import { IFilterQuery } from '@/__types__/filter/filter'
import { isEmpty } from '@firebase/util'
import { isUndefined, omitBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { LocationQuery } from 'vue-router'

interface IFilterState {
  disabled: boolean
  isOpen: boolean
  forcedActive: boolean
  exposeProjectId: boolean
  prestineParams: Partial<IFilterQuery>
  values: IFilterQuery
}

const prestineParams = {
  // using 'undefined' to erase unused params
  unit: 'all',
}

export default defineStore('filter', {
  state: () =>
    ({
      disabled: false,
      isOpen: false,
      forcedActive: false,
      exposeProjectId: false,
      prestineParams,
      values: { projectId: undefined, ...prestineParams },
    } as IFilterState),

  actions: {
    setForcedActive(forcedActiveState: boolean) {
      this.forcedActive = forcedActiveState
    },
    setIsOpen(isOpenState?: boolean) {
      this.isOpen = isOpenState === undefined ? !this.isOpen : isOpenState
    },
    setIsDisabled(isDisabled: boolean) {
      this.disabled = isDisabled
    },
    setInitialProjectID(id: string) {
      this.$state.values.projectId = id
    },
    setValues(routeQuery: LocationQuery, queryParams?: IFilterQuery) {
      const noUndefinedValues = queryParams
        ? (omitBy(queryParams, isUndefined) as unknown as IFilterQuery)
        : {}

      const mergedQuery = {
        ...routeQuery,
        // Avoid unintended undefined overwrites
        ...noUndefinedValues,
      }

      // Update
      this.$patch({
        values: mergedQuery,
      })
    },
  },

  getters: {
    query(state) {
      const values = Object.entries(state.values).reduce(
        (prev, [key, value]) => {
          const typedKey = key as keyof typeof state.values
          if (
            state.values[typedKey] ===
            prestineParams[key as keyof typeof prestineParams]
          ) {
            prev[typedKey] = undefined
            return prev
          }

          prev[typedKey] = value

          return prev
        },
        {} as IFilterQuery
      )

      // remove all undefined values
      return omitBy({ ...values, projectId: undefined }, isUndefined)
    },
    isActive() {
      if (this.forcedActive) {
        return true
      }

      // Only set as active if params related to unit filter is active
      return !isEmpty(omitBy({ ...this.query, lang: undefined }, isUndefined))
    },
  },
})
