import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { isProType } from '@/globals/javascript/_utils/util'
import { resourcesStore } from '@/store'

export function setWDGIDs(this: OnlineType) {
  // When online-type consists of a single pro-type
  if (this.types.length === 1) {
    const onlyType = this.types[0]
    this.innerWDGID = onlyType.getWDGID()
    this.outerWDGID = onlyType.getWDGID()
    this.wdgID = onlyType.getWDGID()
    return
  }

  // When online-type consists of multiple pro types
  // Try and use only non-coating-pro-types
  const proTypes = this.types.filter((x) => isProType(x))

  // Otherwise, when all pro-types are coating
  if (!proTypes.length) {
    const firstPType = this.types[0]
    this.innerWDGID = firstPType.getWDGID()
    this.outerWDGID = firstPType.getWDGID()
    this.wdgID = firstPType.getWDGID()
    return
  }

  // When only a single non-coating pro-type remains
  if (proTypes.length === 1) {
    const firstPType = this.types[0]
    this.innerWDGID = firstPType.getWDGID()
    this.outerWDGID = firstPType.getWDGID()
    this.wdgID = firstPType.getWDGID()
    return
  }

  // When multiple non-coating pro types remains
  const firstPType = this.types[0]
  const lastPType = this.types.slice(-1)[0]

  // Set inner and outer WDG IDs
  this.innerWDGID = firstPType.getWDGID()
  this.outerWDGID = lastPType.getWDGID()

  // Set main WDG ID
  const { wdgAsObject } = resourcesStore()
  let isDustyFound = false

  proTypes.forEach((pType) => {
    if (isDustyFound) {
      return
    }

    const typeWDGID = pType?.getWDGID()
    const wdgObject = wdgAsObject[typeWDGID]

    if (!wdgObject) {
      return
    }

    const { isDusty } = wdgObject.options

    isDustyFound = isDusty
  })

  this.wdgID = isDustyFound ? 'WDG-9' : 'WDG-8'
}
