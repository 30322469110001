
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FeedbackForm',
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hovering: 0,
    }
  },
  methods: {
    setHover(value: number) {
      this.hovering = value
    },
    updateRating(value: number) {
      this.$emit('update:rating', value)
    },
    updateDescription(evt: InputEvent) {
      const target = evt.target as HTMLInputElement
      this.$emit('update:description', target.value ?? '')
    },
  },
})
