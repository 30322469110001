/* eslint-disable no-use-before-define */
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'

export const mergeDuplicates = (onlineTypes: OnlineType[]): OnlineType[] => {
  const reducedOTypes: OnlineType[] = []

  // 1. Filter online types into duplicates and non-duplicates
  const { nonDuplicatedOTypes, duplicatedOTypes } =
    filterDuplicates(onlineTypes)

  // 2. Merge duplicated online types when they share same final WDC-ID
  const mergedOnlineTypesArray: OnlineType[] = []
  Object.values(duplicatedOTypes).forEach((dupArray) => {
    mergedOnlineTypesArray.push(...mergeOnlineTypes(dupArray))
  })

  // 3. Add online types
  reducedOTypes.push(...mergedOnlineTypesArray, ...nonDuplicatedOTypes)

  // 4. Update OnlineType references after merging of dublicates
  updateInterConnectionReferences(mergedOnlineTypesArray, reducedOTypes)

  return reducedOTypes
}

function filterDuplicates(onlineTypes: OnlineType[]) {
  return onlineTypes.reduce(
    (prev, oType) => {
      const isDup = onlineTypes.find(
        (x) => x.id !== oType.id && x.dubID === oType.dubID
      )

      if (!isDup) {
        prev.nonDuplicatedOTypes.push(oType)

        return prev
      }

      const dupArray = prev.duplicatedOTypes[oType.dubID]

      if (dupArray) {
        dupArray.push(oType)
      } else {
        prev.duplicatedOTypes[oType.dubID] = [oType]
      }

      return prev
    },
    {
      nonDuplicatedOTypes: [] as OnlineType[],
      duplicatedOTypes: {} as {
        [key: string]: OnlineType[]
      },
    }
  )
}

function mergeOnlineTypes(dupArray: OnlineType[]) {
  const resultObj = dupArray.reduce((prev, oType) => {
    // 2.1 Set main oType based on WDC-ID
    if (!prev[oType.finalWDCID]) {
      prev[oType.finalWDCID] = oType
      return prev
    }

    const mainOType = prev[oType.finalWDCID]

    if (!mainOType) {
      return prev
    }

    // 2.2 Update main oType with wanted values from duplicated oTypes
    // 2.2.1 Add innerOnlineTypes which is not already present
    oType.innerOnlineTypes.forEach((innerOType) => {
      if (
        !mainOType.innerOnlineTypes.find((x) => x.dubID === innerOType.dubID)
      ) {
        mainOType.innerOnlineTypes.push(innerOType)
      }
    })

    // 2.2.2 Add innerOnlineTypes which is not already present
    oType.outerOnlineTypes.forEach((outerOType) => {
      if (
        !mainOType.outerOnlineTypes.find((x) => x.dubID === outerOType.dubID)
      ) {
        mainOType.outerOnlineTypes.push(outerOType)
      }
    })

    // 2.2.3 Accumulate stack m2
    if (mainOType.stackData?.stackM2 && oType.stackData?.stackM2) {
      mainOType.stackData.stackM2 += oType.stackData.stackM2
    } else {
      mainOType.stackData.stackM2 = NaN
    }

    // 2.2.4 Merge cleaning tasks
    oType.cleaningTasks.forEach((cleaningTask) => {
      // Check if already there
      const existingTask = mainOType.cleaningTasks.find(
        (x) =>
          x.toBeCleaned?.dubID === cleaningTask.toBeCleaned?.dubID &&
          x.toBeRemoved.dubID === cleaningTask.toBeRemoved.dubID
      )

      if (existingTask && existingTask.toBeCleaned?.dubID === mainOType.dubID) {
        existingTask.m2 =
          existingTask.m2 && cleaningTask.m2
            ? (existingTask.m2 += cleaningTask.m2)
            : NaN
      } else if (!existingTask) {
        mainOType.cleaningTasks.push(cleaningTask)
      }
    })

    return prev
  }, {} as { [index: string]: OnlineType })

  // Prev: {'WDC-1': oType, 'WDC-2': oType}

  return Object.values(resultObj)
}

function updateInterConnectionReferences(
  mergedOnlineTypesArray: OnlineType[],
  reducedOTypes: OnlineType[]
) {
  mergedOnlineTypesArray.forEach((oType) => {
    // 4.1 Replace innerOnlineTypes references
    oType.innerOnlineTypes.forEach((innerOType, index) => {
      // Find onlineType with same dublicate ID as one of the innerOnlineTypes
      const replacementOType = reducedOTypes.find(
        (x) => x.dubID === innerOType.dubID
      )

      if (!replacementOType) {
        return
      }

      // Find index of outerOnlineType with same dubID as current mergedOnlineType
      const rIndex = replacementOType.outerOnlineTypes.findIndex(
        (x) => x.dubID === oType.dubID
      )

      // Update replacementOType's outerOnlineTypes with current oType
      replacementOType.outerOnlineTypes.splice(rIndex, 1, oType)
      // Update current oTypes innerOnlineTypes with replacementOType
      oType.innerOnlineTypes.splice(index, 1, replacementOType)
    })

    // 4.2 Replace outerOnlineTypes references
    oType.outerOnlineTypes.forEach((outerOType, index) => {
      // Find onlineType with same dublicate ID as one of the outerOnlineTypes
      const replacementOType = reducedOTypes.find(
        (x) => x.dubID === outerOType.dubID
      )

      if (!replacementOType) {
        return
      }

      // Find index of outerOnlineType with same dubID as current mergedOnlineType
      const rIndex = replacementOType.innerOnlineTypes.findIndex(
        (x) => x.dubID === oType.dubID
      )

      // Update replacementOType's innerOnlineTypes with current oType
      replacementOType.innerOnlineTypes.splice(rIndex, 1, oType)
      // Update current oTypes outerOnlineTypes with replacementOType
      oType.outerOnlineTypes.splice(index, 1, replacementOType)
    })

    // 4.3 Update cleaning task references
    oType.cleaningTasks.forEach((cleaningTask) => {
      // Set correct toBeCleaned OnlineType
      const toBeCleanedTarget = reducedOTypes.find(
        (x) => x.dubID === cleaningTask.toBeCleaned?.dubID
      )

      if (toBeCleanedTarget) {
        cleaningTask.toBeCleaned = toBeCleanedTarget
      }

      // Set correct toBeRemoved OnlineType
      const toBeRemovedTarget = reducedOTypes.find(
        (x) => x.dubID === cleaningTask.toBeRemoved.dubID
      )

      if (toBeRemovedTarget) {
        cleaningTask.toBeRemoved = toBeRemovedTarget
      }
    })
  })
}
