/* eslint-disable no-use-before-define */
import { ProCoatingType } from '@/globals/javascript/models/proCoatingType/ProCoatingType'
import { ProProject } from '@/globals/javascript/models/ProProject/ProProject'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { isProCoatingType } from '@/globals/javascript/_utils/util'
import { IStack, IStackLayer } from '@/__types__/_pro/partials/stack'
import { proProjectsStore } from '@/store'
import { IProRawProjectInterConnection } from '@/__types__/_pro/raw-project/pro-raw-project-interConnection'

export const splitProTypes = (stack: IStack) => {
  const newLayers: IStackLayer[] = []
  const { allSamples, projects } = proProjectsStore()

  stack.layers.forEach((layer, index) => {
    const { pType } = layer
    const prevLayer = stack.layers[index - 1]
    const nextLayer = stack.layers[index + 1]

    const project = projects.find((x) => x.id === pType.projectID)

    if (!project) {
      return
    }

    // Return when coating
    if (isProCoatingType(pType)) {
      return
    }

    // Split pType with adhesive (TAG-2)
    if (pType.hasTags(['TAG-2'])) {
      // 1. Create pro split types
      // 1.1 Adhesive pro type
      const adhesivePType = createSplitProType(
        pType,
        'adhesive-inner-',
        'T-89',
        pType.samples.sampleIDs.filter((x) => x === 'ST-1'),
        project as ProProject,
        false
      )

      // 1.1.1 Update adhesive thickness to hardcoded value
      const adhesiveAmount = adhesivePType.amounts[0]
      if (adhesiveAmount?.data?.type === 'm2') {
        adhesiveAmount.data.thickness = 3
      }

      // 1.2 Material pro type
      const materialPType = createSplitProType(
        pType,
        'adhesive-outer-',
        '',
        pType.samples.sampleIDs.filter((x) => x !== 'ST-1'),
        project as ProProject
      )

      // 2. Create pro split samples
      const mainSample = allSamples.find(
        (x) => x.typeID === pType.id && x.kindOfSample === 'material'
      )
      if (mainSample) {
        // 2.1 Adhesive split sample
        createSplitProSample(
          mainSample as ProSample,
          adhesivePType,
          project as ProProject
        )

        // 2.2 Material split sample
        createSplitProSample(
          mainSample as ProSample,
          materialPType,
          project as ProProject
        )
      }

      // 3. Create interconnections
      // 3.1 To previous pType
      let adhesiveInnerInterConnection: IProRawProjectInterConnection | null =
        null
      if (prevLayer?.outerInterConnection) {
        adhesiveInnerInterConnection = {
          ...prevLayer.outerInterConnection,
          id: adhesivePType.id,
          groupID: stack.stackID,
          outerTypeID: adhesivePType.id,
          innerTypeID: pType.id,
        }

        prevLayer.outerInterConnection = adhesiveInnerInterConnection
      }

      // 3.2 Between adhesive and material
      const betweenInterConnection = {
        id: `adhesive-between-${pType.id}`,
        groupID: stack.stackID,
        outerTypeID: materialPType.id,
        innerTypeID: adhesivePType.id,
        splitStatus: 'splitable',
        effectDirection: 'both',
        isSplitStatusLocked: false,
        isCleanableConnection: false,
      }

      // 4. Create new layers
      // 4.1 Adhesive layer
      const adhesiveLayer: IStackLayer = {
        pType: adhesivePType,
        innerInterConnection: adhesiveInnerInterConnection,
        outerInterConnection: betweenInterConnection,
      }

      // 4.2 Material layer
      const materialLayer: IStackLayer = {
        pType: materialPType,
        innerInterConnection: betweenInterConnection,
        outerInterConnection: null,
      }

      // 5. Set web data and push layers
      adhesivePType.setWebData()
      materialPType.setWebData()

      newLayers.push(adhesiveLayer)
      newLayers.push(materialLayer)

      // 6. Check for coating
      let coatingLayer: IStackLayer | null = null
      if (materialPType.hasCoating()) {
        coatingLayer = splitCoating(stack, materialLayer)
        newLayers.push(coatingLayer)
      }

      // 7. Update interconnection with next layer
      if (nextLayer) {
        if (coatingLayer) {
          coatingLayer.outerInterConnection = nextLayer.innerInterConnection
        } else {
          materialLayer.outerInterConnection = nextLayer.innerInterConnection
        }
      }

      // 7. return layers
      stack.options.isGroup = true

      return
    }

    newLayers.push(layer)

    // Split coating from material
    let coatingLayer: IStackLayer | null = null

    if (pType.hasCoating()) {
      coatingLayer = splitCoating(stack, layer)
      newLayers.push(coatingLayer)
    }

    if (coatingLayer && nextLayer) {
      coatingLayer.outerInterConnection = nextLayer.innerInterConnection
    }
  })

  stack.layers = newLayers
}

/**
 * Method for splitting tile and its adhesive
 * @param originalPType the original ProType which the split versions stems from
 * @param idPreTag identifier to id if the splitted protype from the other part
 * @param typeID Material ID from admin
 * @param sampleIDs Sample IDs relevant for the current partial protype
 * @param project ProProject instance
 * @param isHighlighted (Optional) Overwrite to indicated if the partial protype should be featured in resale
 * @returns instance of SplitProType
 */
const createSplitProType = (
  originalPType: ProType,
  idPreTag: string,
  typeID: string,
  sampleIDs: string[],
  project: ProProject,
  isHighlighted?: boolean
) => {
  const splitProType = new ProType(
    {
      ...JSON.parse(JSON.stringify(originalPType)),
      id: idPreTag + originalPType.id,
      originID: originalPType.id,
      condition: typeID === 'T-89' ? '' : originalPType.condition,
      comments: typeID === 'T-89' ? '' : originalPType.comments,
    },
    originalPType.projectID
  )

  if (typeID) {
    splitProType.type.typeID = typeID
  }

  splitProType.options.isSplitType = true
  splitProType.samples.sampleIDs = sampleIDs

  if (splitProType.recycling && isHighlighted !== undefined) {
    splitProType.recycling.isHighlighted = isHighlighted
  }

  project.types.push(splitProType)

  return splitProType
}

const createSplitProSample = (
  originalSample: ProSample,
  splitProType: ProType,
  project: ProProject
) => {
  const proSplitSample = new ProSplitSample(
    {
      ...JSON.parse(JSON.stringify(originalSample)),
      typeID: splitProType.id,
    } as ProSample,
    splitProType.projectID,
    splitProType.samples.sampleIDs.concat(splitProType.samples.manualSampleIDs),
    splitProType.originID
  )

  proSplitSample.typeID === splitProType.id
  proSplitSample.setWebData()
  project.splitSamples.push(proSplitSample)

  return proSplitSample
}

const splitCoating = (stack: IStack, layer: IStackLayer) => {
  const { pType } = layer

  // Create coating type
  const coatingType = new ProCoatingType({
    ...pType,
    id: `coating-${pType.id}`,
    originID: pType.originID || pType.id,
    tags: pType.coating.tags,
    samples: pType.coating.samples,
    answers: pType.coating.answers,
    condition: '',
    courseOfAction: pType.coating.courseOfAction,
    comments: '',
  })

  // Create interconnection
  const wdgID = pType.getWDGID()

  const coatingInterConnection: IProRawProjectInterConnection = {
    id: coatingType.id,
    groupID: stack.stackID,
    outerTypeID: coatingType.id,
    innerTypeID: pType.id,
    splitStatus: wdgID === 'WDG-2' ? 'splitable' : 'stuck',
    effectDirection: wdgID === 'WDG-2' ? 'right' : '',
    isSplitStatusLocked: false,
    isCleanableConnection: false,
  }

  // Create layer
  const coatingLayer: IStackLayer = {
    pType: coatingType,
    innerInterConnection: coatingInterConnection,
    outerInterConnection: null,
  }

  // Update next layer
  layer.outerInterConnection = coatingInterConnection

  // Update stack
  stack.typeIDs.push(coatingType.id)
  stack.options.isGroup = true

  // Add to project types
  // TODO: Add coating type
  // project.types.push(coatingType)

  return coatingLayer
}
