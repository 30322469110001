import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import {
  deriveWDCID,
  isProCoatingType,
  isProType,
} from '@/globals/javascript/_utils/util'
import { IWDCID } from '@/__types__/utils/wdcID'

export function setOwnWDCIDs(
  this: OnlineType,
  combinedSamples: (ProSample | ProSplitSample)[]
) {
  const wdcIDs: IWDCID[] = []

  // Add WDC IDs for each pType
  this.types.forEach((pType) => {
    let sample = null

    if (isProType(pType)) {
      sample =
        combinedSamples.find(
          (x) => x.typeID === pType.id && x.kindOfSample === 'material'
        ) ?? null
    }

    if (isProCoatingType(pType)) {
      sample =
        combinedSamples.find(
          (x) => x.typeID === pType.originID && x.kindOfSample === 'coating'
        ) ?? null
    }

    const sampleWCDID = sample ? sample.wdcID : 'WDC-1'

    // Get for worst WDC-ID in special cases for type
    const specialCaseWDCID = this.specialCases.reduce((prev, specialCase) => {
      if (specialCase.pType.id !== pType.id || !specialCase.wdcID) {
        return prev
      }

      prev = deriveWDCID([prev, specialCase.wdcID])
      return prev
    }, 'WDC-1' as IWDCID)

    wdcIDs.push(deriveWDCID([sampleWCDID as IWDCID, specialCaseWDCID]))
  })

  this.wdcID = deriveWDCID(wdcIDs)
  this.innerWDCID = wdcIDs[0]
  this.outerWDCID = wdcIDs[wdcIDs.length - 1]
}
