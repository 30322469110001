
import LoadingTextChanger from '@/components/LoadingTextChanger.vue'
import Spinner from '@/components/Spinner.vue'
import { appStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PageLoader',
  props: {
    texts: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  computed: {
    ...mapState(appStore, ['isResale']),
  },
  components: { Spinner, LoadingTextChanger },
})
