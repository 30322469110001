import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import {
  getWDCContaminationScale,
  isProCoatingType,
  isProType,
} from '@/globals/javascript/_utils/util'
import { IWDCID } from '@/__types__/utils/wdcID'

export const combineNotAloneTypes = (
  onlineTypes: OnlineType[],
  combinedSamples: (ProSample | ProSplitSample)[]
): void => {
  for (let i = 0; i < onlineTypes.length; i++) {
    let doTheMerge = false
    const oType = onlineTypes[i]

    // 1. Pre-check for merge
    // 1.1 Check for inner online types
    if (!oType.innerOnlineTypes.length) {
      continue
    }

    // 2. Check for merge
    const [innerOType] = oType.innerOnlineTypes

    // 2.1 Always combine adjecent prevention courseOfAction
    if (
      oType.courseOfAction === 'prevention' &&
      innerOType.courseOfAction === 'prevention'
    ) {
      doTheMerge = true
    }

    // 2.2 Never combine different adjecent courseOfActions
    else if (oType.courseOfAction !== innerOType.courseOfAction) {
      continue
    }

    // 2.3 Check for special flow
    // 2.3.1 Special flow: Adhesive
    else if (oType.specialCases.find((x) => x.caseID === 'special-case-8')) {
      const oTypeWDCID = getWDCContaminationScale(oType.wdcID as IWDCID)
      const innerOTypeWDCID = getWDCContaminationScale(
        innerOType.wdcID as IWDCID
      )

      // Always merge when it has postponed sample or missing test results
      if (
        oType.options.hasPostponedSample ||
        oType.options.hasMissingTestResults
      ) {
        doTheMerge = true
      }

      // Merge when adhesive pType is not clean and is same or worse than outer material
      else if (innerOType.wdcID !== 'WDC-1' && innerOTypeWDCID >= oTypeWDCID) {
        doTheMerge = true
      }
    }

    // 2.3.1 Special flow: Soot material
    else if (oType.specialCases.find((x) => x.caseID === 'special-case-11')) {
      // Always merge when it has postponed sample or missing test results
      if (
        innerOType.options.hasPostponedSample ||
        innerOType.options.hasMissingTestResults
      ) {
        doTheMerge = true
      }

      const oTypeWDCID = getWDCContaminationScale(oType.wdcID as IWDCID)
      const innerOTypeWDCID = getWDCContaminationScale(
        innerOType.wdcID as IWDCID
      )

      if (innerOTypeWDCID >= oTypeWDCID) {
        doTheMerge = true
      }
    }

    // 2.4 Do normal flow
    else {
      // 2.4.1 Check that current oType has 'isNeverAlove'
      // Stop flow if proType can be alone
      if (!oType.getIsNeverAloneStatus()) {
        continue
      }

      // 2.4.2 Never combine different adjecent courseOfAction
      if (oType.courseOfAction !== innerOType.courseOfAction) {
        continue
      }

      // 2.4.2 Omit types that has 'isShownAlone'
      // 2.4.2.1 When first pro-type should be omitted
      const firstPTypeRType =
        isProType(oType.types[0]) && oType.types[0].getResourceType()

      if (firstPTypeRType && firstPTypeRType.options.isShownAlone) {
        continue
      }

      // 2.4.2.2 When last pro-type of inner online-type should be omitted
      const innerOTypeLastPType = innerOType.types.slice(-1)[0]
      const innerOTypeLastPTypeRType =
        isProType(innerOTypeLastPType) && innerOTypeLastPType.getResourceType()

      if (
        innerOTypeLastPTypeRType &&
        innerOTypeLastPTypeRType.options.isShownAlone
      ) {
        continue
      }

      // 2.4.3 Check burn status and if oType is only wallpaper with coating
      const canOTypeBurn = oType.getCanBurnStatus()
      const canInnerOTypeBurn = innerOType.getCanBurnStatus()

      const burnCheckStatus =
        (canOTypeBurn && canInnerOTypeBurn) || !canOTypeBurn

      const isWallperWithCoating =
        oType.types.length === 2 &&
        oType.specialCases.find((x) => x.caseID === 'special-case-7')

      if (!burnCheckStatus && !isWallperWithCoating) {
        continue
      }

      // 2.4.4 Sits on a WDG-2 type or an coating only online type
      const innerOTypeOuterTypeWDGID = innerOType.types.slice(-1)[0].getWDGID()
      const sitsOnWDG2 =
        innerOType.wdgID === 'WDG-2' || innerOTypeOuterTypeWDGID === 'WDG-2'
      const isInnerOTypeOnlyCoating =
        innerOType.types.length === 1 && isProCoatingType(innerOType.types[0])

      // Check if the outermost protype of inner onlinetype is coating and
      // the material is sits on is a WDG-2 material
      const innerOTypeOuterCoatingSitsOnWDG2 =
        isProCoatingType(innerOType.types.slice(-1)[0]) &&
        innerOType.types.slice(-2)[0]?.getWDGID() === 'WDG-2'

      if (
        sitsOnWDG2 ||
        isInnerOTypeOnlyCoating ||
        innerOTypeOuterCoatingSitsOnWDG2
      ) {
        // 2.4.4.1 Check contamination status
        const oTypeWDCID = getWDCContaminationScale(oType.wdcID as IWDCID)
        const innerOTypeWDCID = getWDCContaminationScale(
          innerOType.wdcID as IWDCID
        )

        // Pre set status of postponed sample and missing test results
        oType.setMissingTestResultsStatus()
        oType.setHasPostponedSampleStatus()
        const { hasMissingTestResults, hasPostponedSample } = oType.options

        // Coating sits on WDG-2 and missing test result - do not merge
        if (sitsOnWDG2 && (hasMissingTestResults || hasPostponedSample)) {
          continue
        }

        // If the current onlineType has higher WDCID than inner onlineType
        if (innerOTypeWDCID < oTypeWDCID) {
          continue
        }

        doTheMerge = true
      }

      // 2.4.5 Sits in a non-WDG-2
      else {
        doTheMerge = true
      }
    }

    // 3. Merge not allowed
    if (!doTheMerge) {
      continue
    }

    // 4. Merge
    // 4.1 Merge related proTypes
    innerOType.types = [...innerOType.types, ...oType.types]

    // 4.2 Change outerOnlineType connections
    const [outerInterConnectedType] = oType.outerOnlineTypes

    if (outerInterConnectedType) {
      innerOType.outerOnlineTypes = [outerInterConnectedType]
      outerInterConnectedType.innerOnlineTypes = [innerOType]
    } else {
      innerOType.outerOnlineTypes = []
    }

    // 4.3 Change lastTypeID
    innerOType.lastTypeID = oType.lastTypeID

    // 4.4 Change outerEffectDirection
    innerOType.outerEffectDirection = oType.outerEffectDirection

    // 4.5 Reset initial data
    innerOType.setWDGIDs()
    innerOType.setSpecialCases()
    innerOType.setOwnWDCIDs(combinedSamples)

    // 4.6 Remove merged oType from original array
    onlineTypes.splice(i, 1)

    // 4.7 Realign index after removal of array element
    i = i - 1
  }
}
