import { db, PRO_COLLECTIONS } from '@/firebase'
import { ICountry } from '@/__types__/_pro/countries/country'
import { collection, getDocs } from 'firebase/firestore'
import { defineStore } from 'pinia'

interface ICountriesState {
  countries: ICountry[]
  dataFetched: boolean
}

export default defineStore('countries', {
  state: () =>
    ({
      countries: [],
      dataFetched: false,
    } as ICountriesState),

  actions: {
    async getCountries() {
      const colRef = collection(db, PRO_COLLECTIONS.DB_COUNTRIES)

      const snapshot = await getDocs(colRef)

      const countries = snapshot.docs.map((x) => x.data())

      this.$patch({
        countries,
        dataFetched: true,
      })
    },
  },

  getters: {},
})
