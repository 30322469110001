import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { orderBy } from 'lodash-es'

export const orderOTypesByFullTitle = (
  onlineTypes: OnlineType[],
  order: 'asc' | 'desc' = 'asc'
) =>
  orderBy(
    onlineTypes,
    ['index.primary', 'index.secondary', 'title.category', 'title.types'],
    [order]
  )
