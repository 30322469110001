import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-392b8940"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_PageGuideSlide = _resolveComponent("PageGuideSlide")!

  return (_openBlock(), _createBlock(_component_PageGuideSlide, { class: "Slide Intro" }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.mixWB('MILVA_ONLINE_REPORT')), 1)
    ]),
    intro: _withCtx(() => [
      _createElementVNode("p", { innerHTML: _ctx.introHTML }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_VideoPlayer, { video: 'intro' }),
      _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('GUIDE_SLIDE_INTRO_TEXT_2')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('GUIDE_SLIDE_INTRO_TEXT_3')), 1)
    ]),
    _: 1
  }))
}