import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b1bd6b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "LoadingTextChanger" }
const _hoisted_2 = {
  ref: "InnerWrap",
  class: "InnerWrap"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.texts, (text, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: text,
          innerHTML: text,
          class: _normalizeClass({IsActive: index === _ctx.activeIndex})
        }, null, 10, _hoisted_3))
      }), 128))
    ], 512)
  ]))
}