
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Backdrop',
  props: {
    zIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    isTransparent: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    style() {
      if (!this.zIndex) {
        return {}
      }

      return {
        'z-index': this.zIndex,
      }
    },
  },
})
