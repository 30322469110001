import { mixWB } from '@/globals/javascript/_utils/mixins'
import { IGeneratedFile } from '@/__types__/_pro/partials/files'
import { currentStore, translationsStore } from '@/store'
import { orderBy } from 'lodash-es'
import { defineStore } from 'pinia'
import pkgJson from '/package.json'

type IElementsType = 'sections' | 'pages' | 'images'

export interface IPDFConfig {
  name: string
  reference: string
  versionReference: string
  component: string
  storageName: string
  css: string
  fileName: string
  title: string
}

interface IPDFConfigs {
  all: {
    [key: string]: IPDFConfig
  }
  reports: {
    [key: string]: IPDFConfig
  }
  documents: {
    [key: string]: IPDFConfig
  }
}

// interface IPDF

interface IPDFs {
  pdfs: string[]
  titles: {
    fullReport: string
    screeningReport: string
    samplesDocument: string
    damageRelatedScreeningReport: string
  }
  processing: {
    [key: string]: {
      [key: string]: number
    }
  }
  pageNumbersAdded: {
    [key: string]: boolean
  }
  tocReady: {
    [key: string]: boolean
  }
}

export default defineStore('pdfs', {
  state: () =>
    ({
      pdfs: [
        'Full Report',
        'Screening Report',
        'Samples Document',
        'Damage Related Screening Report',
      ],
      titles: {
        fullReport: mixWB('ENVIRONMENTAL_AND_RESOURCE_MAPPING'),
        screeningReport: mixWB('PDF_SCREENING_TITLE'),
        samplesDocument: mixWB('WORK_DOCUMENT_SAMPLES_AND_ASSESSMENTS'),
        damageRelatedScreeningReport: mixWB(
          'DAMAGE_RELATED_ENVIRONMENTAL_SCREENING'
        ),
      },
      processing: {},
      pageNumbersAdded: {},
      tocReady: {},
    } as IPDFs),

  actions: {
    addAsPending(pdfContext: string, type: IElementsType) {
      // If pdfContext is not set
      if (!this.$state.processing[pdfContext]) {
        this.$state.processing[pdfContext] = {}
      }

      // If pdfContext is set but the type isn't
      if (!this.$state.processing[pdfContext]?.[type]) {
        this.$state.processing[pdfContext][type] = 1

        return
      }

      // If the name just needs to be added
      this.$state.processing[pdfContext][type]++
    },
    removeAsPending(pdfContext: string, type: IElementsType) {
      if (this.$state.processing[pdfContext][type]) {
        this.$state.processing[pdfContext][type]--
      }
    },
    setPageNumberState(pdfContext: string, bool: boolean) {
      this.$state.pageNumbersAdded[pdfContext] = bool
    },
    setTOCState(pdfContext: string, bool: boolean) {
      this.$state.tocReady[pdfContext] = bool
    },
    prepState(pdfContext: string) {
      if (!this.$state.processing[pdfContext]) {
        this.$state.processing[pdfContext] = {} as {
          [key: string]: number
        }
      }

      if (!this.$state.pageNumbersAdded[pdfContext]) {
        this.$state.pageNumbersAdded[pdfContext] = false
      }

      if (!this.$state.tocReady[pdfContext]) {
        this.$state.pageNumbersAdded[pdfContext] = false
      }
    },
    newPDFAvailable(reportName: string): boolean {
      // No existing files with the current report name
      if (this.generatedPDFs[reportName] === undefined) {
        return true
      }

      if (this.generatedPDFs[reportName].length === 0) {
        return true
      }

      const { selectedLanguage } = translationsStore()
      const proLastUpdated =
        currentStore().project?.data.lastUpdated._seconds ?? 0

      const reportKey = !reportName.includes('-')
        ? reportName
        : reportName.split('-')[0]

      // Check if a PDF exists with the current parameters
      const foundExistingPDF = this.generatedPDFs[reportName].some((pdf) => {
        const sameVersion =
          Number(pkgJson.pdfVersions[reportKey]) === pdf.pdfVersion
        const sameUpdateTime = proLastUpdated === pdf.proLastUpdated
        const sameLocale = selectedLanguage.locale === pdf.locale

        // Compare version, lastupdated and locale
        return sameVersion && sameUpdateTime && sameLocale
      })

      return foundExistingPDF ? false : true
    },
  },

  getters: {
    configs(): IPDFConfigs {
      const { project, projectAddress, onlineTypes } = currentStore()
      const { selectedLanguage } = translationsStore()

      if (!project) {
        return { all: {}, reports: {}, documents: {} }
      }

      const { customCaseNumber, caseNumber, lastUpdated } = project.data
      const casePart = customCaseNumber || caseNumber

      const proLastUpdated = lastUpdated?._seconds ?? -1
      const locale = selectedLanguage.locale

      const { buildings } = currentStore().units.active
      const { isScreening, projectType } = project.data
      const isDamageRelatedScreening =
        projectType === 'damage-related-screening'

      const buildingOnlineTypeCount = buildings.reduce((prev, building) => {
        const scopedUnitIDs = [
          building.id,
          ...building.floorIDs,
          ...building.apartmentIDs,
        ]

        const relatedTypes = onlineTypes.filter((o) =>
          scopedUnitIDs.some((id) => o.unitIDs.includes(id))
        )

        prev[building.id] = relatedTypes.length

        return prev
      }, {} as { [key: string]: number })

      const configs = this.pdfs.reduce(
        (prev, name) => {
          const parts = name.split(' ')
          const camelCase = [parts[0].toLowerCase(), ...parts.slice(1)].join('')

          // If damage related screening selected, only show that PDF type
          if (
            isDamageRelatedScreening &&
            !camelCase.includes('damageRelatedScreeningReport')
          ) {
            return prev
          }

          // Never show damage related screening PDF in other contexts
          if (
            !isDamageRelatedScreening &&
            camelCase.includes('damageRelatedScreeningReport')
          ) {
            return prev
          }

          // If screening project only allow screening reports
          if (isScreening && !camelCase.includes('screeningReport')) {
            return prev
          }

          // If mapping project only allow non-screening reports
          if (!isScreening && camelCase.includes('screeningReport')) {
            return prev
          }

          const snakeCase = parts.join('-').toLocaleLowerCase()

          const titlesArray = this.titles
          const title = this.titles[camelCase as keyof typeof titlesArray]

          const hasMultipleBuildings = buildings.length > 1

          const allowCombinedReport =
            !hasMultipleBuildings ||
            (hasMultipleBuildings && onlineTypes.length <= 200)

          if (allowCombinedReport) {
            const config = {
              name,
              reference: camelCase,
              versionReference: camelCase,
              component: parts.join(''),
              css: `pdf-${snakeCase}`,
              storageName: `${snakeCase}-${caseNumber}-${proLastUpdated}-v${pkgJson.pdfVersions[camelCase]}-${locale}`,
              fileName: hasMultipleBuildings
                ? `${title} (${mixWB(
                    'ALL_BUILDINGS'
                  )}) - ${casePart} - ${projectAddress}`
                : `${title} - ${casePart} - ${projectAddress}`,
              title: hasMultipleBuildings
                ? `${title} - ${mixWB('ALL_BUILDINGS')}`
                : title,
            }

            prev.all[camelCase] = config

            if (parts.slice(-1)[0] === 'Report') {
              prev.reports[camelCase] = config
            }

            if (parts.slice(-1)[0] === 'Document') {
              prev.documents[camelCase] = config
            }
          }

          // Configs for building version of report
          if (hasMultipleBuildings) {
            buildings.forEach((building) => {
              // Only allow building pdfs with materials registred (incl. subunits)
              if (buildingOnlineTypeCount[building.id]) {
                const reference = `${camelCase}-${building.id}`
                const config = {
                  name,
                  reference,
                  versionReference: camelCase,
                  component: parts.join(''),
                  css: `pdf-${snakeCase}`,
                  storageName: `${snakeCase}-bid-${building.id}-${caseNumber}-${proLastUpdated}-v${pkgJson.pdfVersions[camelCase]}-${locale}`,
                  fileName: `${title} (${building.getFullTitle()}) - ${casePart} - ${projectAddress}`,
                  title: `${title} - ${building.getFullTitle()}`,
                }

                prev.all[reference] = config

                if (parts.slice(-1)[0] === 'Report') {
                  prev.reports[reference] = config
                }

                if (parts.slice(-1)[0] === 'Document') {
                  prev.documents[reference] = config
                }
              }
            })
          }

          return prev
        },
        { all: {}, reports: {}, documents: {} } as IPDFConfigs
      )

      return configs
    },
    generatedPDFs(): { [key: string]: IGeneratedFile[] } {
      const { project } = currentStore()
      const { selectedLanguage } = translationsStore()

      if (!project) {
        return {}
      }

      if (!project.data.generatedFiles) {
        return {}
      }

      const { isScreening, projectType } = project.data
      const isDamageRelatedScreening =
        projectType === 'damage-related-screening'

      const files = Object.entries(project.data.generatedFiles).reduce(
        (prev, [key, value]) => {
          const filteredFiles = value.filter(
            (x) => (x?.locale ?? 'da-DK') === selectedLanguage.locale
          )

          // If damage related screening selected, only show that PDF type
          if (
            isDamageRelatedScreening &&
            !key.includes('damageRelatedScreeningReport')
          ) {
            return prev
          }

          // Never show damage related screening PDF in other contexts
          if (
            !isDamageRelatedScreening &&
            key.includes('damageRelatedScreeningReport')
          ) {
            return prev
          }

          // If screening project only allow screening reports
          if (isScreening && !key.includes('screeningReport')) {
            return prev
          }

          // If mapping project only allow non-screening reports
          if (!isScreening && key.includes('screeningReport')) {
            return prev
          }

          // Add sorted array to output
          prev[key] = orderBy(
            filteredFiles,
            ['proLastUpdated', 'pdfVersion', 'timestamp'],
            ['desc', 'desc', 'desc']
          )

          return prev
        },
        {} as { [key: string]: IGeneratedFile[] }
      )

      return files
    },
  },
})
