
import storageKeys from '@/globals/javascript/constants/storageKeys'
import { appStore } from '@/store'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FeedbackPin',
  methods: {
    ...mapActions(appStore, ['setShownDialog', 'setShowFeedbackPinState']),
    hidePin() {
      localStorage.setItem(storageKeys.LS_FEEDBACK_SEEN, 'true')

      this.setShowFeedbackPinState()
    },
  },
})
