import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { mixFormatNumber, mixWB } from '@/globals/javascript/_utils/mixins'

export function setDisposalCost(this: OnlineType) {
  const { amounts, disposalCost, fraction } = this

  if (!fraction?.id || !fraction?.avgPrice) {
    return
  }

  if (!amounts.totalTons) {
    return
  }

  disposalCost.hasMissingAmounts = amounts.hasMissingAmounts
  disposalCost.totalCost = amounts.totalTons * fraction.avgPrice
  disposalCost.calculation = mixWB('FEE_TONNES_AND_PRICE_CALCULATION_STRING', [
    mixFormatNumber(amounts.totalTons, 3).toString(),
    mixFormatNumber(fraction.avgPrice).toString(),
    mixFormatNumber(disposalCost.totalCost, 2).toString(),
  ])
}
