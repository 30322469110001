/* eslint-disable no-use-before-define */
import { Functions } from 'firebase/functions'
import { FirebaseStorage } from 'firebase/storage'
import { Firestore } from 'firebase/firestore'

export default function initEmulators(
  db: Firestore,
  storage: FirebaseStorage,
  functions: Functions
): void {
  const emulatorServices = process.env.VUE_APP_EMULATORS || ''

  // Start emulator for firestore
  if (emulatorServices === 'all' || emulatorServices.includes('firestore')) {
    initFirestoreEmulator(db)
  }
  // Start emulator for storage
  if (emulatorServices === 'all' || emulatorServices.includes('storage')) {
    initStorageEmulator(storage)
  }
  // Start emulator for functions
  if (emulatorServices === 'all' || emulatorServices.includes('functions')) {
    initFunctionEmulator(functions)
  }
}

const initFirestoreEmulator = async (db: Firestore) => {
  const { connectFirestoreEmulator } = await import('firebase/firestore')

  connectFirestoreEmulator(db, 'localhost', 5000)
}

const initStorageEmulator = async (storage: FirebaseStorage) => {
  const { connectStorageEmulator } = await import('firebase/storage')

  connectStorageEmulator(storage, 'localhost', 9199)
}

const initFunctionEmulator = async (functions: Functions) => {
  const { connectFunctionsEmulator } = await import('firebase/functions')

  connectFunctionsEmulator(functions, 'localhost', 5001)
}
