import { currentStore } from '@/store'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

let hotjarEventsFired = false

export default function setHotjar(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  // Check for LIVE site
  if (process.env.NODE_ENV !== 'production') {
    next()
    return
  }

  const currentStoreRef = currentStore()

  // Ensure needed info is available
  const { mapper, account, project } = currentStoreRef

  if (!account || !project) {
    next()
    return
  }

  // Add event (may not work due to free plan)
  if (!window.hj) {
    // eslint-disable-next-line func-names
    window.hj =
      window.hj ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        ;(window.hj.q = window.hj.q || []).push(arguments)
      }
  }

  // Fire hotjar startup events + ensure they only fire once!
  if (!hotjarEventsFired && window.hj) {
    window.hj('event', 'hotjar_started')
    window.hj('event', `Project ID: ${project.id}`)
    window.hj('event', `Account: ${account.name}`)
    window.hj('event', `Account ID: ${account.id}`)
    window.hj('event', `Mapper: ${mapper?.fullName ?? 'na'}`)
    window.hj('event', `Mapper email: ${mapper?.email ?? 'na'}`)
    window.hj('event', `Mapper ID: ${mapper?.id ?? 'na'}`)

    hotjarEventsFired = true
  }

  next()
}
