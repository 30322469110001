import versionKeys from '@/globals/javascript/constants/versionKeys'
import OnlineType, {
  ISpecialCase,
} from '@/globals/javascript/models/onlineType/OnlineType'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { isProCoatingType, isProType } from '@/globals/javascript/_utils/util'
import { currentStore } from '@/store'
import { compare } from 'compare-versions'

export function setSpecialCases(this: OnlineType) {
  const { projectProVersion } = currentStore()

  // Reset if previously set
  if (this.specialCases.length) {
    this.specialCases = [] as ISpecialCase[]
  }

  if (this.types.length >= 2) {
    const nextToLastType = this.types[this.types.length - 2]
    const lastType = this.types[this.types.length - 1]
    const nextToLastTypeRType = isProType(nextToLastType)
      ? nextToLastType.getResourceType()
      : null
    const nextToLastTypeMaterial = isProType(nextToLastType)
      ? nextToLastType.getMaterial()
      : null
    const isLastTypeCoating = isProCoatingType(lastType)

    // Case 7: Check for wallpaper with coating
    if (nextToLastTypeRType?.id === 'T-64' && isLastTypeCoating) {
      this.specialCases.push({
        caseID: 'special-case-7',
        wdcID: '',
        pType: nextToLastType,
        title: `${nextToLastType.getTitle()} ${mixWB('WITH_SHORT')} ${lastType
          .getTitle()
          .toLowerCase()}`,
        overrideNormalInformationText: true,
      })
    }

    // Case 9: Check for metal with coating
    if (
      this.types.length === 2 &&
      nextToLastTypeMaterial?.options?.isMetal &&
      isLastTypeCoating
    ) {
      this.specialCases.push({
        caseID: 'special-case-9',
        wdcID: '',
        pType: nextToLastType,
        overrideNormalInformationText: true,
      })
    }
  }

  // Go through each type
  this.types.forEach((pType, index) => {
    if (!isProType(pType)) {
      return
    }

    const rType = pType.getResourceType()
    const sampleIDs = pType.getAllSampleIDs()

    if (!rType) {
      return
    }

    // Case 1 and 2: Insulation - Stone/glass wool
    if (['T-17', 'T-62'].includes(rType.id)) {
      // Before 1998
      if (pType.hasTags(['TAG-YEAR-1998'])) {
        this.specialCases.push({
          caseID: 'special-case-1',
          wdcID: 'WDC-2',
          pType,
          overrideNormalInformationText: true,
        })
      }
      // After 1998
      if (pType.hasTags(['TAG-AFTER-YEAR-1998'])) {
        this.specialCases.push({
          caseID: 'special-case-2',
          wdcID: 'WDC-2',
          pType,
          overrideNormalInformationText: true,
        })
      }
    }

    // Case 3: Pressure impregnated wood
    if (['T-24'].includes(rType.id)) {
      this.specialCases.push({
        caseID: 'special-case-3',
        wdcID: 'WDC-2',
        pType,
        overrideNormalInformationText: true,
      })
    }

    // Case 4: No asbestos as after 1988
    if (
      !sampleIDs.includes('ST-1') &&
      pType.hasTags(['TAG-AFTER-YEAR-1988']) &&
      !pType.hasTags(['TAG-2'])
    ) {
      this.specialCases.push({
        caseID: 'special-case-4',
        wdcID: '',
        pType,
        overrideNormalInformationText: true,
      })
    }

    // Case 5: No asbestos for types that has adhesives
    if (
      !sampleIDs.includes('ST-1') &&
      pType.hasTags(['TAG-2']) &&
      !pType.id.includes('adhesive-outer-')
    ) {
      this.specialCases.push({
        caseID: 'special-case-5',
        wdcID: '',
        pType,
        overrideNormalInformationText: true,
      })
    }

    // Case 6: Soot
    if (pType.hasTags(['TAG-3'])) {
      this.specialCases.push({
        caseID: 'special-case-6',
        wdcID: 'WDC-2',
        pType,
        overrideNormalInformationText: true,
      })
    }

    // Case 8: First type is a type with adhesives
    if (
      index === 0 &&
      pType.hasTags(['TAG-2']) &&
      pType.id.includes('adhesive-outer-')
    ) {
      this.specialCases.push({
        caseID: 'special-case-8',
        wdcID: '',
        pType,
        overrideNormalInformationText: true,
      })
    }

    // Case 10: Bricks with mortar
    if (rType.id === 'T-50') {
      // VERSION CHECK: Same or newer than 2.6.7
      if (
        compare(
          projectProVersion,
          versionKeys.BRICKS_AND_MORTAR_CLEANING_QUESTION,
          '>='
        )
      ) {
        const answerItem = pType.tags.items.find((x) =>
          x.tagIDs.includes('TAG-4')
        )
        const answer = !answerItem ? 0 : answerItem.answer

        this.specialCases.push({
          caseID: 'special-case-10',
          wdcID: '',
          pType,
          answer,
          overrideNormalInformationText: false,
        })
      }

      // VERSION CHECK: Same or older than 2.6.7
      else {
        // Do nothing
      }
    }

    // Case 11: SOOT
    if (['T-95'].includes(rType.id)) {
      this.specialCases.push({
        caseID: 'special-case-11',
        wdcID: 'WDC-2',
        pType,
        overrideNormalInformationText: true,
      })
    }
  })
}
