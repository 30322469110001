import { defineStore } from 'pinia'
import { PRO_COLLECTIONS, db } from '@/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { sortBy } from 'lodash-es'

import { IResourcesSampleType } from '@/__types__/_pro/resources/resources-sampleType'
import { IResourcesWGD } from '@/__types__/_pro/resources/resources-wdg'
import { IResourcesEWCCode } from '@/__types__/_pro/resources/resources-ewcCode'
import { IResourcesCategory } from '@/__types__/_pro/resources/resources-category'
import { IResourcesType } from '@/__types__/_pro/resources/resources-type'
import { IResourcesCategoryType } from '@/__types__/_pro/resources/resources-categoryType'
import { IResourcesMaterial } from '@/__types__/_pro/resources/resources-material'
import { IResourcesFraction } from '@/__types__/_pro/resources/resources-fraction'
import { IResourcesQuestion } from '@/__types__/_pro/resources/resources-question'
import { IResourcesGroupQuestion } from '@/__types__/_pro/resources/resources-groupQuestion'
import { IResourcesCoatingType } from '@/__types__/_pro/resources/resources-coatingType'
import { IResources } from '@/__types__/_pro/resources/resources'
import { IAnalysisTest } from '@/__types__/_pro/resources/resources-analysisTest'

interface IResourcesState extends IResources {
  dataFetched: boolean
}

export default defineStore('resources', {
  state: () =>
    ({
      dataFetched: false,
      areasAsArray: [],
      categoriesAsArray: [],
      categoryTypesAsArray: [],
      coatingTypesAsArray: [],
      ewcCodesAsArray: [],
      fractionsAsArray: [],
      groupQuestionsAsArray: [],
      materialsAsArray: [],
      questionsAsArray: [],
      sampleTypesAsArray: [],
      analysisTestsAsArray: [],
      analysisTestGroupsAsArray: [],
      ecoToxAnalysisTestIDList: [],
      tagsAsArray: [],
      typesAsArray: [],
      wdgAsArray: [],
    } as IResourcesState),

  actions: {
    async fetchData(
      docName: string,
      stateProp: keyof IResources,
      sorting: string | string[] = []
    ): Promise<void> {
      const docRef = doc(db, PRO_COLLECTIONS.DB_RESOURCES, docName)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data }: any = (await getDoc(docRef)).data()

      // Adding fetch data to state (sorted by property if requested)
      this.$patch({
        [stateProp]: sortBy(data, sorting),
      })
    },
    async fetchAreas(): Promise<void> {
      await this.fetchData('-areas-', 'areasAsArray')
    },
    async fetchCategories(): Promise<void> {
      await this.fetchData('-categories-', 'categoriesAsArray', [
        'areaID',
        'position',
      ])
    },
    async fetchCategoryTypes(): Promise<void> {
      await this.fetchData('-category-types-', 'categoryTypesAsArray')
    },
    async fetchCoatingTypes(): Promise<void> {
      await this.fetchData('-coating-types-', 'coatingTypesAsArray')
    },
    async fetchEWCCodes(): Promise<void> {
      await this.fetchData('-ewc-codes-', 'ewcCodesAsArray')
    },
    async fetchFractions(): Promise<void> {
      await this.fetchData('-fractions-', 'fractionsAsArray')
    },
    async fetchGroupQuestions(): Promise<void> {
      await this.fetchData('-group-questions-', 'groupQuestionsAsArray')
    },
    async fetchQuestions(): Promise<void> {
      await this.fetchData('-questions-', 'questionsAsArray')
    },
    async fetchMaterials(): Promise<void> {
      await this.fetchData('-materials-', 'materialsAsArray')
    },
    async fetchSampleTypes(): Promise<void> {
      await this.fetchData('-sample-types-', 'sampleTypesAsArray')
    },
    async fetchAnalysisTests(): Promise<void> {
      await this.fetchData('-analysis-tests-', 'analysisTestsAsArray')
    },
    async fetchAnalysisTestGroups(): Promise<void> {
      await this.fetchData(
        '-analysis-test-groups-',
        'analysisTestGroupsAsArray'
      )
    },
    async fetchEcoToxAnalysisTestIDList(): Promise<void> {
      await this.fetchData(
        '-eco-tox-analysis-test-id-list-',
        'ecoToxAnalysisTestIDList'
      )
    },
    async fetchTags(): Promise<void> {
      await this.fetchData('-tags-', 'tagsAsArray')
    },
    async fetchTypes(): Promise<void> {
      await this.fetchData('-types-', 'typesAsArray')
    },
    async fetchWorkGroupDescription(): Promise<void> {
      await this.fetchData('-work-description-groups-', 'wdgAsArray')
    },
    async fetchAllResources() {
      await Promise.all([
        this.fetchAreas(),
        this.fetchCategories(),
        this.fetchCategoryTypes(),
        this.fetchCoatingTypes(),
        this.fetchEWCCodes(),
        this.fetchFractions(),
        this.fetchGroupQuestions(),
        this.fetchMaterials(),
        this.fetchQuestions(),
        this.fetchSampleTypes(),
        this.fetchAnalysisTests(),
        this.fetchAnalysisTestGroups(),
        this.fetchEcoToxAnalysisTestIDList(),
        this.fetchTypes(),
        this.fetchTags(),
        this.fetchWorkGroupDescription(),
      ])

      this.dataFetched = true
    },
  },

  getters: {
    cleanAnalysisTestsAsArray(state): IAnalysisTest[] {
      return state.analysisTestsAsArray.filter((x) => x.id !== 'cpScreening')
    },
    categoriesAsObject(state): { [index: string]: IResourcesCategory } {
      return state.categoriesAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {})
    },

    categoryTypesAsObject(state) {
      return state.categoryTypesAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesCategoryType })
    },

    coatingTypesAsObject(state) {
      return state.coatingTypesAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesCoatingType })
    },

    ewcCodesAsObject(state) {
      return state.ewcCodesAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesEWCCode })
    },

    fractionsAsObject(state) {
      return state.fractionsAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesFraction })
    },

    groupQuestionsAsObject(state) {
      return state.groupQuestionsAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesGroupQuestion })
    },

    materialsAsObject(state) {
      return state.materialsAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesMaterial })
    },

    questionsAsObject(state) {
      return state.questionsAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesQuestion })
    },

    sampleTypesAsObject(state) {
      return state.sampleTypesAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesSampleType })
    },

    typesAsObject(state) {
      return state.typesAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesType })
    },

    wdgAsObject(state) {
      return state.wdgAsArray.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {} as { [index: string]: IResourcesWGD })
    },
  },
})
