import { ProProject } from '@/globals/javascript/models/ProProject/ProProject'
import { mixWB } from '@/globals/javascript/_utils/mixins'

export function getCommentsOnProjectTitle(this: ProProject) {
  return this.data.isScreening
    ? mixWB('COMMENTS_ON_THE_SCREENING')
    : mixWB('COMMENTS_ON_THE_MAPPING')
}

export function getProjectBeingConductedByText(this: ProProject) {
  return this.data.isScreening
    ? mixWB('SCREENING_BEING_CONDUCTED_BY')
    : mixWB('MAPPING_BEING_CONDUCTED_BY')
}

export function getProjectDateText(this: ProProject) {
  return this.data.isScreening ? mixWB('SCREENING_DATE') : mixWB('MAPPING_DATE')
}

export function getProjectDoneByText(this: ProProject) {
  return this.data.isScreening
    ? mixWB('SCREENING_DONE_BY')
    : mixWB('MAPPING_DONE_BY')
}

export function getProjectStartingDateText(this: ProProject) {
  return this.data.isScreening
    ? mixWB('SCREENING_STARTING_DATE')
    : mixWB('MAPPING_STARTING_DATE')
}

export function getProjectStartedStatusText(this: ProProject) {
  return this.data.isScreening
    ? mixWB('STATUS_SCREENING_STARTED')
    : mixWB('STATUS_MAPPING_STARTED')
}
