import { storage } from '@/firebase'
import { getWDCContaminationScale } from '@/globals/javascript/_utils/util'
import { translationsStore } from '@/store'
import { IWDCID } from '@/__types__/utils/wdcID'
import { getDownloadURL, ref } from '@firebase/storage'
import { captureMessage, Severity, withScope } from '@sentry/vue'
import dayjs from 'dayjs'
import 'dayjs/locale/da'
import 'dayjs/locale/en'
import { HOST } from '@/globals/javascript/_utils/host'

export function mixWB(key: string, params = [] as string[]): string {
  if (!key) {
    return ''
  }

  const { translations, selectedLanguage } = translationsStore()
  const translation = translations[key] || false

  let textToUse = ''

  if (!translation) {
    textToUse = key.replace('W: ', '')

    // Report missing translation to sentry
    if (process.env.NODE_ENV === 'production') {
      withScope((scope) => {
        scope.setLevel(Severity.Error)
        scope.setExtra('key', key)
        scope.setExtra('countryCode', selectedLanguage.countryCode)
        captureMessage(`ERROR: Translation (${key}) not found`)
      })
    } else {
      console.log(`[MISSING TRANSLATION]: ${key}`)
    }
  } else {
    textToUse = translation
  }

  params.forEach((param) => {
    textToUse = textToUse.replace('%s', param)
  })

  return textToUse
}

export function mixWBPreRender(key: string, params = [] as string[]): string {
  if (!key) {
    return ''
  }

  const { preRenderTranslations, selectedLanguage } = translationsStore()
  const countryCode = selectedLanguage.countryCode

  if (!countryCode) {
    return ''
  }

  const translation = preRenderTranslations[key] || false

  let textToUse = ''

  if (!translation) {
    textToUse = key.replace('W: ', '')
  } else {
    textToUse = translation[countryCode]
  }

  params.forEach((param) => {
    textToUse = textToUse.replace('%s', param)
  })

  return textToUse
}

export function mixFormatNumber(number: string | number, decimals = NaN) {
  if (Number.isNaN(Number(number))) {
    return number
  }

  const { selectedLanguage } = translationsStore()
  const userLang: string = selectedLanguage.locale
  const numberAsString = number.toString()

  // Find decimals
  let decimalsToUse = decimals

  if (Number.isNaN(decimals)) {
    const numberArray = numberAsString.split('.')

    if (numberArray.length > 1) {
      decimalsToUse = numberArray[1].length
    } else {
      decimalsToUse = 0
    }
  }

  return parseFloat(
    parseFloat(numberAsString).toFixed(decimalsToUse)
  ).toLocaleString(userLang, {
    minimumFractionDigits: decimalsToUse,
  })
}

export function mixFormatPrice(number: number, postfix = ',-') {
  // return '-' on all types of falsy except the number 0
  if (!number && number !== 0) {
    return '-'
  }

  // Format to locale
  const value = mixFormatNumber(Math.ceil(number), 0)

  if (Number.isNaN(value)) {
    return '-'
  }

  // Format output according to preference
  const formattedValue = value.toString().replace('-', '- ')

  return `${formattedValue}${postfix}`
}

export function mixGetDate(
  date: { _seconds: number; _nanoseconds: number } | string | null,
  format: 'DATE_FORMAT_1' | 'DATE_FORMAT_2' | null = 'DATE_FORMAT_1'
) {
  // Avoid empty strings
  if (date !== null && !date) {
    return ''
  }

  // Format docs: https://day.js.org/docs/en/display/format
  let dateToFormat = dayjs()

  // Accepted format of string - ISO 8601 e.g. '2018-04-04T16:00:00.000Z'
  if (typeof date === 'string') {
    dateToFormat = dayjs(date)
  }

  if (typeof date === 'object' && date) {
    dateToFormat = dayjs.unix(date._seconds)
  }

  // Return date as a unix timestamp
  if (format === null) {
    return dateToFormat.unix()
  }

  return dateToFormat.format(mixWB(format))
}

export function mixGetFileUrl({
  path,
  filename,
  download = false,
}: {
  path: string
  filename: string
  download: boolean
}) {
  return `${HOST.cloudFunction}/system_proxy_cloud_storage?path=${path}&filename=${filename}&attachment=${download}`
}

export function mixGetDownloadURL({ path }: { path: string }): Promise<string> {
  return new Promise((resolve, reject) => {
    getDownloadURL(ref(storage, path))
      .then((url: string) => {
        resolve(url)
      })
      .catch(() => {
        reject()
      })
  })
}

export function mixContaminationClass(value: string | null | 0 | 1 | 2) {
  // Mimics the default value from Eurofins test result property when not analysed
  if (value === null) {
    return 'NotAnalysed'
  }

  // Shortcircuit for manually set unknown contamination
  if (value === '') {
    return 'Unknown'
  }

  const scale =
    typeof value === 'number'
      ? value + 1 // testResult.result er 0, 1, 2
      : getWDCContaminationScale(value as IWDCID)

  if (Number.isNaN(scale)) {
    throw new Error(
      `Value [ ${value} ] could not be converted to meaningfull contamination class`
    )
  }

  if (scale >= 3) {
    return 'Hazardous'
  }

  if (scale === 2) {
    return 'Contaminated'
  }

  if (scale === 1) {
    return 'Clean'
  }

  return 'Unknown'
}

export function mixEncodeString(str: string) {
  const encodedString = str
    .replace('æ', 'ae')
    .replace('ø', 'o')
    .replace('å', 'aa')
    .replace(/\s/gm, '-')
    .toLowerCase()

  return encodeURIComponent(encodedString)
}

export const mixins = {
  methods: {
    mixWB,
    mixWBPreRender,
    mixFormatPrice,
    mixFormatNumber,
    mixGetDate,
    mixGetDownloadURL,
    mixGetFileUrl,
    mixContaminationClass,
    mixEncodeString,
  },
}
