import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { mixFormatNumber, mixWB } from '@/globals/javascript/_utils/mixins'
import { isProCoatingType, isProType } from '@/globals/javascript/_utils/util'
import {
  getDisposalCostSumAsText,
  getTonsSumAsText,
} from '@/globals/javascript/_utils/pane-util'

export function getTonsAsText(this: OnlineType) {
  const { totalTons, hasMissingAmounts } = this.amounts

  return getTonsSumAsText({ total: totalTons, hasMissingAmounts })
}

export function getAmountData(this: OnlineType) {
  // Find amount type
  const type = this.stackData.stackM2
    ? 'm2'
    : this.amounts.amountsPerPType?.[0]?.amountType

  // Find amount value
  const value =
    this.stackData.stackM2 || this.amounts.amountsPerPType?.[0]?.amountValue

  let asText = '-'

  if (type && value) {
    switch (type) {
      case 'meters':
        asText = `${mixWB('X_M', [value.toString()])}`
        break

      case 'm2':
        asText = `${mixWB('X_M2', [value.toString()])}`
        break

      case 'm3':
        asText = `${mixWB('X_M3', [value.toString()])}`
        break

      case 'pcs':
        asText = `${mixWB('X_PCS', [value.toString()])}`
        break
    }
  }

  return {
    value: value || NaN,
    type: type || '',
    asText,
  }
}

export function getCO2AmountData(this: OnlineType) {
  // Find amount type
  const type = this.stackData.stackM2
    ? 'm2'
    : this.co2Amounts.amountsPerPType?.[0]?.type

  // Find amount value
  const value =
    this.stackData.stackM2 || this.co2Amounts.amountsPerPType[0]?.amount

  let asText = '-'

  if (type && value) {
    switch (type) {
      case 'meters':
        asText = `${mixWB('X_M', [value.toString()])}`
        break

      case 'm2':
        asText = `${mixWB('X_M2', [value.toString()])}`
        break

      case 'm3':
        asText = `${mixWB('X_M3', [value.toString()])}`
        break

      case 'pcs':
        asText = `${mixWB('X_PCS', [value.toString()])}`
        break
    }
  }

  return {
    value: value || NaN,
    type: type || '',
    asText,
  }
}

export function getCO2TextWithDetails(this: OnlineType) {
  if (!this.co2Amounts.totalTons) {
    return mixWB('NOT_SPECIFIED')
  }

  const { asText: amountAsText } = this.getCO2AmountData()

  let text = ''

  // Add amount valiue and type
  if (amountAsText !== '-') {
    text = `${amountAsText} ≈ `
  }

  // Add tons
  text += `${mixFormatNumber(this.co2Amounts.totalTons, 3)} ${mixWB(
    'TONNES_2'
  )} ${mixWB('CO2_E')}`

  return text
}

export function getTonsTextWithDetails(this: OnlineType) {
  if (!this.amounts.totalTons) {
    return mixWB('NOT_SPECIFIED')
  }

  const { asText: amountAsText } = this.getAmountData()

  let text = ''

  // Add amount valiue and type
  if (amountAsText !== '-') {
    text = `${amountAsText} ≈ `
  }

  // Add tons
  text += `${mixFormatNumber(this.amounts.totalTons, 3)} ${mixWB('TONNES_2')}`

  return text
}

export function getDisposalCostAsText(this: OnlineType) {
  const { totalCost, hasMissingAmounts } = this.disposalCost

  return getDisposalCostSumAsText({ total: totalCost, hasMissingAmounts })
}

export function getIsNeverAloneStatus(this: OnlineType) {
  return this.types.every((pType) => {
    if (isProCoatingType(pType)) {
      return true
    }

    const rType = pType.getResourceType()
    return rType?.options.isNeverAlone
  })
}

export function getCanBurnStatus(this: OnlineType) {
  // When online-type consists of a single pro-type
  if (this.types.length === 1) {
    return this.types[0].getCanBurnStatus()
  }

  // When online-type consists of multiple pro types
  // Try and use only non-coating-pro-types
  const proTypes = this.types.filter((x) => isProType(x))

  // Otherwise, when all pro-types are coating
  if (!proTypes.length) {
    return this.types[0].getCanBurnStatus()
  }

  // Get only pro-types without 'isNeverAlone'
  const notNeverAlineProTypes = this.types.filter(
    (x) => isProType(x) && !x.getResourceType()?.options.isNeverAlone
  )

  // If any pro-types does not have 'isNeverAlone', then use those
  if (notNeverAlineProTypes.length) {
    return notNeverAlineProTypes.every((pType) => pType.getCanBurnStatus())
  }

  // If all pro-types has 'isNeverAlone', then use those
  return proTypes.every((pType) => pType.getCanBurnStatus())
}
