import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import Apartment from '@/globals/javascript/models/proUnit/Apartment'
import Building from '@/globals/javascript/models/proUnit/Building'
import Floor from '@/globals/javascript/models/proUnit/Floor'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { ICategoryGroup } from '@/__types__/categoryGroup'
import { IFractionGroup } from '@/__types__/fractionGroup'
import { countriesStore, filterStore, proProjectsStore } from '@/store'
import { IProRawProjectAccount } from '@/__types__/_pro/raw-project/pro-raw-project-account'
import { IProRawProjectMapper } from '@/__types__/_pro/raw-project/pro-raw-project-mapper'
import { defineStore } from 'pinia'

import { captureException, Severity, withScope } from '@sentry/vue'
import { IAnalysisTestGroup } from '@/__types__/_pro/resources/resources-analysisTestGroup'
import { compileCategoryGroups } from '@/store/modules/current/helpers/compileCategoryGroups'
import { compileFractionGroups } from '@/store/modules/current/helpers/compileFractionGroups'
import { compileRemdiationGroups } from '@/store/modules/current/helpers/compileRemediationGroups'
import { compileResale } from '@/store/modules/current/helpers/compileResale'
import { compileSamples } from '@/store/modules/current/helpers/compileSamples'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { compileUnits } from '@/store/modules/current/helpers/compileUnits'

// interface ICurrentState {}

export default defineStore('current', {
  // state: () => ({} as ICurrentState),

  actions: {
    getCategoryGroupsByBuildingID(id?: string): {
      all: ICategoryGroup[]
      withOnlineTypes: ICategoryGroup[]
      withoutOnlineTypes: ICategoryGroup[]
      resale: ICategoryGroup[]
    } {
      if (id) {
        const relatedTypes = this.onlineTypes.filter((x: OnlineType) =>
          x.unitIDs.includes(id)
        )

        return compileCategoryGroups(relatedTypes)
      }

      return this.categoryGroupsByBuilding
    },
  },

  getters: {
    project() {
      const filterStoreRef = filterStore()
      const proStoreRef = proProjectsStore()
      const currentProjectID = filterStoreRef.values.projectId

      if (!currentProjectID || !proStoreRef.dataFetched) {
        return
      }

      return proStoreRef.projects.find((p) => p.id === currentProjectID)
    },
    projectProVersion(): string {
      return this.project?.data?.appVersion || ''
    },
    projectType() {
      const proStoreRef = proProjectsStore()

      if (proStoreRef.projects.length === 1) {
        return 'single'
      }

      return 'enterprise'
    },
    isEnterpriseProject(): boolean {
      return this.projectType === 'enterprise'
    },
    isSingleProject(): boolean {
      // Working with the thought that "isSingleProject" is the default
      return this.projectType !== 'enterprise'
    },
    projectAddress(): string {
      if (!this.project) {
        return ''
      }

      if (!this.account) {
        return ''
      }

      const { baseCountry } = this.account
      const { countryCode: projectCountry } = this.project.data

      const { address, city, postalCode } = this.project.data.address
      const addressLine = `${address}, ${postalCode} ${city}`

      // Return default addresse setup if projectCountry is falsy or same as baseCountry
      if (!projectCountry || baseCountry === projectCountry) {
        return addressLine
      }

      const { countries } = countriesStore()
      const country = countries.find((c) => c.countryCode === projectCountry)

      // Report to sentry if we have no collection of selected project country and return default
      if (!country) {
        withScope((scope) => {
          scope.setLevel(Severity.Critical)
          captureException(
            `ERROR: Project created with unknown country selction: ${projectCountry}`
          )
        })

        return addressLine
      }

      // Add country to default address line
      return `${addressLine}, ${mixWB(country.translation)}`
    },
    usedAnalysisTestGroups(): IAnalysisTestGroup[] {
      return this.project?.usedAnalysisTestGroups || []
    },
    samples(): {
      all: ProSample[]
      standard: ProSample[]
      assessed: ProSample[]
      equivalent: ProSample[]
      existing: ProSample[]
      postponed: ProSample[]
    } {
      const filterStoreRef = filterStore()
      const proStoreRef = proProjectsStore()

      const samples = (proStoreRef.allSamples as ProSample[]).filter(
        (sample) => filterStoreRef.values.projectId === sample.projectID
      )

      return compileSamples(samples)
    },
    units(): {
      all: (Building | Floor | Apartment)[]
      active: { all: (Building | Floor | Apartment)[]; buildings: Building[] }
      selected: {
        all: (Building | Floor | Apartment)[]
        buildings: Building[]
        floors: Floor[]
        apartments: Apartment[]
      }
      custom: (Building | Floor | Apartment)[]
    } {
      const filterStoreRef = filterStore()
      const proStoreRef = proProjectsStore()

      const all = proStoreRef.allUnits.filter(
        (x) => filterStoreRef.values.projectId === x.projectID
      )

      return compileUnits(all)
    },

    onlineTypes() {
      const filterStoreRef = filterStore()
      const proStoreRef = proProjectsStore()

      return (
        proStoreRef.onlineTypes.filter(
          (x) => filterStoreRef.values.projectId === x.projectID
        ) ?? []
      )
    },

    resale(): {
      all: OnlineType[]
      normal: OnlineType[]
      highlighted: OnlineType[]
      fractions: IFractionGroup[]
      categories: ICategoryGroup[]
    } {
      const { all, normal, highlighted } = compileResale(this.onlineTypes)

      return {
        all,
        normal,
        highlighted,
        fractions: this.fractionGroups.resale,
        categories: this.categoryGroups.resale,
      }
    },

    account(): IProRawProjectAccount | null {
      const proStoreRef = proProjectsStore()

      if (typeof this.project === 'undefined') {
        return null
      }

      return proStoreRef.account
    },

    mapper(): IProRawProjectMapper | null {
      if (typeof this.project === 'undefined') {
        return null
      }

      return this.project.mapper
    },

    reportReady(): boolean {
      return (this.project?.status?.code ?? '') !== 'not-started'
    },

    paradigmContent(): {
      account: string
      builder: string
      projectAddress: string
      projectTypeText: string
      projectTypeTextAlt: string
    } {
      let projectTypeText = ''
      let projectTypeTextAlt = ''

      if (this.project) {
        projectTypeText =
          this.project.data.projectType === 'renovation'
            ? mixWB('RENOVATION_2')
            : mixWB('DEMOLITION_2')

        projectTypeTextAlt =
          this.project.data.projectType === 'renovation'
            ? mixWB('THE_RENOVATION_WORK')
            : mixWB('THE_DEMOLITION_WORK')
      }

      return {
        account: this.account?.name ?? '',
        builder: mixWB('BUILDER'),
        projectAddress: this.projectAddress ?? '',
        projectTypeText,
        projectTypeTextAlt,
      }
    },

    /**
     * Grouping for overview pages
     */
    fractionGroups(): {
      all: IFractionGroup[]
      unknown: IFractionGroup[]
      prevention: IFractionGroup[]
      reuse: IFractionGroup[]
      remaining: IFractionGroup[]
      resale: IFractionGroup[]
    } {
      return compileFractionGroups(this.onlineTypes)
    },

    categoryGroups(): {
      all: ICategoryGroup[]
      withOnlineTypes: ICategoryGroup[]
      withoutOnlineTypes: ICategoryGroup[]
      resale: ICategoryGroup[]
    } {
      return compileCategoryGroups(this.onlineTypes)
    },

    remediationGroups(): {
      withoutCleaning: OnlineType[]
      withCleaning: OnlineType[]
      potential: OnlineType[]
    } {
      return compileRemdiationGroups(this.onlineTypes)
    },

    /**
     * Filtered by building content
     */
    unitIDsByBuilding(): string[] {
      const filterStoreRef = filterStore()
      const selectedUnit = filterStoreRef.query.unit

      if (!selectedUnit || selectedUnit === 'all') {
        return []
      }

      const building = this.units.all.find(
        (unit) => unit.hashID === selectedUnit
      ) as Building

      if (!building) {
        return []
      }

      return [building.id, ...building.floorIDs, ...building.apartmentIDs]
    },

    buildingFilterApplied(): boolean {
      return !!this.unitIDsByBuilding.length
    },

    onlineTypesByBuilding(): OnlineType[] {
      if (!this.buildingFilterApplied) {
        return this.onlineTypes
      }

      return this.onlineTypes.filter((x) =>
        x.unitIDs.some((id) => this.unitIDsByBuilding.includes(id))
      )
    },

    categoryGroupsByBuilding(): {
      all: ICategoryGroup[]
      withOnlineTypes: ICategoryGroup[]
      withoutOnlineTypes: ICategoryGroup[]
      resale: ICategoryGroup[]
    } {
      if (!this.buildingFilterApplied) {
        return this.categoryGroups
      }

      return compileCategoryGroups(this.onlineTypesByBuilding)
    },

    fractionGroupsByBuilding(): {
      all: IFractionGroup[]
      unknown: IFractionGroup[]
      prevention: IFractionGroup[]
      reuse: IFractionGroup[]
      remaining: IFractionGroup[]
      resale: IFractionGroup[]
    } {
      if (!this.buildingFilterApplied) {
        return this.fractionGroups
      }

      return compileFractionGroups(this.onlineTypesByBuilding)
    },

    remediationGroupsByBuilding(): {
      withoutCleaning: OnlineType[]
      withCleaning: OnlineType[]
      potential: OnlineType[]
    } {
      if (!this.buildingFilterApplied) {
        return this.remediationGroups
      }

      return compileRemdiationGroups(this.onlineTypesByBuilding)
    },

    resaleByBuilding(): {
      all: OnlineType[]
      normal: OnlineType[]
      highlighted: OnlineType[]
      fractions: IFractionGroup[]
      categories: ICategoryGroup[]
    } {
      if (!this.buildingFilterApplied) {
        return this.resale
      }

      const { all, normal, highlighted } = compileResale(
        this.onlineTypesByBuilding
      )

      return {
        all,
        normal,
        highlighted,
        fractions: this.fractionGroupsByBuilding.resale,
        categories: this.categoryGroupsByBuilding.resale,
      }
    },

    samplesByBuilding(): {
      all: ProSample[]
      standard: ProSample[]
      assessed: ProSample[]
      equivalent: ProSample[]
      existing: ProSample[]
      postponed: ProSample[]
    } {
      if (!this.buildingFilterApplied) {
        return this.samples
      }

      const projectSamples = this.samples.all

      // Return only samples that are related to a material registred on the selected building
      const samples = projectSamples.filter((sample) =>
        sample
          .getSampledType()
          ?.unitIDs.some((id) => this.unitIDsByBuilding.includes(id))
      )

      return compileSamples(samples)
    },

    unitsByBuilding(): {
      all: (Building | Floor | Apartment)[]
      active: { all: (Building | Floor | Apartment)[]; buildings: Building[] }
      selected: {
        all: (Building | Floor | Apartment)[]
        buildings: Building[]
        floors: Floor[]
        apartments: Apartment[]
      }
      custom: (Building | Floor | Apartment)[]
    } {
      if (!this.buildingFilterApplied) {
        return this.units
      }

      const proStoreRef = proProjectsStore()

      const units = proStoreRef.allUnits
        .map((x) => {
          return this.unitIDsByBuilding.includes(x.id) ? x : null
        })
        .filter(Boolean) as (Building | Floor | Apartment)[]

      return compileUnits(units)
    },
  },
})
