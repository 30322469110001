import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a7c3878"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "FeedbackPin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hidePin())),
      class: "FeedbackPinClose"
    }, [
      _createVNode(_component_InlineSVG, {
        src: require('@/assets/svg/close.svg')
      }, null, 8, ["src"])
    ]),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setShownDialog('feedback'))),
      class: "FeedbackPinOpen"
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('GIVE_FEEDBACK')), 1),
        _createVNode(_component_InlineSVG, {
          src: require('@/assets/svg/feedback.svg')
        }, null, 8, ["src"])
      ])
    ])
  ]))
}