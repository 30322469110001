import { captureMessage, Severity, withScope } from '@sentry/vue'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkForDuplicatedIDs = (errorLabel: string, data: any[]) => {
  const { dupIDs } = data.reduce(
    (prev, item) => {
      if (!('hashID' in item)) {
        throw new Error('Hash ID must exist on data item to be checked')
      }

      const { hashID } = item

      prev.idsFound.includes(hashID)
        ? prev.dupIDs.push(hashID)
        : prev.idsFound.push(hashID)

      return prev
    },
    {
      idsFound: [] as string[],
      dupIDs: [] as string[],
    }
  )

  if (dupIDs.length) {
    withScope((scope) => {
      scope.setLevel(Severity.Critical)
      scope.setExtra("Duplicated hash-ID's", dupIDs)
      captureMessage(`ERROR: Duplicate ${errorLabel} hash-ID's found`)
    })
  }
}
