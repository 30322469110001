/* eslint-disable no-use-before-define */
import { galleryStore } from '@/store'
import { DirectiveBinding } from 'vue'

export interface IGalleryConfiguration {
  collection: string
}

interface IMyDirectiveBinding extends DirectiveBinding {
  value: IGalleryConfiguration | string
}

const defaultConfig: IGalleryConfiguration = {
  collection: '',
}

const gallery = {
  mounted(el: HTMLElement, binding: IMyDirectiveBinding) {
    const targets = getElements(el)
    const config = getConfig(binding.value)

    targets.forEach((target, index) => {
      applyConfig(target, index, config)

      target.addEventListener('click', onClick.bind(null, target))
    })
  },
  beforeUnmount(el: HTMLElement) {
    const targets = getElements(el)

    targets.forEach((target) => {
      target.removeEventListener('click', onClick.bind(null, target))
    })
  },
}

export default gallery

/**
 * Helper methods
 */

const onClick = (el: HTMLElement) => {
  const { openCollection } = galleryStore()

  const index = Number(el.getAttribute('data-index'))
  const collection = el.getAttribute('data-collection') ?? ''

  openCollection(collection, index)
}

const getElements = (el: HTMLElement) => {
  const isIMG = el.tagName === 'IMG'

  const targets = isIMG
    ? [el]
    : ([...el.querySelectorAll('img')] as HTMLElement[])

  return targets
}

const getConfig = (
  userConfig: IGalleryConfiguration | string
): IGalleryConfiguration => {
  let config = defaultConfig

  if (typeof userConfig === 'string') {
    config.collection = userConfig
  } else {
    config = {
      ...defaultConfig,
      ...userConfig,
    }
  }

  if (!config.collection) {
    throw new Error('v-gallery must have a collection name')
  }

  return config
}

const applyConfig = (
  el: HTMLElement,
  index: number,
  config: IGalleryConfiguration
) => {
  const { collection } = config

  el.setAttribute('data-index', index.toString())
  el.setAttribute('data-collection', collection)
}
