import { mixWB } from '@/globals/javascript/_utils/mixins'
import { currentStore } from '@/store'
import { orderBy } from 'lodash-es'
import { defineStore } from 'pinia'

interface IParadigm {
  order: number
  index: number
  reference: string
  title: string
  url: string
  component: string
}
interface IParadigmState {
  blackLists: { [key: string]: string[] }
  paradigmList: IParadigm[]
}

export default defineStore('paradigm', {
  state: () =>
    ({
      blackLists: {
        'damage-related-screening': ['global-warming-potential'],
      },
      paradigmList: [
        {
          order: 1,
          index: -1,
          reference: 'INTRO',
          title: mixWB('INTRODUCTION'),
          url: 'introduction',
          component: 'ParadigmIntro',
        },
        {
          order: 2,
          index: -1,
          reference: 'UN',
          title: mixWB('UNS_SUSTAINABILITY_GOALS'),
          url: 'un-sustainability-goals',
          component: 'ParadigmUN',
        },
        {
          order: 3,
          index: -1,
          reference: 'DGNB',
          title: mixWB('DGNB_SUSTAINABILITY_CERTIFICATION'),
          url: 'dgnb-sustainability-certification',
          component: 'ParadigmDGNB',
        },
        {
          order: 4,
          index: -1,
          reference: 'INSPECTION',
          title: mixWB('INSPECTION_AND_SAMPLING'),
          url: 'inspection-and-sampling',
          component: 'ParadigmInspection',
        },
        {
          order: 5,
          index: -1,
          reference: 'VALUES',
          title: mixWB(
            'LIMIT_VALUES_FOR_ENVIRONMENTALLY_PROBLEMATIC_SUBSTANCES'
          ),
          url: 'limit-values-for-environmentally-problematic-substances',
          component: 'ParadigmValues',
        },
        {
          order: 6,
          index: -1,
          reference: 'HANDLING',
          title: mixWB(
            'HANDLING_OF_MATERIALS_WITH_ENVIRONMENTALLY_HAZARDOUS_SUBSTANCES'
          ),
          url: 'handling-of-materials-with-environmentally-problematic-substances',
          component: 'ParadigmHandling',
        },
        {
          order: 7,
          index: -1,
          reference: 'REMEDIATION',
          title: mixWB('PRECAUTIONS_FOR_REMEDIATION_AND_DEMOLITION'),
          url: 'precautions-for-decontamination-and-demolition',
          component: 'ParadigmRemediation',
        },
        {
          order: 8,
          index: -1,
          reference: 'GWP',
          title: mixWB('CALCULATION_OF_GWP'),
          url: 'global-warming-potential',
          component: 'ParadigmGWP',
        },
      ],
    } as IParadigmState),

  actions: {
    isBlackListed(id: string) {
      const { project } = currentStore()

      const projectType = project?.data.projectType

      if (!projectType) {
        return false
      }

      return !!this.$state.blackLists[projectType]?.includes(id)
    },
  },

  getters: {
    sections(state) {
      const { project } = currentStore()

      if (!project) {
        return []
      }

      const list = project.data.isScreening
        ? state.paradigmList.filter(
            (p) => !['DGNB', 'UN'].includes(p.reference)
          )
        : state.paradigmList

      const orderedList = orderBy(list, 'order', 'asc')

      return orderedList.map((item, index) => {
        item.index = index + 1
        return item
      })
    },
    sectionsAsObject(): { [index: string]: IParadigm } {
      return this.sections.reduce((prev, cur) => {
        prev[cur.url] = cur
        return prev
      }, {} as { [index: string]: IParadigm })
    },
    urls(): string[] {
      return this.sections.map((s) => s.url)
    },
  },
})
