import { isProCoatingType } from '@/globals/javascript/_utils/util'
import { IStack } from '@/__types__/_pro/partials/stack'

export const useStackAmount = (stack: IStack) => {
  if (!stack.options.isGroup) {
    return
  }

  let isFound = false
  stack.layers.forEach((layer, index) => {
    const { outerInterConnection } = layer

    if (isFound || !outerInterConnection) {
      return
    }

    // 1. Check for stuck split status
    if (outerInterConnection.splitStatus === 'stuck') {
      isFound = true
      return
    }

    // 2. Check if 2 layers could contaminate each other
    if (outerInterConnection.effectDirection === 'none') {
      return
    }

    const innerType = layer.pType
    const outerType = stack.layers[index + 1].pType

    if (isProCoatingType(innerType) || isProCoatingType(outerType)) {
      return
    }

    // 2.1 Check left
    if (['both', 'left'].includes(outerInterConnection.effectDirection)) {
      if (
        outerType.samples.sampleIDs.length ||
        outerType.assessments.sampleIDs.length
      ) {
        isFound = true
        return
      }
    }

    // 2.2 Check right
    if (['both', 'right'].includes(outerInterConnection.effectDirection)) {
      // Check for coating
      if (innerType.coating.coatingTypeIDs.length) {
        isFound = true
        return
      }

      // Check material
      if (
        innerType.samples.sampleIDs.length ||
        innerType.assessments.sampleIDs.length
      ) {
        isFound = true
        return
      }
    }
  })

  stack.options.useStackAmount = isFound
}
