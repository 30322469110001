
import { galleryStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import Hammer, { PointerEventInput } from 'hammerjs'
import Spinner from '@/components/Spinner.vue'

export default defineComponent({
  name: 'GalleryOverlay',
  data() {
    return {
      states: {
        isDragging: false,
        lastPosX: 0,
        lastPosY: 0,
        initScale: 1,
      },
      transform: {
        angle: 0,
        scale: 1,
        translate: {
          x: 0,
          y: 0,
        },
      },
      selectors: {
        currentThumbnail: '.Thumbnail:not(.IsInActive)',
        currentFocusImage: '.FocusImage:not(.NotActive)',
      },
      hammerjs: null as any,
    }
  },
  computed: {
    ...mapState(galleryStore, ['selectedCollection', 'currentIndex']),
    ...mapState(galleryStore, {
      allowLoop: ({ configuration }) => configuration.loop,
      thumbnails: ({ sources }) => sources.thumbnails,
      images: ({ sources }) => sources.fullSize,
    }),
    imageTransform(): { transform: string } {
      const { scale, angle, translate } = this.transform
      const transform = `scale(${scale}) translate(${translate.x}px, ${translate.y}px) rotate(${angle}deg)`

      return {
        transform,
      }
    },
  },
  watch: {
    selectedCollection: {
      handler(value, prevValue) {
        if (value) {
          this.addEventListerners()
        }

        if (!value && prevValue) {
          this.removeEventListerners()
          this.resetHammerJSStates()
        }
      },
      immediate: true,
    },
    currentIndex(index) {
      if (typeof index === 'number') {
        this.resetHammerJSStates()

        this.$nextTick(this.repositionThumbnailScroll)
      }
    },
  },
  methods: {
    ...mapActions(galleryStore, [
      'closeCollection',
      'next',
      'previous',
      'toImage',
    ]),
    addEventListerners() {
      window.addEventListener('keydown', this.onKeyDown)
      window.addEventListener('resize', this.repositionThumbnailScroll)

      this.addHammerJSEvents()
    },
    removeEventListerners() {
      window.removeEventListener('keydown', this.onKeyDown)
      window.removeEventListener('resize', this.repositionThumbnailScroll)

      this.removeHammerJSEvents()
    },
    onKeyDown(e: KeyboardEvent) {
      switch (e.code) {
        case 'ArrowRight':
          e.preventDefault()
          this.next()
          break

        case 'ArrowLeft':
          e.preventDefault()
          this.previous()
          break

        case 'ArrowUp':
          e.preventDefault()
          this.onZoomButtonClick('in')
          break

        case 'ArrowDown':
          e.preventDefault()
          this.onZoomButtonClick('out')
          break

        case 'Escape':
          e.preventDefault()
          this.closeCollection()
          break
      }
    },
    addHammerJSEvents() {
      this.$nextTick(() => {
        const containerEl = document.body.querySelector(
          this.selectors.currentFocusImage
        ) as HTMLElement

        if (!containerEl) {
          return
        }

        this.hammerjs = new Hammer(containerEl)

        // Setup pan config
        this.hammerjs.add(
          new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 })
        )

        // Setup pinch (scale / zoom) config
        this.hammerjs.add(new Hammer.Pinch())

        // Setup swipe config
        this.hammerjs
          .add(new Hammer.Swipe())
          .recognizeWith(this.hammerjs.get('pan'))

        this.hammerjs.on('panstart panmove panend', this.onPan)
        this.hammerjs.on('swipe', this.onSwipe)
        this.hammerjs.on('pinchstart pinchin pinchout', this.onPinch)
      })
    },
    removeHammerJSEvents() {
      if (!this.hammerjs) {
        return
      }

      this.hammerjs.off('pan', this.onPan)
      this.hammerjs.off('swipe', this.onSwipe)
      this.hammerjs.off('pinch', this.onPinch)
    },
    resetHammerJSStates() {
      this.transform.angle = 0
      this.transform.scale = 1
      this.transform.translate.x = 0
      this.transform.translate.y = 0
    },
    onSwipe(e: PointerEventInput) {
      if (e.velocityX > 1) {
        this.previous()
      }

      if (e.velocityX < -1) {
        this.next()
      }
    },
    onPinch(ev: PointerEventInput) {
      if (ev.type == 'pinchstart') {
        // Make sure the starting point matches starting scale
        this.states.initScale = this.transform.scale
      }

      // Apply incomming scale change to the starting point to avoid exponential scaling
      const scale = this.states.initScale * ev.scale

      this.changeScaleValue(scale)
    },
    onScroll(ev: WheelEvent) {
      const scale = ev.deltaY < 0 ? 1.25 : 0.75

      this.changeScaleValue(this.transform.scale * scale)
    },
    onZoomButtonClick(direction: 'in' | 'out') {
      const scale = direction === 'in' ? 1.25 : 0.75

      this.changeScaleValue(this.transform.scale * scale)
    },
    changeScaleValue(scale: number) {
      this.transform.scale = Math.max(1, scale)

      const elem = document.body.querySelector(
        this.selectors.currentFocusImage
      ) as HTMLElement

      if (!elem) {
        return
      }

      const parentElem = elem.offsetParent as HTMLElement
      const elemWidth = elem.offsetWidth * this.transform.scale
      const elemHeight = elem.offsetHeight * this.transform.scale

      const parentWidth = parentElem?.offsetWidth ?? 0
      const parentHeight = parentElem?.offsetHeight ?? 0

      if (parentWidth >= elemWidth) {
        this.repositionImage('x')
        return
      }

      if (parentHeight >= elemHeight) {
        this.repositionImage('y')
        return
      }
    },
    onRotate() {
      const rotationIncrement = 90
      const changedRotation = this.transform.angle + rotationIncrement
      const newRotation = changedRotation >= 360 ? 0 : changedRotation

      this.transform.angle = newRotation
    },
    onPan(ev: PointerEventInput) {
      let elem = ev.target

      // Get elements information
      const parentElem = elem.offsetParent as HTMLElement
      const elemWidth = elem.offsetWidth * this.transform.scale
      const elemHeight = elem.offsetHeight * this.transform.scale
      const parentWidth = parentElem?.offsetWidth ?? 0
      const parentHeight = parentElem?.offsetHeight ?? 0

      // Set pan limits
      // 1. Get width diff between container and image
      // 2. Divide widthDiff by 2 since element staring point is centered
      // 3. Take the current image scale into consideration
      const widthDifference = elemWidth - parentWidth
      const heightDifference = elemHeight - parentHeight

      const panXLimit = widthDifference / 2 / this.transform.scale
      const panYLimit = heightDifference / 2 / this.transform.scale

      // DRAG STARTED
      if (ev.type === 'panstart') {
        // Find starting position
        if (elemWidth > parentWidth) {
          this.states.lastPosX = this.transform.translate.x
        }

        if (elemHeight > parentHeight) {
          this.states.lastPosY = this.transform.translate.y
        }
      }

      // Adjust movement accoding to scale
      const deltaY = ev.deltaY / this.transform.scale
      const deltaX = ev.deltaX / this.transform.scale

      // Calculate new X position
      if (elemWidth > parentWidth) {
        const posX = deltaX + this.states.lastPosX

        this.transform.translate.x = Math.max(
          Math.min(posX, panXLimit),
          panXLimit * -1
        )
      }

      // Calculate new Y position
      if (elemHeight > parentHeight) {
        const posY = deltaY + this.states.lastPosY

        this.transform.translate.y = Math.max(
          Math.min(posY, panYLimit),
          panYLimit * -1
        )
      }
    },
    repositionImage(orientation: 'both' | 'x' | 'y') {
      if (['both', 'x'].includes(orientation)) {
        this.states.lastPosX = 0
        this.transform.translate.x = 0
      }
      if (['both', 'y'].includes(orientation)) {
        this.states.lastPosY = 0
        this.transform.translate.y = 0
      }
    },
    repositionThumbnailScroll() {
      const activeThumbnail = document.body.querySelector(
        this.selectors.currentThumbnail
      )

      activeThumbnail?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    },
  },
  components: { Spinner },
})
