/* eslint-disable no-use-before-define */
import { CleaningTask } from '@/globals/javascript/models/CleaningTask'
import OnlineType, {
  IOTypeAmountCleaningTaskItem,
} from '@/globals/javascript/models/onlineType/OnlineType'
import { mixFormatNumber, mixWB } from '@/globals/javascript/_utils/mixins'

const cleaningThickness = 0.001

export function setAmountFromCleaningTasks(this: OnlineType) {
  this.cleaningTasks.forEach((cleaningTask) => {
    // 2.1 Inner - To be cleaned
    if (
      cleaningTask.side === 'inner' &&
      cleaningTask.toBeCleaned?.id === this.id
    ) {
      innerToBeCleaned.call(this, cleaningTask)
    }
    // 2.2 Outer - To be cleaned
    if (
      cleaningTask.side === 'outer' &&
      cleaningTask.toBeCleaned?.id === this.id
    ) {
      outerToBeCleaned.call(this, cleaningTask)
    }
    // 2.3 Inner - To be removed
    if (
      cleaningTask.side === 'outer' &&
      cleaningTask.toBeRemoved.id === this.id
    ) {
      innerToBeRemoved.call(this, cleaningTask)
    }
    // 2.4 Outer - To be removed
    if (
      cleaningTask.side === 'inner' &&
      cleaningTask.toBeRemoved.id === this.id
    ) {
      outerToBeRemoved.call(this, cleaningTask)
    }
  })
}

function getAmountCleaningTaskItem(
  taskID: string
): IOTypeAmountCleaningTaskItem {
  return {
    taskID,
    status: 'OK',
    tons: NaN,
    tonsAsText: '',
    calculation: '',
  }
}

function calculationFailed(
  this: OnlineType,
  amountCleaningTaskItem: IOTypeAmountCleaningTaskItem,
  cleaningTask: CleaningTask,
  oTypeTitle: string
) {
  amountCleaningTaskItem.status = 'FAIL'
  amountCleaningTaskItem.calculation = mixWB(
    'CLEANING_OF_X_THE_QUANTITY_COULD_NOT_BE_CALCULATED',
    [oTypeTitle]
  )
  this.amounts.amountsPerCleaningTask.push(amountCleaningTaskItem)
  this.amounts.hasMissingAmounts = true
}

function calculationUnknown(
  this: OnlineType,
  amountCleaningTaskItem: IOTypeAmountCleaningTaskItem
) {
  amountCleaningTaskItem.status = 'TYPE-UNKNOWN'
  amountCleaningTaskItem.calculation = mixWB(
    'THE_TYPE_TO_BE_CLEANED_IS_UNKNOWN'
  )
  this.amounts.amountsPerCleaningTask.push(amountCleaningTaskItem)
  this.amounts.hasMissingAmounts = true
}

function innerToBeCleaned(this: OnlineType, cleaningTask: CleaningTask) {
  const amountCleaningTaskItem = getAmountCleaningTaskItem(cleaningTask.id)

  // 1. Check if calculation is doable
  // - Check if cleaning task has m2
  // - Check if oType has a totalTons
  // - Check if first pType is measured in m2
  // - Check if first pType has an amount
  // - Check if first pType has a density
  const m2ToBeCleaned = cleaningTask.m2
  const totalTons = this.amounts.totalTons
  const firstPTypeAmountItem = this.amounts.amountsPerPType[0]
  const firstPType = this.types[0]
  const firstPTypeDensity = firstPType.getDensity()

  if (
    !m2ToBeCleaned ||
    !totalTons ||
    firstPTypeAmountItem.amountType !== 'm2' ||
    !firstPTypeAmountItem.tons ||
    !firstPTypeDensity
  ) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      cleaningTask.toBeRemoved.titles.complete
    )
    return
  }

  // 2. Get tons to be cleaned
  const tons = m2ToBeCleaned * cleaningThickness * firstPTypeDensity

  // 3. Check that totals tons still will be above 0
  if (tons <= 0 || totalTons - tons < 0) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      cleaningTask.toBeRemoved.titles.complete
    )
    return
  }

  // 4. Update data
  const tonsToBeRemoved = tons * -1
  const tonsAsText = mixFormatNumber(tonsToBeRemoved, 3).toString()
  amountCleaningTaskItem.tons = tonsToBeRemoved
  amountCleaningTaskItem.tonsAsText = tonsAsText
  amountCleaningTaskItem.calculation = mixWB(
    'CLEANING_OF_X_CALCULATION_STRING_1',
    [
      cleaningTask.toBeRemoved.titles.complete,
      mixFormatNumber(m2ToBeCleaned).toString(),
      mixFormatNumber(cleaningThickness).toString(),
      mixFormatNumber(firstPTypeDensity).toString(),
      tonsAsText,
    ]
  )

  this.amounts.amountsPerCleaningTask.push(amountCleaningTaskItem)
  this.amounts.totalTons += tonsToBeRemoved
}

function outerToBeCleaned(this: OnlineType, cleaningTask: CleaningTask) {
  const amountCleaningTaskItem = getAmountCleaningTaskItem(cleaningTask.id)

  // 1. Check if calculation is doable
  // - Check if cleaning task has m2
  // - Check if oType has a totalTons
  // - Check if last pType is measured in m2
  // - Check if last pType has an amount
  // - Check if last pType has a density
  const m2ToBeCleaned = cleaningTask.m2
  const totalTons = this.amounts.totalTons
  const lastPTypeAmountItem = this.amounts.amountsPerPType.slice(-1)[0]
  const lastPType = this.types.slice(-1)[0]
  const lastPTypeDensity = lastPType.getDensity()

  if (
    !m2ToBeCleaned ||
    !totalTons ||
    lastPTypeAmountItem.amountType !== 'm2' ||
    !lastPTypeAmountItem.tons ||
    !lastPTypeDensity
  ) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      cleaningTask.toBeRemoved.titles.complete
    )
    return
  }

  // 2. Get tons to be cleaned
  const tons = m2ToBeCleaned * cleaningThickness * lastPTypeDensity

  // 3. Check that totals tons still will be above 0
  if (tons <= 0 || totalTons - tons < 0) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      cleaningTask.toBeRemoved.titles.complete
    )
    return
  }

  // 4. Update data
  const tonsToBeRemoved = tons * -1
  const tonsAsText = mixFormatNumber(tonsToBeRemoved, 3).toString()
  amountCleaningTaskItem.tons = tonsToBeRemoved
  amountCleaningTaskItem.tonsAsText = tonsAsText
  amountCleaningTaskItem.calculation = mixWB(
    'CLEANING_OF_X_CALCULATION_STRING_1',
    [
      cleaningTask.toBeRemoved.titles.complete,
      mixFormatNumber(m2ToBeCleaned).toString(),
      mixFormatNumber(cleaningThickness).toString(),
      mixFormatNumber(lastPTypeDensity).toString(),
      tonsAsText,
    ]
  )

  this.amounts.amountsPerCleaningTask.push(amountCleaningTaskItem)
  this.amounts.totalTons += tonsToBeRemoved
}

function innerToBeRemoved(this: OnlineType, cleaningTask: CleaningTask) {
  const amountCleaningTaskItem = getAmountCleaningTaskItem(cleaningTask.id)

  // 1. Check if calculation is doable
  const oTypeToBeCleaned = cleaningTask.toBeCleaned

  // 1.1 Check if inner to be cleaned is unknown
  if (!oTypeToBeCleaned) {
    calculationUnknown.call(this, amountCleaningTaskItem)
    return
  }

  // 1.2 Check this oType which will be removed
  const m2ToBeCleaned = cleaningTask.m2
  const totalTons = this.amounts.totalTons

  if (!oTypeToBeCleaned || !m2ToBeCleaned || !totalTons) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      oTypeToBeCleaned?.titles.complete || ''
    )
    return
  }

  // 1.3 Check oType to be cleaned
  const oTypeToBeCleanedTotalTons = oTypeToBeCleaned.amounts.totalTons
  const oTypeToBeCleanedLastPTypeAmountItem =
    oTypeToBeCleaned.amounts.amountsPerPType.slice(-1)[0]
  const oTypeToBeCleanedLastPType = oTypeToBeCleaned.types.slice(-1)[0]
  const oTypeToBeCleanedLastPTypeDensity =
    oTypeToBeCleanedLastPType.getDensity()

  if (
    !oTypeToBeCleanedTotalTons ||
    oTypeToBeCleanedLastPTypeAmountItem.amountType !== 'm2' ||
    !oTypeToBeCleanedLastPTypeAmountItem.tons ||
    !oTypeToBeCleanedLastPTypeDensity
  ) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      oTypeToBeCleaned.titles.complete
    )
    return
  }

  // 2. Get tons to be cleaned
  const tons =
    m2ToBeCleaned * cleaningThickness * oTypeToBeCleanedLastPTypeDensity

  // 3. Check tons
  if (tons <= 0) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      oTypeToBeCleaned.titles.complete
    )
    return
  }

  // 4. Update data
  const tonsAsText = mixFormatNumber(tons, 3).toString()
  amountCleaningTaskItem.tons = tons
  amountCleaningTaskItem.tonsAsText = tonsAsText
  amountCleaningTaskItem.calculation = mixWB(
    'CLEANING_OF_X_CALCULATION_STRING_1',
    [
      oTypeToBeCleaned.titles.complete,
      mixFormatNumber(m2ToBeCleaned).toString(),
      mixFormatNumber(cleaningThickness).toString(),
      mixFormatNumber(oTypeToBeCleanedLastPTypeDensity).toString(),
      tonsAsText,
    ]
  )

  this.amounts.amountsPerCleaningTask.push(amountCleaningTaskItem)
  this.amounts.totalTons += tons
}

function outerToBeRemoved(this: OnlineType, cleaningTask: CleaningTask) {
  const amountCleaningTaskItem = getAmountCleaningTaskItem(cleaningTask.id)

  // 1. Check if calculation is doable
  const oTypeToBeCleaned = cleaningTask.toBeCleaned

  // 1.1 Check this oType which will be removed
  const m2ToBeCleaned = cleaningTask.m2
  const totalTons = this.amounts.totalTons

  if (!oTypeToBeCleaned || !m2ToBeCleaned || !totalTons) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      oTypeToBeCleaned?.titles.complete || ''
    )

    return
  }

  // 1.2 Check oType to be cleaned
  const oTypeToBeCleanedTotalTons = oTypeToBeCleaned.amounts.totalTons
  const oTypeToBeCleanedFirstPTypeAmountItem =
    oTypeToBeCleaned.amounts.amountsPerPType[0]

  const oTypeToBeCleanedFirstPType = oTypeToBeCleaned.types[0]
  const oTypeToBeCleanedFirstPTypeDensity =
    oTypeToBeCleanedFirstPType.getDensity()

  if (
    !oTypeToBeCleanedTotalTons ||
    oTypeToBeCleanedFirstPTypeAmountItem.amountType !== 'm2' ||
    !oTypeToBeCleanedFirstPTypeAmountItem.tons ||
    !oTypeToBeCleanedFirstPTypeDensity
  ) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      oTypeToBeCleaned.titles.complete
    )
    return
  }

  // 2. Get tons to be cleaned
  const tons =
    m2ToBeCleaned * cleaningThickness * oTypeToBeCleanedFirstPTypeDensity

  // 3. Check tons
  if (tons <= 0) {
    calculationFailed.call(
      this,
      amountCleaningTaskItem,
      cleaningTask,
      oTypeToBeCleaned.titles.complete
    )

    return
  }

  // 4. Update data
  const tonsAsText = mixFormatNumber(tons, 3).toString()
  amountCleaningTaskItem.tons = tons
  amountCleaningTaskItem.tonsAsText = tonsAsText
  amountCleaningTaskItem.calculation = mixWB(
    'CLEANING_OF_X_CALCULATION_STRING_1',
    [
      oTypeToBeCleaned.titles.complete,
      mixFormatNumber(m2ToBeCleaned).toString(),
      mixFormatNumber(cleaningThickness).toString(),
      mixFormatNumber(oTypeToBeCleanedFirstPTypeDensity).toString(),
      tonsAsText,
    ]
  )

  this.amounts.amountsPerCleaningTask.push(amountCleaningTaskItem)
  this.amounts.totalTons += tons
}
