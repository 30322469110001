
import Backdrop from '@/components/Backdrop.vue'
import storageKeys from '@/globals/javascript/constants/storageKeys'
import { appStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Dialog',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      required: false,
      default: 'medium',
    },
    hasEasyClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isVeryTall: false,
      showDialogBox: false,
    }
  },
  computed: {
    ...mapState(appStore, {
      dialogName: 'showDialog',
      errorState: 'errorState',
    }),
    isShowing() {
      if (this.errorState) {
        return false
      }

      return this.dialogName === this.name
    },
    dialogClasses() {
      return {
        SizeSmall: this.size === 'small',
        SizeMedium: this.size === 'medium',
        IsVeryTall: this.isVeryTall,
        isShowing: this.showDialogBox,
      }
    },
  },
  watch: {
    isShowing: {
      // Waiting for a new text to be added, then apply the active class
      handler(to) {
        if (!to) {
          this.isVeryTall = false
          this.showDialogBox = false
          return
        }
        requestAnimationFrame(() => {
          this.onCheckHeight()
          this.showDialogBox = true
        })
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(appStore, ['setShownDialog']),
    onBackdropClick() {
      if (this.hasEasyClose) {
        this.closeDialog()
      }
    },
    onCloseButtonClick() {
      this.closeDialog()
    },
    onCheckHeight() {
      this.isVeryTall = false
      const { height } = (
        this.$refs.Dialog as HTMLElement
      ).getBoundingClientRect()

      if (height > window.innerHeight - window.innerHeight * 0.3) {
        this.isVeryTall = true
      }
    },
    closeDialog() {
      if (this.dialogName === 'pageGuide') {
        localStorage.setItem(storageKeys.LS_PAGE_GUIDE_SEEN, 'true')
      }

      this.setShownDialog()
    },
  },
  components: { Backdrop },
})
