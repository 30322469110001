import { App } from 'vue'
import { captureException, captureMessage, setTag } from '@sentry/vue'

export interface ISentry {
  captureException: typeof captureException
  captureMessage: typeof captureMessage
  setTag: typeof setTag
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$sentry = {
      captureException,
      captureMessage,
      setTag,
    } as ISentry
  },
}
