import { IPTypeAmountItem } from '@/globals/javascript/models/onlineType/OnlineType'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { mixFormatNumber, mixWB } from '@/globals/javascript/_utils/mixins'
import { IAmountTypes } from '@/__types__/utils/amountTypes'

export function getTonsFromM2(this: ProType, m2: number): IPTypeAmountItem {
  const amount = this.amounts[0]
  const density = this.getDensity()

  const amountItem: IPTypeAmountItem = {
    pTypeID: this.id,
    status: 'OK',
    amountType: (amount?.data?.type as IAmountTypes) || '',
    amountValue: m2,
    tons: NaN,
    tonsAsText: '',
    calculation: '',
  }

  if (!density) {
    amountItem.status = 'FAIL'
    amountItem.calculation = mixWB(
      'X_THE_DENSITY_OF_THE_MATERIAL_COULD_NOT_BE_FOUND',
      [this.getTitle()]
    )
    return amountItem
  }

  // Case: Skipped
  if (!amount || amount?.data?.type === 'skip') {
    amountItem.status = 'FAIL'
    amountItem.calculation = mixWB('X_QUANTITY_NOT_SPECIFIED', [
      this.getTitle(),
    ])
    return amountItem
  }

  // Case: m2 used
  if (amount?.data?.type === 'm2') {
    // Calculate tons
    const thickness = amount.data.thickness / 1000
    const tons = m2 * thickness * density
    const tonsAsText = mixFormatNumber(tons, 3).toString()

    // Update data
    amountItem.tons = tons
    amountItem.tonsAsText = tonsAsText
    amountItem.calculation = mixWB('X_CLEANING_CALCULATION_STRING_1', [
      this.getTitle(),
      mixFormatNumber(m2).toString(),
      mixFormatNumber(thickness).toString(),
      mixFormatNumber(density).toString(),
      tonsAsText,
    ])

    return amountItem
  }

  // Case: Other than skip or m2
  amountItem.status = 'FAIL'
  amountItem.calculation = mixWB('X_QUANTITY_NOT_STATED_IN_M2', [
    this.getTitle(),
  ])
  return amountItem
}
