import storageKeys from '@/globals/javascript/constants/storageKeys'
import {
  filterStore,
  proProjectsStore,
  resourcesStore,
  translationsStore,
} from '@/store'
import { defineStore } from 'pinia'

export type IBootingStateText = '' | 'getting-data' | 'preparing-project'

export default defineStore('app', {
  state: () => ({
    booting: true,
    bootingStateText: '' as IBootingStateText,
    isResale: false,
    packageVersion: process.env.PACKAGE_VERSION || '0',
    errorState: null as null | { code: string; message: string },
    showDialog: '',
    feedback: {
      showPin: null as null | boolean,
    },
    menu: {
      isMinimized: false,
      isHidden: false,
      mode: 'static',
    },
    device: 'above-mobile',
  }),

  actions: {
    setIsResale(predicate: boolean) {
      this.$state.isResale = predicate
    },
    setErrorState(code: string, message: string) {
      this.$patch({
        errorState: {
          code,
          message,
        },
      })
    },
    setBootingState(bool: boolean) {
      this.$patch({
        booting: bool,
      })
    },
    setBootingStateText(text: IBootingStateText) {
      this.$patch({
        bootingStateText: text,
      })
    },
    setShownDialog(name?: string | null) {
      this.$state.showDialog = name ?? ''
    },
    setShowFeedbackPinState(predicate?: boolean) {
      if (predicate === undefined) {
        const seenState = !localStorage.getItem(storageKeys.LS_FEEDBACK_SEEN)
        this.$state.feedback.showPin = seenState

        return
      }

      this.$state.feedback.showPin = predicate
    },
    setMenuHiddenState(predicate?: boolean) {
      this.$state.menu.isHidden =
        predicate === undefined ? !this.$state.menu.isHidden : predicate
    },
    setMenuMinimizedState(predicate?: boolean) {
      this.$state.menu.isMinimized =
        predicate === undefined ? !this.$state.menu.isMinimized : predicate
    },
    setMenuMode(mode: 'static' | 'overlay') {
      this.$state.menu.mode = mode
    },
    setDeviceType(type: 'mobile' | 'above-mobile') {
      this.$state.device = type
    },
  },

  getters: {
    allDataFetched() {
      const { dataFetched: proProjectsFetched } = proProjectsStore()
      const { dataFetched: ressourcesFetched } = resourcesStore()
      const { translationsFethched } = translationsStore()
      const {
        values: { projectId },
      } = filterStore()

      const dataNotReady =
        !proProjectsFetched ||
        !ressourcesFetched ||
        !translationsFethched ||
        !projectId

      return !dataNotReady
    },
  },
})
