import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import Apartment from '@/globals/javascript/models/proUnit/Apartment'
import Building from '@/globals/javascript/models/proUnit/Building'
import Floor from '@/globals/javascript/models/proUnit/Floor'
import { ENV } from '@/globals/javascript/_utils/env'
import { currentStore, paradigmStore, proProjectsStore } from '@/store'
import { ICategoryGroup } from '@/__types__/categoryGroup'
import { IFractionGroup } from '@/__types__/fractionGroup'
import { defineStore } from 'pinia'

// interface IPanesState {}

export default defineStore('pane', {
  // state: () => ({} as IPanesState),

  actions: {
    getPanePrefix(id: string) {
      return id.split('-')[0]
    },
    getPaneComponentName(id: string) {
      const { urls: paradigmUrls } = paradigmStore()
      const prefix = this.getPanePrefix(id)
      let pane = ''

      if (!this.validatePaneId(prefix, id)) {
        return ''
      }

      if (id === 'featured') {
        return 'PaneFeaturedResale'
      }

      // If id points to paradigm section
      if (paradigmUrls.includes(id)) {
        return 'PaneParadigm'
      }

      switch (prefix) {
        case 'F':
          pane = 'PaneFraction'
          break

        case 'C':
          pane = 'PaneBuildingPart'
          break

        case 'OT':
          pane = 'PaneOnlineType'
          break

        case 'GOT':
          pane = 'PaneResaleOnlineType'
          break

        case 'S':
          pane = 'PaneSample'
          break

        case 'U':
          pane = 'PaneUnit'
          break

        default:
          break
      }

      return pane
    },
    validatePaneId(prefix: string, id: string) {
      const { urls: paradigmUrls, sections } = paradigmStore()

      // If id points to paradigm section
      if (paradigmUrls.includes(id)) {
        return sections.find((s) => s.url === id) ?? null
      }

      if (id === 'featured') {
        return 'featured'
      }

      const { allSamples, onlineTypes, allUnits } = proProjectsStore()
      const { categoryGroupsByBuilding } = currentStore()

      let data = false

      switch (prefix) {
        case 'GOT':
          data = !!onlineTypes.find(
            (oType: OnlineType) => oType.resaleID === id
          )
          break

        case 'OT':
          data = !!onlineTypes.find((oType: OnlineType) => oType.hashID === id)
          break

        case 'F':
          data = !!onlineTypes.find(
            (oType: OnlineType) => oType.fractionID === id
          )
          break

        case 'C':
          data = !!categoryGroupsByBuilding.all.find(
            (category: ICategoryGroup) => category.hashID === id
          )
          break

        case 'S':
          data = !!allSamples.find((s) => s.hashID === id)
          break

        case 'U':
          data = !!allUnits.find((x) => x.hashID === id)
          break

        default:
          if (ENV.isDevelopment) {
            console.error('AppStore: No pane found with identifier [ %s ]', id)
          }

          break
      }

      return data
    },
    getPaneTitleByID(id: string) {
      const prefix = this.getPanePrefix(id)
      const isValid = this.validatePaneId(prefix, id)

      if (!isValid) {
        return ''
      }

      // Resale online type
      if (prefix === 'GOT') {
        const { onlineTypes } = proProjectsStore()
        const onlineType = onlineTypes.find(
          (oType: OnlineType) => oType.resaleID === id
        )

        return onlineType?.titles.complete ?? ''
      }

      // Normal online type
      if (prefix === 'OT') {
        const { onlineTypes } = proProjectsStore()
        const onlineType = onlineTypes.find(
          (oType: OnlineType) => oType.hashID === id
        )

        return onlineType?.titles.complete ?? ''
      }

      // Fraction
      if (prefix === 'F') {
        const { fractionGroupsByBuilding } = currentStore()
        const fractionGroup = fractionGroupsByBuilding.all.find(
          (fraction: IFractionGroup) => fraction.hashID === id
        )

        return fractionGroup?.title ?? ''
      }

      // Category
      if (prefix === 'C') {
        const { categoryGroupsByBuilding } = currentStore()
        const categoryGroup = categoryGroupsByBuilding.all.find(
          (category: ICategoryGroup) => category.hashID === id
        )

        return categoryGroup?.title ?? ''
      }

      // Sample
      if (prefix === 'S') {
        const { allSamples } = proProjectsStore()
        const sample = allSamples.find(
          (sample: ProSample) => sample.hashID === id
        )

        return sample?.getFullTitle() ?? ''
      }

      // Unit
      if (prefix === 'U') {
        const { units } = currentStore()
        const unit = units.active.all.find(
          (unit: Building | Floor | Apartment) => unit.hashID === id
        )

        return unit?.getFullTitle() ?? ''
      }

      return ''
    },
  },

  getters: {},
})
