import Building from '@/globals/javascript/models/proUnit/Building'
import Unit from '@/globals/javascript/models/proUnit/Unit'
import { mixWB } from '@/globals/javascript/_utils/mixins'

interface IFloorData {
  buildingID: string | null
  bbrBuildingID: string | null
  numericFloorValue: number
}

interface IFloorConstructor {
  id: string
  isSelected: boolean
  bbrID: string | null
  bbrData: unknown
  type: string
  data: IFloorData
  apartmentIDs: string[]
}

export default class Floor extends Unit {
  // Floor
  data = {} as IFloorData
  apartmentIDs: string[] = []

  constructor(
    {
      id,
      isSelected,
      bbrID,
      bbrData,
      type,
      data,
      apartmentIDs,
    }: IFloorConstructor,
    projectID: string
  ) {
    super({
      id,
      projectID,
      isSelected,
      bbrID,
      bbrData,
      type,
    })

    this.apartmentIDs = apartmentIDs
    this.data = {
      buildingID: data.buildingID || null,
      bbrBuildingID: data.bbrBuildingID || null,
      numericFloorValue:
        data.numericFloorValue !== null && data.numericFloorValue !== undefined
          ? data.numericFloorValue
          : 0,
    }
  }

  getTitle() {
    if (this.data.numericFloorValue > 0) {
      return mixWB('X_FLOOR', [this.data.numericFloorValue.toString()])
    }
    if (this.data.numericFloorValue === -1) {
      return mixWB('BASEMENT')
    }
    if (this.data.numericFloorValue < 0) {
      return mixWB('BASEMENT_X_SHORT', [
        Math.abs(this.data.numericFloorValue).toString(),
      ])
    }
    return mixWB('GROUND_FLOOR')
  }

  getFullTitle() {
    const floorText = this.getTitle()
    const building = Floor.getUnitById(this.data.buildingID || '')
    const buildingText =
      building instanceof Building ? building.getShortTitle() : mixWB('UNKNOWN')

    return `${buildingText} - ${floorText}`
  }

  getBuildingID() {
    return this.data.buildingID
  }
}
