/* eslint-disable no-use-before-define */
import OnlineType, {
  IPTypeCO2Item,
} from '@/globals/javascript/models/onlineType/OnlineType'

export function setCO2(this: OnlineType) {
  this.co2Amounts = this.types.reduce(
    (prev, pType) => {
      const typeAmountData = pType.getPotentiallyCO2SavingAmount(
        this.stackData.stackM2
      )
      prev.amountsPerPType.push(typeAmountData)

      if (typeAmountData.tons) {
        prev.totalTons += typeAmountData.tons
      } else {
        prev.hasMissingAmounts = true
      }

      return prev
    },
    {
      amountsPerPType: [] as IPTypeCO2Item[],
      totalTons: 0,
      hasMissingAmounts: false as boolean,
    }
  )
}
