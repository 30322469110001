import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import {
  mixContaminationClass,
  mixWB,
} from '@/globals/javascript/_utils/mixins'
import wdcJSON from '@/globals/database/WorkDescriptionCases.json'
import { isProCoatingType, isProType } from '@/globals/javascript/_utils/util'
import { proProjectsStore, resourcesStore } from '@/store'
import { union } from 'lodash-es'
import roomsJSON from '@/globals/database/Rooms.json'
import { createHashID } from '@/globals/javascript/_utils/pane-util'
import { ENV } from '@/globals/javascript/_utils/env'

export function setCourseOfAction(this: OnlineType) {
  const { options, courseOfAction } = this

  // If set for reuse, but is too contaminated - set for demolition
  if (!options.isRecycable && courseOfAction === 'reuse') {
    this.courseOfAction = 'demolition'
    this.setFractionAndEWCCode()
    this.setTitles()
  }
}

export function setHashID(this: OnlineType, useDubID?: boolean) {
  const id = useDubID ? this.dubID : this.id
  this.hashID = createHashID('OT', id)

  if (this.courseOfAction !== 'prevention') {
    this.resaleID = `G${this.hashID}`
  }
}

export function setCustomTypeState(this: OnlineType) {
  const proTypes = this.types.filter((x) => isProType(x)) as ProType[]
  const customProType = proTypes.find((x) => x.options.isCustomType)

  this.options.isCustomType = !!customProType
}

export function setCategoryTitle(this: OnlineType) {
  const { categoriesAsObject } = resourcesStore()

  this.titles.category = mixWB(categoriesAsObject[this.categoryID].translation)
}

export function setFractionTitle(this: OnlineType) {
  let title = ''

  switch (this.fractionID) {
    case 'F-unknown':
      title = mixWB('UNKNOWN')
      break

    case 'F-prevention':
      title = mixWB('NOT_TO_BE_DEMOLISHED')
      break

    case 'F-reuse':
      title = mixWB('TO_BE_REUSED')
      break

    default:
      title = mixWB(this.fraction?.translation ?? '')
      break
  }

  this.titles.fraction = title
}

export function setTypeTitles(this: OnlineType) {
  this.titles.types = this.types.map((pType) => pType.getTitle()).join(' + ')
}

export function setTitles(this: OnlineType) {
  this.setCategoryTitle()
  this.setTypeTitles()
  this.setFractionTitle()

  const { primary, secondary } = this.index
  const { category, types } = this.titles

  this.titles.complete = `${primary}.${secondary} ${category} - ${types}`
}

export function setUnitIDsAndText(this: OnlineType) {
  this.unitIDs = this.types.reduce((prev: string[], pType) => {
    prev = union(prev, pType.unitIDs)
    return prev
  }, [])

  const { allUnits } = proProjectsStore()

  this.unitsAsString = this.unitIDs.reduce((prev, unitID) => {
    const unit = allUnits.find((x) => x.id === unitID)

    if (!unit) {
      return prev
    }

    if (prev) {
      prev += ', '
    }

    prev += unit.getFullTitle()

    return prev
  }, '')
}

export function setStatus(this: OnlineType) {
  if (this.options.hasPostponedSample) {
    this.status.text = mixWB('SAMPLE_POSTPONED')
    this.status.className = mixContaminationClass('')

    return
  }

  if (this.options.hasMissingTestResults) {
    this.status.text = mixWB('STATUS_AWAITING_TEST_RESULTS')
    this.status.className = mixContaminationClass('')

    return
  }

  if (this.options.isPotentiallyContaminated) {
    this.status.text = mixWB('POTENTIALLY_CONTAMINATED')
    this.status.className = mixContaminationClass('')

    return
  }

  const containsAalholmSlotDustSamples = this.samples.some(
    (x) => x.options.isAalholmSlotDustSample
  )

  if (containsAalholmSlotDustSamples) {
    this.status.text = mixWB('SEE_COMMENTS')
    this.status.className = mixContaminationClass('')

    return
  }

  this.status.text = mixWB(wdcJSON[this.finalWDCID]?.translation ?? 'UNKNOWN')
  this.status.className = mixContaminationClass(this.finalWDCID)
}

export function setIsPotentiallyContaminated(this: OnlineType) {
  // WDG-2 wont be contaminated by adjecent onlinetypes
  if (this.wdgID === 'WDG-2') {
    this.potentiallyContaminatedBy = []
    return
  }

  const potentialContaminents: OnlineType[] = []

  // Checking potential contamination sources
  if (['both', 'left'].includes(this.outerEffectDirection)) {
    potentialContaminents.push(...this.outerOnlineTypes)
  }

  if (['both', 'right'].includes(this.innerEffectDirection)) {
    potentialContaminents.push(...this.innerOnlineTypes)
  }

  if (potentialContaminents.length === 0) {
    this.potentiallyContaminatedBy = []
    return
  }

  // Only add those OnlineTypes that have unknown testresults
  this.potentiallyContaminatedBy = potentialContaminents.filter(
    (oType) =>
      oType.options.hasMissingTestResults || oType.options.hasPostponedSample
  )

  this.options.isPotentiallyContaminated =
    !!this.potentiallyContaminatedBy.length
}

export function setSamples(this: OnlineType) {
  const { allSamples, allSplitSamples } = proProjectsStore()
  const combinedSamples = [...allSamples, ...allSplitSamples]

  // Get samples for all material pro types
  this.types.forEach((pType) => {
    const kindOfSample = isProType(pType) ? 'material' : 'coating'
    const typeID = isProType(pType) ? pType.id : pType.originID
    const sample = combinedSamples.find(
      (x) => x.typeID === typeID && x.kindOfSample === kindOfSample
    )

    if (sample) {
      this.samples.push(sample as ProSample)
    }
  })
}

export function setRooms(this: OnlineType) {
  let roomIDs = [] as string[]
  let otherRooms = [] as string[]

  this.types.forEach((pType) => {
    const { places } = pType
    roomIDs = union(roomIDs, places.placeIDs)
    otherRooms = union(otherRooms, places.otherPlaces)
  })

  const list = roomIDs
    .map((roomID) => mixWB(roomsJSON[roomID].translation ?? ''))
    .concat(otherRooms)

  this.rooms.roomIDs = roomIDs
  this.rooms.otherRooms = otherRooms
  this.rooms.listAsText = list
    .map((room, index) => {
      if (index === 0) {
        return room
      }
      return room.toLowerCase()
    })
    .join(', ')
}

export function setFractionAndEWCCode(this: OnlineType) {
  const {
    isCustomType,
    hasMissingTestResults,
    hasPostponedSample,
    isPotentiallyContaminated,
  } = this.options

  // OnlineType is set to stay
  if (this.courseOfAction === 'prevention') {
    this.fractionID = 'F-prevention'
    return
  }

  const fractionUnavailable =
    isCustomType ||
    hasMissingTestResults ||
    hasPostponedSample ||
    isPotentiallyContaminated

  // Fraction not posible to determine
  if (fractionUnavailable) {
    this.fractionID = 'F-unknown'
    return
  }

  // OnlineType is set for reuse as is
  if (this.courseOfAction === 'reuse') {
    this.fractionID = 'F-reuse'
    return
  }

  const { ewcCode, fraction } = this.deriveFractionAndEWCCode()

  this.fraction = fraction
  this.ewcCode = ewcCode
  this.fractionID = fraction?.id ?? 'F-unknown'

  if (ENV.isDevelopment && !fraction?.id) {
    throw Error(`OnlineType.ts: fraction.id is ${fraction?.id}`)
  }
}

export function setMissingTestResultsStatus(this: OnlineType) {
  this.options.hasMissingTestResults = !!this.types.find(
    (x) => x.options.hasMissingTestResult
  )
}

export function setHasPostponedSampleStatus(this: OnlineType) {
  // Any connected type has any postponed sample - set as true
  this.options.hasPostponedSample = this.types.some((t) =>
    t.getSamples().some((x) => x.sampleType === 'postponed')
  )
}

export function setMultiTypeStatus(this: OnlineType) {
  if (this.types.length === 1) {
    this.options.isMultiType = false
    return
  }

  const proTypes = this.types.filter((x) => isProType(x))

  if (proTypes.length > 1) {
    this.options.isMultiType = true
    return
  }

  const isFirstPTypeCoating = isProCoatingType(this.types[0])
  if (isFirstPTypeCoating) {
    this.options.isMultiType = true
    return
  }

  this.options.isMultiType = false
}
