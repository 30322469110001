
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { appStore } from '@/store'
import ErrorPage from '@/views/ErrorPage.vue'
import PageLoader from '@/components/PageLoader.vue'
import { IBootingStateText } from '@/store/app'
import Dialog from '@/components/modals/Dialog.vue'
import PageGuide from '@/components/pageGuide/PageGuide.vue'
import storageKeys from '@/globals/javascript/constants/storageKeys'
import FeedbackFlow from '@/components/feedback/FeedbackFlow.vue'
import GalleryOverlay from '@/components/gallery/GalleryOverlay.vue'
import FeedbackPin from '@/components/feedback/FeedbackPin.vue'
import proProjects from '@/store/modules/pro-projects/pro-projects'

export default defineComponent({
  name: 'App',
  data() {
    return {
      loaderTexts: [''] as string[],
    }
  },
  computed: {
    ...mapState(appStore, [
      'allDataFetched',
      'booting',
      'bootingStateText',
      'errorState',
      'showDialog',
      'feedback',
    ]),
    ...mapState(proProjects, ['onlineTypes']),
  },
  watch: {
    showDialog(value: string) {
      if (value === 'feedback') {
        this.setShowFeedbackPinState(false)
      }
    },
    bootingStateText: {
      handler(to: IBootingStateText) {
        if (to === 'getting-data') {
          this.loaderTexts.push(this.mixWBPreRender('GETTING_DATA'))
        }
        if (to === 'preparing-project') {
          this.loaderTexts.push(this.mixWBPreRender('PREPARING_PROJECT'))
        }
      },
      deep: true,
      immediate: true,
    },
    booting(bool: boolean) {
      if (!bool && this.feedback.showPin === null) {
        // Check if feedback pin is to be shown after 2 minutes
        setTimeout(() => {
          if (this.feedback.showPin === null) {
            this.setShowFeedbackPinState()
          }
        }, 60 * 1000 * 2)
      }
    },
    onlineTypes(arr) {
      this.$nextTick(() => {
        this.setBootingState(!(arr && this.allDataFetched))
      })
    },
  },
  methods: {
    ...mapActions(appStore, [
      'setShownDialog',
      'setShowFeedbackPinState',
      'setBootingState',
    ]),
  },
  components: {
    ErrorPage,
    PageLoader,
    Dialog,
    PageGuide,
    FeedbackFlow,
    GalleryOverlay,
    FeedbackPin,
  },
  created() {
    const pageGuideSeen = localStorage.getItem(storageKeys.LS_PAGE_GUIDE_SEEN)

    if (!pageGuideSeen) {
      this.setShownDialog('pageGuide')
    }
  },
})
