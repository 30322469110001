
import PageGuideSlide from '@/components/pageGuide/PageGuideSlide.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Types',
  computed: {
    introHTML() {
      const intro = this.mixWB('GUIDE_SLIDE_TYPES_TEXT_1')

      return intro.replace(/!\*\*/g, '</strong>').replace(/\*\*/g, '<strong>')
    },
  },
  components: { PageGuideSlide },
})
