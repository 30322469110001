import { filterStore } from '@/store'
import { isEmpty, isEqual, isUndefined, omitBy } from 'lodash-es'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default function persistQuery(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const filterStoreRef = filterStore()

  const filterQuery = filterStoreRef.query
  const toQuery = omitBy(to.query, isUndefined)

  const toQueryIsEmpty = isEmpty(toQuery)
  const filterQueryIsEmpty = isEmpty(filterQuery)

  // Prevent filterQuery overwrite on initial load
  if (!toQueryIsEmpty && filterQueryIsEmpty) {
    next()

    return
  }

  // If different always use filterQuery to retain query
  if (!isEqual(filterQuery, toQuery)) {
    next({ ...to, query: filterQuery })

    return
  }

  // If the same
  next()
}
