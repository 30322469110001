import { IPTypeAmountItem } from '@/globals/javascript/models/onlineType/OnlineType'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { mixFormatNumber, mixWB } from '@/globals/javascript/_utils/mixins'
import { IAmountTypes } from '@/__types__/utils/amountTypes'

export function getTonsFromRegisteredAmount(this: ProType): IPTypeAmountItem {
  const amount = this.amounts[0]
  const density = this.getDensity()

  const amountItem: IPTypeAmountItem = {
    pTypeID: this.id,
    status: 'OK',
    amountType: (amount?.data?.type as IAmountTypes) || '',
    amountValue: NaN,
    tons: NaN,
    tonsAsText: '',
    calculation: '',
  }

  // Case: Skipped
  if (amount?.data?.type === 'skip') {
    amountItem.status = 'FAIL'
    amountItem.calculation = mixWB('X_QUANTITY_NOT_SPECIFIED', [
      this.getTitle(),
    ])
    return amountItem
  }

  if (!density) {
    amountItem.status = 'FAIL'
    amountItem.calculation = mixWB(
      'X_THE_DENSITY_OF_THE_MATERIAL_COULD_NOT_BE_FOUND',
      [this.getTitle()]
    )
    return amountItem
  }

  // Case: Meters
  if (amount?.data?.type === 'meters') {
    const height = amount.data.height / 1000
    const width = amount.data.width / 1000
    const tons = amount.data.meters * height * width * density
    const tonsAsText = mixFormatNumber(tons, 3).toString()

    // Update data
    amountItem.amountValue = amount.data.meters
    amountItem.tons = tons
    amountItem.tonsAsText = tonsAsText
    amountItem.calculation = mixWB('X_CLEANING_CALCULATION_STRING_2', [
      this.getTitle(),
      mixFormatNumber(amount.data.meters).toString(),
      mixFormatNumber(height).toString(),
      mixFormatNumber(width).toString(),
      mixFormatNumber(density).toString(),
      tonsAsText,
    ])

    return amountItem
  }

  // Case: M2
  if (amount?.data?.type === 'm2') {
    // Calculate tons
    const thickness = amount.data.thickness / 1000
    const tons = amount.data.m2 * thickness * density
    const tonsAsText = mixFormatNumber(tons, 3).toString()

    // Update data
    amountItem.amountValue = amount.data.m2
    amountItem.tons = tons
    amountItem.tonsAsText = tonsAsText
    amountItem.calculation = mixWB('X_CLEANING_CALCULATION_STRING_1', [
      this.getTitle(),
      mixFormatNumber(amount.data.m2).toString(),
      mixFormatNumber(thickness).toString(),
      mixFormatNumber(density).toString(),
      tonsAsText,
    ])

    return amountItem
  }

  // Case: M3
  if (amount?.data?.type === 'm3') {
    // Calculate tons
    const tons = amount.data.m3 * density
    const tonsAsText = mixFormatNumber(tons, 3).toString()

    // Update data
    amountItem.amountValue = amount.data.m3
    amountItem.tons = tons
    amountItem.tonsAsText = tonsAsText
    amountItem.calculation = mixWB('X_CLEANING_CALCULATION_STRING_3', [
      this.getTitle(),
      mixFormatNumber(amount.data.m3).toString(),
      mixFormatNumber(density).toString(),
      tonsAsText,
    ])

    return amountItem
  }

  // Case: Pcs
  if (amount?.data?.type === 'pcs') {
    // Calculate tons
    const tons = amount.data.pcs * amount.data.weight
    const tonsAsText = mixFormatNumber(tons, 3).toString()

    // Update data
    amountItem.amountValue = amount.data.pcs
    amountItem.tons = tons
    amountItem.tonsAsText = tonsAsText
    amountItem.calculation = mixWB('X_CLEANING_CALCULATION_STRING_4', [
      this.getTitle(),
      mixFormatNumber(amount.data.pcs).toString(),
      mixFormatNumber(amount.data.weight).toString(),
      tonsAsText,
    ])

    return amountItem
  }

  // Case: Fallback
  amountItem.status = 'FAIL'
  amountItem.calculation = mixWB('X_QUANTITY_NOT_SPECIFIED', [this.getTitle()])
  return amountItem
}
