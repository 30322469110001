import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f57dc6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "DialogWrap CustomScrollbar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Backdrop = _resolveComponent("Backdrop")!
  const _component_InlineSVG = _resolveComponent("InlineSVG")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isShowing)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_Backdrop, {
                onClick: _ctx.onBackdropClick,
                isTransparent: false
              }, null, 8, ["onClick"]),
              _createElementVNode("div", {
                ref: "Dialog",
                class: _normalizeClass(["Dialog", _ctx.dialogClasses])
              }, [
                _createElementVNode("button", {
                  class: "ButtonIcon",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseButtonClick && _ctx.onCloseButtonClick(...args)))
                }, [
                  _createVNode(_component_InlineSVG, {
                    src: require('@/assets/svg/close.svg')
                  }, null, 8, ["src"])
                ]),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}