/* eslint-disable no-use-before-define */
import OnlineType, {
  IOTypeAmountCleaningTaskItem,
  IPTypeAmountItem,
} from '@/globals/javascript/models/onlineType/OnlineType'

export function setAmount(this: OnlineType) {
  // Case: When group is isolated
  if (this.stackData.stackType === 'group-isolated') {
    setAmountFromBasicCalculation.call(this)
  }
  // Case: When solo type is isolated
  else if (this.stackData.stackType === 'solo-isolated') {
    setAmountFromBasicCalculation.call(this)
  }
  // Case: When m2 is set for the stack
  else if (this.stackData.stackM2) {
    setAmountFromStack.call(this)
  }
  // Case: Unable to set amount as group or solo-partial without m2 set
  else {
    // Unable to set - do nothing for now
  }
}

function setAmountFromBasicCalculation(this: OnlineType) {
  const pTypeAmounts = this.types.reduce(
    (prev, pType) => {
      const typeAmountData = pType.getTonsFromRegisteredAmount()

      prev.amountsPerPType.push(typeAmountData)

      if (typeAmountData.status === 'OK') {
        prev.totalTons += typeAmountData.tons
      } else {
        prev.hasMissingAmounts = true
      }

      return prev
    },
    {
      amountsPerPType: [] as IPTypeAmountItem[],
      amountsPerCleaningTask: [] as IOTypeAmountCleaningTaskItem[],
      totalTons: 0,
      hasMissingAmounts: false,
    }
  )

  this.amounts = pTypeAmounts
}

function setAmountFromStack(this: OnlineType) {
  const pTypeAmounts = this.types.reduce(
    (prev, pType) => {
      const typeAmountData = pType.getTonsFromM2(this.stackData.stackM2)

      prev.amountsPerPType.push(typeAmountData)

      if (typeAmountData.status === 'OK') {
        prev.totalTons += typeAmountData.tons
      } else {
        prev.hasMissingAmounts = true
      }
      return prev
    },
    {
      amountsPerPType: [] as IPTypeAmountItem[],
      amountsPerCleaningTask: [] as IOTypeAmountCleaningTaskItem[],
      totalTons: 0,
      hasMissingAmounts: false,
    }
  )

  this.amounts = pTypeAmounts
}
