import { IPTypeAmountItem } from '@/globals/javascript/models/onlineType/OnlineType'
import { ProCoatingType } from '@/globals/javascript/models/proCoatingType/ProCoatingType'
import { mixFormatNumber, mixWB } from '@/globals/javascript/_utils/mixins'

export function getTonsFromRegisteredAmount(
  this: ProCoatingType
): IPTypeAmountItem {
  const amountItem: IPTypeAmountItem = {
    pTypeID: this.id,
    status: 'OK',
    amountType: '',
    amountValue: NaN,
    tons: NaN,
    tonsAsText: '',
    calculation: '',
  }

  const coatingAmount = this.amounts[0]
  const coatingThickness = 0.00025

  // Case: Skipped
  if (coatingAmount?.data?.type === 'skip') {
    amountItem.status = 'FAIL'
    amountItem.calculation = mixWB('X_QUANTITY_NOT_SPECIFIED', [
      this.getTitle(),
    ])
    return amountItem
  }

  // Case: Coating amount is not m2
  if (coatingAmount?.data?.type !== 'm2') {
    amountItem.status = 'FAIL'
    amountItem.calculation = mixWB('X_QUANTITY_NOT_STATED_IN_M2', [
      this.getTitle(),
    ])
    return amountItem
  }

  // Case: Coating amount is in m2
  const coatingMaterial = this.getMaterial()
  const coatingDensity = coatingMaterial.metaData.density.value
  const coatingTons = coatingAmount.data.m2 * coatingThickness * coatingDensity
  const coatingTonsAsText = mixFormatNumber(coatingTons, 3).toString()

  // Update data
  amountItem.amountValue = coatingAmount.data.m2
  amountItem.tons = coatingTons
  amountItem.tonsAsText = coatingTonsAsText
  amountItem.calculation = mixWB('X_CLEANING_CALCULATION_STRING_1', [
    this.getTitle(),
    mixFormatNumber(coatingAmount.data.m2).toString(),
    mixFormatNumber(coatingThickness).toString(),
    mixFormatNumber(coatingDensity).toString(),
    coatingTonsAsText,
  ])

  return amountItem
}
