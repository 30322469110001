/* eslint-disable no-use-before-define */
import { CleaningTask } from '@/globals/javascript/models/CleaningTask'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { IInfoText } from '@/__types__/utils/infoText'
import { currentStore, resourcesStore } from '@/store'

export function getWorkdescriptionTexts(this: OnlineType): IInfoText[] {
  const { project } = currentStore()

  const infoTextsMap = {
    remediation: [] as IInfoText[],
    inGeneral: [] as IInfoText[],
    safety: [] as IInfoText[],
  }

  // 0. Return nothing when project is a screening
  if (project?.data?.isScreening) {
    return [] as IInfoText[]
  }

  // 1. Check for any predefined cases
  const {
    hasMissingTestResults,
    hasPostponedSample,
    isPotentiallyContaminated,
  } = this.options

  const couldBeContaminated =
    hasMissingTestResults || hasPostponedSample || isPotentiallyContaminated

  if (couldBeContaminated) {
    return [] as IInfoText[]
  }

  // 2. Add texts from special cases
  const specialCasesText = getSpecialCasesTexts(this)

  if (specialCasesText.length) {
    return specialCasesText
  }

  // 3. Cleaning tasks
  infoTextsMap.remediation = getRemediationTexts(this)

  // 5. Basic flow
  infoTextsMap.inGeneral = getInGeneralTexts(this)

  // 6. PCB handling flow

  // 7. Add safty text
  infoTextsMap.safety = getSafetyTexts(this)

  if (this.courseOfAction === 'prevention') {
    return infoTextsMap.remediation
  }

  return [
    ...infoTextsMap.remediation,
    ...infoTextsMap.inGeneral,
    ...infoTextsMap.safety,
  ]
}

/**
 *  HELPERS
 */
function getSpecialCasesTexts(oType: OnlineType) {
  const texts: IInfoText[] = []

  // 3.1 Insulation before 1998
  const hasInsulationBefore1998 = oType.specialCases.find(
    (x) => x.caseID === 'special-case-1'
  )

  if (hasInsulationBefore1998) {
    texts.push({
      text: mixWB('OLD_INSULATION_WARNING_TEXT'),
    })
    if (oType.types.length === 1) {
      texts.push({
        text: mixWB('OLD_INSULATION_DISPOSAL_TEXT'),
      })

      texts.push({
        text: mixWB('WORK_DESCRIPTION_HANDLING_DAGEROUS_MATERIALS_TEXT'),
      })

      return texts
    }
  }

  // 3.2 Metal with hazardous coating
  const isMetalWithCoating = oType.specialCases.find(
    (x) => x.caseID === 'special-case-9'
  )

  if (
    isMetalWithCoating &&
    ['WDC-3', 'WDC-4', 'WDC-5'].includes(oType.finalWDCID)
  ) {
    const contaminationLevelTitle =
      oType.finalWDCID === 'WDC-3'
        ? mixWB('HAZARDOUS')
        : oType.finalWDCID === 'WDC-4'
        ? mixWB('ASBESTOS_2')
        : mixWB('HAZARDOUS_ASBESTOS')

    texts.push({
      text: mixWB('MUST_BE_HANDLED_AS_X_WASTE_AND_DISPOSAL_TEXT', [
        contaminationLevelTitle,
      ]),
    })

    texts.push({
      text: mixWB('WORK_DESCRIPTION_HANDLING_DAGEROUS_MATERIALS_TEXT'),
    })

    return texts
  }

  return texts
}

function getRemediationTexts(oType: OnlineType) {
  const texts: IInfoText[] = []

  if (oType.cleaningTasks.length) {
    texts.push({
      type: 'header',
      text: mixWB('CLEANING'),
    })

    // 4.1 Sort all cleaning tasks into the 4 types of cleaning
    const {
      cleanFromInnerConnectionTasks,
      removeFromInnerConnectionTasks,
      cleanFromOuterConnectionTasks,
      removeFromOuterConnectionTasks,
    } = oType.cleaningTasks.reduce(
      (prev, cleaningTask) => {
        if (cleaningTask.toBeCleaned?.id === oType.id) {
          if (cleaningTask.side === 'inner') {
            prev.cleanFromInnerConnectionTasks.push(cleaningTask)
          } else {
            prev.cleanFromOuterConnectionTasks.push(cleaningTask)
          }
        }
        if (cleaningTask.toBeRemoved.id === oType.id) {
          if (cleaningTask.side === 'inner') {
            prev.removeFromOuterConnectionTasks.push(cleaningTask)
          } else {
            prev.removeFromInnerConnectionTasks.push(cleaningTask)
          }
        }
        return prev
      },
      {
        cleanFromInnerConnectionTasks: [],
        removeFromInnerConnectionTasks: [],
        cleanFromOuterConnectionTasks: [],
        removeFromOuterConnectionTasks: [],
      } as {
        cleanFromInnerConnectionTasks: CleaningTask[]
        removeFromInnerConnectionTasks: CleaningTask[]
        cleanFromOuterConnectionTasks: CleaningTask[]
        removeFromOuterConnectionTasks: CleaningTask[]
      }
    )

    // 4.2 Generate texts for each type of cleaning present
    // 4.2.1 Cleaning of the inner side of current oType
    if (cleanFromInnerConnectionTasks.length) {
      // Add texts for cleaning tasks
      const listOfTypeTitles = cleanFromInnerConnectionTasks
        .map((x) => x.toBeRemoved.titles.complete)
        .join(', ')
      texts.push({
        text: mixWB('CLEANING_X_OFF_INSIDE_THIS_TEXT', [listOfTypeTitles]),
      })

      // Add texts for notice
      insertNoticeFromCleaningTasks(cleanFromInnerConnectionTasks, true, texts)
    }

    // 4.2.2 Remove current type from inner types
    if (removeFromInnerConnectionTasks.length) {
      // 4.2.2.1 When cleaning task exists, but it is not set what it should be cleaned from
      if (
        removeFromInnerConnectionTasks.length === 1 &&
        !removeFromInnerConnectionTasks[0].toBeCleaned
      ) {
        texts.push({
          text: mixWB('NOT_FOUND_ON_A_MATERIAL_WARNING_TEXT'),
        })
      }
      // 4.2.2.2 Normal flow
      else {
        // Add texts for cleaning tasks
        const listOfTypeTitles = removeFromInnerConnectionTasks
          .map((x) => x.toBeCleaned?.titles.complete || '')
          .join(', ')
        texts.push({
          text: mixWB('CLEANING_THIS_OFF_OUTSIDE_X_TEXT', [listOfTypeTitles]),
        })

        // Add texts for notice
        insertNoticeFromCleaningTasks(
          removeFromInnerConnectionTasks,
          false,
          texts
        )
      }
    }
    // 4.2.3 Cleaning of the outer side of current oType
    if (cleanFromOuterConnectionTasks.length) {
      // Add texts for cleaning tasks
      const listOfTypeTitles = cleanFromOuterConnectionTasks
        .map((x) => x.toBeRemoved.titles.complete)
        .join(', ')
      texts.push({
        text: mixWB('CLEANING_X_OFF_OUTSIDE_THIS_TEXT', [listOfTypeTitles]),
      })

      // Add texts for notice
      insertNoticeFromCleaningTasks(cleanFromOuterConnectionTasks, true, texts)
    }

    // 4.2.4 Remove current type from outer types
    if (removeFromOuterConnectionTasks.length) {
      // Add texts for cleaning tasks
      const listOfTypeTitles = removeFromOuterConnectionTasks
        .map((x) => x.toBeCleaned?.titles.complete || '')
        .join(', ')
      texts.push({
        text: mixWB('CLEANING_THIS_OFF_INSIDE_X_TEXT', [listOfTypeTitles]),
      })

      // Add texts for notice
      insertNoticeFromCleaningTasks(
        removeFromOuterConnectionTasks,
        false,
        texts
      )
    }
  }

  return texts
}

function getInGeneralTexts(oType: OnlineType) {
  const texts: IInfoText[] = []
  const { wdgAsObject } = resourcesStore()
  const wdg = wdgAsObject[oType.wdgID]
  const key = oType.finalWDCID

  if (wdg && key) {
    // 5.1 Check to show general header
    if (texts.filter((x) => x.type === 'header').length) {
      texts.push({
        type: 'header',
        text: mixWB('IN_GENERAL'),
      })
    }

    // 5.2 Show general text
    texts.push({
      text: mixWB(wdg.cases[key]?.translation ?? ''),
    })
  }

  return texts
}

function getSafetyTexts(oType: OnlineType) {
  const texts: IInfoText[] = []

  if (oType.finalWDCID !== 'WDC-1') {
    texts.push({
      text: mixWB('WORK_DESCRIPTION_HANDLING_DAGEROUS_MATERIALS_TEXT'),
    })
  }

  return texts
}

function insertNoticeFromCleaningTasks(
  cleaningTasks: CleaningTask[],
  toBeCleaned: boolean,
  infoTexts: IInfoText[]
) {
  const notice = cleaningTasks.reduce(
    (prev, cleaningTask) => {
      const notice = cleaningTask.notice
      if (!notice) {
        return prev
      }

      const id = `${notice.id}+${notice.fractionTranslation}`

      if (!prev[id]) {
        prev[id] = {
          id: notice.id,
          typeTitle: notice.typeTitle,
          listOfTypeTitles: [],
          fractionTranslation: notice.fractionTranslation || '',
        }
      }

      if (toBeCleaned) {
        prev[id].listOfTypeTitles.push(
          cleaningTask.toBeRemoved?.titles.complete
        )
      } else {
        prev[id].listOfTypeTitles.push(
          cleaningTask.toBeCleaned?.titles.complete || ''
        )
      }
      return prev
    },
    {} as {
      [key: string]: {
        id: string
        typeTitle: string | undefined
        listOfTypeTitles: string[]
        fractionTranslation: string
      }
    }
  )

  // Add texts for notice
  Object.values(notice).forEach((value) => {
    if (value.id === 'IS_SHOWN_ALONE_NOTICE') {
      if (toBeCleaned) {
        infoTexts.push({
          text: mixWB('SEPARATION_AND_CLEANING_OF_X_OMIT_TEXT', [
            value.listOfTypeTitles.join(', '),
            mixWB(value.fractionTranslation),
          ]),
        })
      } else {
        infoTexts.push({
          text: mixWB('SEPARATION_AND_CLEANING_OF_X_FROM_Y_OMIT_TEXT', [
            value.typeTitle?.toLowerCase() || '',
            value.listOfTypeTitles.join(', '),
            mixWB(value.fractionTranslation),
          ]),
        })
      }
    }
  })
}
