import { defineStore } from 'pinia'

interface IGalleryState {
  currentIndex: number
  selectedCollection: string
  sources: {
    thumbnails: string[]
    fullSize: string[]
  }
  configuration: {
    loop: boolean
  }
}

export default defineStore('gallery', {
  state: () =>
    ({
      currentIndex: -1,
      selectedCollection: '',
      sources: {
        thumbnails: [],
        fullSize: [],
      },
      configuration: {
        loop: false,
      },
    } as IGalleryState),

  actions: {
    openCollection(collection: string, index: number) {
      const targets = [
        ...document.querySelectorAll(`[data-collection="${collection}"]`),
      ]

      // Sorting needed?

      targets.forEach((target) => {
        const [src] = target.getAttribute('src')?.split('?') ?? []

        this.$state.sources.thumbnails.push(`${src}?tx=t_square_40`)
        this.$state.sources.fullSize.push(src)
      })

      this.$state.selectedCollection = collection
      this.$state.currentIndex = index ?? 0
    },
    closeCollection() {
      this.$patch({
        currentIndex: -1,
        selectedCollection: '',
        sources: {
          thumbnails: [],
          fullSize: [],
        },
      })
    },
    next() {
      if (this.currentIndex < this.sources.fullSize.length - 1) {
        this.currentIndex++
      } else if (this.configuration.loop) {
        this.currentIndex = 0
      }
    },
    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      } else if (this.configuration.loop) {
        this.currentIndex = this.sources.fullSize.length - 1
      }
    },
    toImage(index: number) {
      this.currentIndex = index
    },
  },

  getters: {},
})
