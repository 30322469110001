/* eslint-disable @typescript-eslint/no-explicit-any */
import Apartment from '@/globals/javascript/models/proUnit/Apartment'
import Building from '@/globals/javascript/models/proUnit/Building'
import Floor from '@/globals/javascript/models/proUnit/Floor'
import { createHashID } from '@/globals/javascript/_utils/pane-util'
import { proProjectsStore } from '@/store'

interface IUnitConstructor {
  id: string
  projectID: string
  isSelected: boolean
  bbrID: string | null
  bbrData: any
  type: string
}

export default class Unit {
  id: string
  hashID: string
  projectID: string
  isSelected: boolean
  bbrID: string | null
  bbrData: any
  type: string

  constructor({
    id,
    isSelected,
    bbrID,
    bbrData,
    type,
    projectID,
  }: IUnitConstructor) {
    this.id = id
    this.hashID = createHashID('U', this.id)
    this.projectID = projectID
    this.isSelected = isSelected
    this.bbrID = bbrID
    this.bbrData = bbrData
    this.type = type
  }

  hasFloorPlan() {
    return this.getFloorPlan().length >= 1
  }

  getFloorPlan() {
    const addressImage = this.getAddressImage()

    if (!addressImage) {
      return []
    }

    return addressImage.units[this.id].floorPlans ?? []
  }

  getAddressImage() {
    const project = this.getProject()

    if (!project) {
      return null
    }

    return project.addressImages.find((x) => x.units?.[this.id])
  }

  getProject() {
    const { projects } = proProjectsStore()
    return projects.find((p) => p.id === this.projectID) ?? null
  }

  static getUnitById(unitID: string): Building | Floor | Apartment | null {
    const { allUnits } = proProjectsStore()

    // Check current project
    const unit = allUnits.find((unit) => unit.id === unitID)

    if (!unit) {
      return null
    }

    return unit
  }
}
