import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import { proProjectsStore } from '@/store'

export function setSampleType(this: ProSample | ProSplitSample) {
  if (this.assessNumber) {
    return 'assessed'
  }

  if (this.equivalentNumber) {
    return 'equivalent'
  }

  if (this.existingResultNumber) {
    return 'existing'
  }

  if (this.postponeNumber) {
    return 'postponed'
  }

  if (this.sampleNumber) {
    return 'standard'
  }

  return 'not-defined'
}

export function setSampleTypeID(this: ProSample | ProSplitSample) {
  const {
    assessNumber,
    equivalentNumber,
    existingResultNumber,
    postponeNumber,
    sampleNumber,
    sampleType,
  } = this

  switch (sampleType) {
    case 'standard':
      return `P${sampleNumber}`

    case 'assessed':
      return `V${assessNumber}`

    case 'equivalent':
      return `T${equivalentNumber}`

    case 'existing':
      return `E${existingResultNumber}`

    case 'postponed':
      return `U${postponeNumber}`

    case 'not-defined':
      return ''
  }
}

export function setWebIDs(this: ProSample | ProSplitSample) {
  const webID = {
    type: this.setSampleTypeID(),
    origin: '',
  }

  if (this.otherScreeningID) {
    const otherProject = proProjectsStore().rawProjects.find(
      (x) => x.id === this.otherScreeningID
    )

    // Set webId for reference to the external ACTUAL sample
    if (otherProject) {
      const { customCaseNumber, caseNumber } = otherProject.data
      const oCaseID = customCaseNumber || caseNumber

      webID.origin = `P${this.equivalentSampleNumber} (${oCaseID})`
    }
  }

  return webID
}

export function setMissingTestResultsStatus(this: ProSample | ProSplitSample) {
  // Following sampleTypes will never have LAB test results, thus, never missing them.
  if (['existing', 'assessed'].includes(this.sampleType)) {
    return
  }

  // Postponed samples are by default missing testresults
  if (this.sampleType === 'postponed') {
    this.options.hasMissingTestResult = true
    return
  }

  // Standard and equivalent samples
  if (['standard', 'equivalent'].includes(this.sampleType)) {
    const statusListItem = this.getProjectStandardSamplesStatusSampleItem()
    this.options.hasMissingTestResult = !statusListItem?.isCompleted
    return
  }

  // Fallback
  this.options.hasMissingTestResult = true
}

export function setProSampleWDCID(this: ProSample) {
  // Standard and equivalent samples
  if (['standard', 'equivalent'].includes(this.sampleType)) {
    if (this.options.hasMissingTestResult) {
      this.wdcID = ''
      return
    }

    // When standard samples status data is available
    const statusListItem = this.getProjectStandardSamplesStatusSampleItem()
    this.wdcID = statusListItem?.wdcID || ''
    return
  }

  // Assessment
  if (this.sampleType === 'assessed') {
    const statusListItem = this.getProjectAssessedSamplesStatusSampleItem()
    this.wdcID = statusListItem?.wdcID || ''
    return
  }

  // Existing result
  if (this.sampleType === 'existing') {
    this.wdcID = this.existingResultWDCID
  }
}
