import { IPTypeCO2Item } from '@/globals/javascript/models/onlineType/OnlineType'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { mixFormatNumber, mixWB } from '@/globals/javascript/_utils/mixins'

export function getPotentiallyCO2SavingAmount(this: ProType, m2: number) {
  const amount = this.amounts[0]

  const item: IPTypeCO2Item = {
    pTypeID: this.id,
    type: '',
    density: 0,
    amount: 0,
    tons: 0,
    texts: {
      calculation: mixWB('X_CO2_E_UNKNOWN', [this.getTitle()]),
      tons: '',
    },
  }

  // Case: No amount found
  if (!amount) {
    return item
  }

  const { type = undefined } = amount.data

  // Case: No amount type found
  if (!type) {
    return item
  }

  // Update item with type data
  item.type = type

  const material = this.getMaterial()

  // Case: No connected material from admin CMS
  if (!material) {
    return item
  }

  // Retrieve and set co2 density
  const { value: co2Density } = material.metaData.co2

  // If co2Density is not set on material
  if (!co2Density) {
    return item
  }

  item.density = parseFloat(co2Density.toFixed(3))

  // Case: Meters
  if (type === 'meters') {
    const height = amount.data.height / 1000
    const width = amount.data.width / 1000
    const co2InTons = amount.data.meters * height * width * co2Density
    const tonsAsText = mixFormatNumber(co2InTons, 3).toString()

    // Update data
    item.amount = amount.data.meters
    item.tons = co2InTons
    item.texts.tons = tonsAsText

    item.texts.calculation = mixWB('X_CO2_E_CALCULATION_METERS', [
      this.getTitle(),
      mixFormatNumber(amount.data.meters).toString(),
      mixFormatNumber(height).toString(),
      mixFormatNumber(width).toString(),
      mixFormatNumber(co2Density, 3).toString(),
      tonsAsText,
    ])

    return item
  }

  // Case: M2
  if (type === 'm2') {
    const m2Amount = m2 || amount.data.m2
    // Calculate tons
    const thickness = amount.data.thickness / 1000
    const co2InTons = m2Amount * thickness * co2Density
    const tonsAsText = mixFormatNumber(co2InTons, 3).toString()

    // Update data
    item.amount = m2Amount
    item.tons = co2InTons
    item.texts.tons = tonsAsText

    item.texts.calculation = mixWB('X_CO2_E_CALCULATION_M2', [
      this.getTitle(),
      mixFormatNumber(m2Amount).toString(),
      mixFormatNumber(thickness).toString(),
      mixFormatNumber(co2Density, 3).toString(),
      tonsAsText,
    ])

    return item
  }

  // Case: M3
  if (type === 'm3') {
    // Calculate tons
    const co2InTons = amount.data.m3 * co2Density
    const tonsAsText = mixFormatNumber(co2InTons, 3).toString()

    // Update data
    item.amount = amount.data.m3
    item.tons = co2InTons
    item.texts.tons = tonsAsText

    item.texts.calculation = mixWB('X_CO2_E_CALCULATION_M3', [
      this.getTitle(),
      mixFormatNumber(amount.data.m3).toString(),
      mixFormatNumber(co2Density, 3).toString(),
      tonsAsText,
    ])

    return item
  }

  // Case: Pcs
  if (type === 'pcs') {
    // Calculate tons
    const materialInTons = amount.data.pcs * amount.data.weight
    const materialDensity = this.getDensity()
    const materialVolume = materialInTons / materialDensity

    const co2InTons = item.density * materialVolume
    const tonsAsText = mixFormatNumber(co2InTons, 3).toString()

    // Update data
    item.amount = amount.data.pcs
    item.tons = co2InTons
    item.texts.tons = tonsAsText

    item.texts.calculation = mixWB('X_CO2_E_CALCULATION_PC', [
      this.getTitle(),
      mixFormatNumber(amount.data.pcs).toString(),
      mixFormatNumber(amount.data.weight).toString(),
      mixFormatNumber(materialDensity).toString(),
      mixFormatNumber(item.density).toString(),
      tonsAsText,
    ])

    return item
  }

  // Case: Skipped + Fallback
  item.texts.calculation = mixWB('X_QUANTITY_NOT_SPECIFIED', [this.getTitle()])
  return item
}
