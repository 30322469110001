import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09d083c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "FeedbackFlow" }
const _hoisted_2 = {
  key: 0,
  class: "FeedbackSpinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_FeedbackReceipt = _resolveComponent("FeedbackReceipt")!
  const _component_FeedbackForm = _resolveComponent("FeedbackForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.states.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Spinner, { size: "large" })
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.states.showReceipt)
            ? (_openBlock(), _createBlock(_component_FeedbackReceipt, {
                key: 0,
                success: _ctx.states.success,
                error: _ctx.states.error
              }, null, 8, ["success", "error"]))
            : (_openBlock(), _createBlock(_component_FeedbackForm, {
                key: 1,
                rating: _ctx.params.rating,
                "onUpdate:rating": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.rating) = $event)),
                description: _ctx.params.message,
                "onUpdate:description": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.message) = $event))
              }, null, 8, ["rating", "description"])),
          (!_ctx.states.showReceipt)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "FeedbackButton",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
              }, _toDisplayString(_ctx.mixWB('SEND')), 1))
            : _createCommentVNode("", true),
          (_ctx.states.showReceipt && _ctx.states.success)
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                class: "FeedbackButton",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setShownDialog()))
              }, _toDisplayString(_ctx.mixWB('CLOSE')), 1))
            : _createCommentVNode("", true),
          (_ctx.states.showReceipt && _ctx.states.error)
            ? (_openBlock(), _createElementBlock("button", {
                key: 4,
                class: "FeedbackButton",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onGoBack && _ctx.onGoBack(...args)))
              }, _toDisplayString(_ctx.mixWB('GO_BACK')), 1))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}