
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Spinner',
  props: {
    size: {
      type: String as PropType<'auto' | 'small' | 'large'>,
      default: 'small',
    },
  },
  computed: {
    sizeClass(): string {
      const firstLetter = this.size.charAt(0).toUpperCase()
      return firstLetter + this.size.substr(1)
    },
  },
})
