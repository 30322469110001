import '@/globals/scss/base.scss'

import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'

import InlineSvg from 'vue-inline-svg'
import { pinia } from '@/store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import append from '@/globals/javascript/plugins/append'
import { mixins } from '@/globals/javascript/_utils/mixins'
import PaneLink from '@/components/panes/PaneLink.vue'
import sentryPlugin, { ISentry } from '@/globals/javascript/plugins/sentry'
import gallery from '@/directives/gallery'
import { ENV } from '@/globals/javascript/_utils/env'

// Augmenting '@vue/runtime-core' MUST be done here - otherwise... BOOM
declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    // Plugins
    $routeAppend: (pathToAppend: string, path?: string) => string
    $sentry: ISentry

    // Mixins from pro
    mixWB: (key: string, params?: string[]) => string
    mixWBPreRender: (key: string, params?: string[]) => string
    mixFormatPrice: (number: number, postfix?: string) => string
    mixFormatNumber: (number: string | number, decimals?: number) => string
    mixGetDownloadURL: ({ path }: { path: string }) => Promise<string>
    mixGetFileUrl: ({
      path,
      filename,
      download,
    }: {
      path: string
      filename: string
      download: boolean
    }) => string
    mixGetDate: (
      date: { _seconds: number; _nanoseconds: number } | string | null,
      format?: string | null
    ) => string | number

    // Mixins native to online
    mixContaminationClass: (value: string | null | 0 | 1 | 2) => string
    mixEncodeString: (str: string) => string
  }
}

const app = createApp(App)

// Disable sentry by default on localhost
const disableSentry = ENV.isLocalhost

Sentry.init({
  app,
  dsn: disableSentry
    ? ''
    : 'https://8070c154c1ab4501a8fc0f3f4bca1b1b@o460398.ingest.sentry.io/6227689',
  environment: process.env.NODE_ENV,
  release: `frontend@${process.env.PACKAGE_VERSION}`,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['dev-online.milva.dk', 'online.milva.dk', /^\//],
    }),
  ],
  logErrors: true,
  tracesSampleRate: 0.1,
})

app.use(pinia).use(router).use(append).use(sentryPlugin)

app.mixin(mixins)
app.component('InlineSVG', InlineSvg)
app.component('PaneLink', PaneLink)
app.directive('gallery', gallery)

app.mount('#app')
