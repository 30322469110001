/* eslint-disable no-use-before-define */
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import {
  getOnlineTypesSumData,
  getOTypeCountText,
} from '@/globals/javascript/_utils/pane-util'
import { resourcesStore } from '@/store'
import { ICategoryGroup } from '@/__types__/categoryGroup'
import { IResourcesCategory } from '@/__types__/_pro/resources/resources-category'
import { orderBy } from 'lodash-es'

export const compileCategoryGroups = (
  onlineTypes: OnlineType[]
): {
  all: ICategoryGroup[]
  withOnlineTypes: ICategoryGroup[]
  withoutOnlineTypes: ICategoryGroup[]
  resale: ICategoryGroup[]
} => {
  const resourceStoreRef = resourcesStore()
  const categoriesAsArray: IResourcesCategory[] =
    resourceStoreRef.categoriesAsArray

  const { all, resale } = categoriesAsArray.reduce(
    (prev, c, index) => {
      const { connectedMaterialIDs, connectedTypeIDs } =
        resourceStoreRef.categoriesAsObject[c.id]
      const connectedIDs = [...connectedMaterialIDs, ...connectedTypeIDs]

      // Get online types collections
      const { directlyRelated, indirectlyRelated } =
        compileOnlineTypeCollections(c, onlineTypes, connectedIDs)

      const containsResaleOnlineTypes = !!(
        directlyRelated.resale.length || indirectlyRelated.resale.length
      )

      // Only add to resale if any types registred
      if (containsResaleOnlineTypes) {
        prev.resale.push(
          compileCategoryGroup(
            index,
            c,
            directlyRelated.resale,
            indirectlyRelated.resale
          )
        )
      }

      prev.all.push(
        compileCategoryGroup(
          index,
          c,
          directlyRelated.all,
          indirectlyRelated.all
        )
      )

      return prev
    },
    {
      all: [] as ICategoryGroup[],
      resale: [] as ICategoryGroup[],
    }
  )

  const { withOnlineTypes, withoutOnlineTypes } = all.reduce(
    (prev, category) => {
      category.hasOnlineTypes
        ? prev.withOnlineTypes.push(category)
        : prev.withoutOnlineTypes.push(category)

      return prev
    },
    {
      withoutOnlineTypes: [] as ICategoryGroup[],
      withOnlineTypes: [] as ICategoryGroup[],
    }
  )

  return {
    all,
    withOnlineTypes,
    withoutOnlineTypes,
    resale,
  }
}

/*
 * LOCAL HELPERS
 */

const iconMap = {
  'C-1': 'roof',
  'C-2': 'facade',
  'C-3': 'plinth',
  'C-4': 'window',
  'C-5': 'woodwork',
  'C-6': 'exterior-tiles',
  'C-7': 'interior-tiles',
  'C-8': 'walls',
  'C-9': 'floor',
  'C-10': 'ceiling',
  'C-11': 'woodwork',
  'C-12': 'installations',
  'C-13': 'insulation',
  'C-14': 'gutters',
  'C-15': 'soft-joints',
  'C-16': 'chimney',
  'C-17': 'sole-benches',
  'C-18': 'stairs',
  'C-19': 'balcony',
  'C-20': 'window',
}

const compileOnlineTypeCollections = (
  category: IResourcesCategory,
  onlineTypes: OnlineType[],
  connectedIDs: string[]
) => {
  return onlineTypes.reduce(
    (prev, oType) => {
      const { isRecycable } = oType.options
      const directlyRelated = oType.categoryID === category.id
      const indirectlyRelated = connectedToCategory(
        category,
        oType,
        connectedIDs
      )

      // Related by category ID
      if (directlyRelated) {
        prev.directlyRelated.all.push(oType)
      }
      // Related by category ID and is eligible for utilization
      else if (directlyRelated && isRecycable) {
        prev.directlyRelated.resale.push(oType)
      }
      // Related by material
      else if (indirectlyRelated) {
        prev.indirectlyRelated.all.push(oType)
      }
      // Related by material and is eligible for utilization
      else if (indirectlyRelated && isRecycable) {
        prev.indirectlyRelated.resale.push(oType)
      }

      return prev
    },
    {
      directlyRelated: {
        all: [] as OnlineType[],
        resale: [] as OnlineType[],
      },
      indirectlyRelated: {
        all: [] as OnlineType[],
        resale: [] as OnlineType[],
      },
    }
  )
}

const compileCategoryGroup = (
  index: number,
  category: IResourcesCategory,
  directlyRelated: OnlineType[],
  indirectlyRelated: OnlineType[]
): ICategoryGroup => {
  const iconID = iconMap[category.id as keyof typeof iconMap]
  const allRelated = [...directlyRelated, ...indirectlyRelated]

  const sumDataFromAll = getOnlineTypesSumData(allRelated)
  const sumDataFromDirect = getOnlineTypesSumData(directlyRelated)
  const sumDataFromIndirect = getOnlineTypesSumData(indirectlyRelated)

  const categoryGroup: ICategoryGroup = {
    hashID: category.id,
    title: `${index + 1}. ${mixWB(category.translation)}`,
    icon: iconID,
    translation: category.translation,
    hasOnlineTypes: !!allRelated.length,
    onlineTypes: {
      allRelated: orderOnlineTypes(allRelated),
      directlyRelated: orderOnlineTypes(directlyRelated),
      indirectlyRelated: orderOnlineTypes(indirectlyRelated),
    },
    texts: {
      count: {
        allRelated: getOTypeCountText(allRelated),
        directlyRelated: getOTypeCountText(directlyRelated),
        indirectlyRelated: getOTypeCountText(indirectlyRelated),
      },
      tons: {
        allRelated: sumDataFromAll.tons.asText,
        directlyRelated: sumDataFromDirect.tons.asText,
        indirectlyRelated: sumDataFromIndirect.tons.asText,
      },
      disposalCost: {
        allRelated: sumDataFromAll.sum.asText,
        directlyRelated: sumDataFromDirect.sum.asText,
        indirectlyRelated: sumDataFromIndirect.sum.asText,
      },
    },
  }

  return categoryGroup
}

const orderOnlineTypes = (onlineTypes: OnlineType[]) => {
  return orderBy(
    onlineTypes,
    ['index.primary', 'index.secondary', 'titles.category'],
    ['asc', 'asc', 'asc']
  )
}

const connectedToCategory = (
  category: IResourcesCategory,
  oType: OnlineType,
  connectedIDs: string[]
) => {
  const connectedToCategory = oType.types.some((proType) => {
    if (!(proType instanceof ProType)) {
      return
    }

    const resourceType = proType.getResourceType()

    if (!resourceType) {
      return
    }

    const proTypeCategory =
      resourcesStore().categoriesAsObject[proType.categoryID]
    const resourceTypeMaterialID = resourceType.materialIDs[0]

    let isConnected = false
    // Check if material is connected to category
    if (connectedIDs.includes(resourceTypeMaterialID)) {
      isConnected = true
    }

    // Check if type is connected to category
    if (connectedIDs.includes(resourceType.id)) {
      isConnected = true
    }

    // Check if from same area. Allow insulation to be gathered from all areas
    if (
      category.areaID !== 'AREA-3' &&
      category.areaID !== proTypeCategory.areaID &&
      category.id !== 'C-13' // Insulation
    ) {
      isConnected = false
    }

    return isConnected
  })

  return connectedToCategory
}
