import { ProCoatingType } from '@/globals/javascript/models/proCoatingType/ProCoatingType'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { IWDCID } from '@/__types__/utils/wdcID'
import { countBy } from 'lodash-es'

export const uniqueStrings = (x: string, i: number, arr: string[]) =>
  arr.indexOf(x) === i

export const formatEWCNumber = (value: string) => {
  const splittedValue = value.match(/.{1,2}/g) ?? []

  return splittedValue.join(' ')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringsAppearingOnce = (source: any, prop?: any) => {
  const countObject = countBy(source, prop)

  return Object.entries(countObject)
    .filter(([, value]) => value === 1)
    .flat(1)
    .filter((val) => typeof val === 'string')
}

export const deriveWDCID = (wdcIDs: IWDCID[]): IWDCID => {
  if (wdcIDs.includes('WDC-5')) {
    return 'WDC-5'
  }

  if (wdcIDs.includes('WDC-4') && wdcIDs.includes('WDC-3')) {
    return 'WDC-5'
  }

  if (wdcIDs.includes('WDC-4')) {
    return 'WDC-4'
  }

  if (wdcIDs.includes('WDC-3')) {
    return 'WDC-3'
  }

  if (wdcIDs.includes('WDC-2')) {
    return 'WDC-2'
  }

  return 'WDC-1'
}

export const getWDCContaminationScale = (wdcID: IWDCID) =>
  wdcID && Number(wdcID.replace('WDC-', ''))

export const isProType = (pType: ProType | ProCoatingType): pType is ProType =>
  pType instanceof ProType

export const isProCoatingType = (
  pType: ProType | ProCoatingType
): pType is ProCoatingType => pType instanceof ProCoatingType

export const customJoin = (array: string[], primary: string, end?: string) => {
  if (array.length === 0) {
    return ''
  }

  if (array.length === 1) {
    return array[0]
  }

  if (!end) {
    return array.join(primary)
  }

  const firstPart = array.slice(0, -1).join(primary)
  const lastItem = array.slice(-1)[0]

  return [firstPart, lastItem].join(end)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getElementDocumentPos = (elem: any) => {
  let offsetTop = 0
  let offsetLeft = 0
  let offsetBottom = 0
  let offsetRight = 0
  const savedElem = elem

  if (!elem) {
    return {
      top: offsetTop,
      left: offsetLeft,
      bottom: offsetBottom,
      right: offsetRight,
    }
  }

  do {
    if (!Number.isNaN(elem.offsetTop)) offsetTop += elem.offsetTop
    if (!Number.isNaN(elem.offsetLeft)) offsetLeft += elem.offsetLeft
    // eslint-disable-next-line no-cond-assign
  } while ((elem = elem.offsetParent) !== null)

  if (offsetTop) {
    const { height, width } = savedElem.getBoundingClientRect()
    offsetBottom = offsetTop + height
    offsetRight = offsetLeft + width
  }

  return {
    top: offsetTop,
    left: offsetLeft,
    bottom: offsetBottom,
    right: offsetRight,
  }
}
