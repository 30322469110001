import OnlineType, {
  ICombinedContamination,
} from '@/globals/javascript/models/onlineType/OnlineType'
import {
  deriveWDCID,
  getWDCContaminationScale,
  isProCoatingType,
  isProType,
} from '@/globals/javascript/_utils/util'
import { IWDCID } from '@/__types__/utils/wdcID'

/*
  Here we check if the current online-type is contaminated from either side.
*/

export function setFinalWCDID(this: OnlineType) {
  const contaminatedBy: ICombinedContamination[] = []

  const noAdjecentTypes =
    !this.innerOnlineTypes.length && !this.outerOnlineTypes.length

  this.finalWDCID = this.wdcID

  if (noAdjecentTypes) {
    return
  }

  const innerType = this.innerOnlineTypes[0]
  const outerType = this.outerOnlineTypes[0]

  if (innerType) {
    // Cases when contamination check is skipped
    // Case 1: Inner pro-type is cleanable
    const isInnerPTypeCleanable = this.innerWDGID === 'WDG-2'

    // Case 2: The outer pro-type of adjacent inner online-type has 'isShownAlone'
    const innerOTypeOuterPType = innerType.types.slice(-1)[0]
    const innerOTypeOuterPTypeRType =
      isProType(innerOTypeOuterPType) && innerOTypeOuterPType.getResourceType()

    const innerOTypeLastPTypeIsShownAlone =
      innerOTypeOuterPTypeRType &&
      innerOTypeOuterPTypeRType.options.isShownAlone

    // Only check for contamination when no cases are found
    if (!isInnerPTypeCleanable && !innerOTypeLastPTypeIsShownAlone) {
      const contamination = this.getContamination(innerType, 'inner')

      if (contamination) {
        contaminatedBy.push(contamination)
      }
    }
  }

  if (outerType) {
    // Cases when contamination check is skipped
    // Case 1: Outer pro-type is cleanable
    const isOuterPTypeCleanable = this.outerWDGID === 'WDG-2'

    // Case 2: The inner pro-type of adjacent outer online-type has 'isShownAlone'
    const outerOTypeInnerPTypeRType =
      isProType(outerType.types[0]) && outerType.types[0].getResourceType()

    const outerOTypeFirstPTypeIsShownAlone =
      outerOTypeInnerPTypeRType &&
      outerOTypeInnerPTypeRType.options.isShownAlone

    const outerTypeIsSoot = !!outerType.specialCases.find(
      (x) => x.caseID === 'special-case-11'
    )

    const oTypeContainsMultipleTypes = this.types.length > 1
    const oTypeOuterTypeIsCleanCoating =
      isProCoatingType(this.types.slice(-1)[0]) && this.outerWDCID === 'WDC-1'

    // Special-case: material + clean-coating || soot
    const isSootOnMaterialWithCleanCoating =
      outerTypeIsSoot &&
      oTypeContainsMultipleTypes &&
      oTypeOuterTypeIsCleanCoating

    // Only check for contamination when no cases are found
    if (
      !isOuterPTypeCleanable &&
      !outerOTypeFirstPTypeIsShownAlone &&
      !isSootOnMaterialWithCleanCoating
    ) {
      const contamination = this.getContamination(outerType, 'outer')

      if (contamination) {
        contaminatedBy.push(contamination)
      }
    }
  }

  // No contamination
  if (!contaminatedBy.length) {
    return
  }

  // Single contamination
  if (contaminatedBy.length === 1) {
    this.finalWDCID = contaminatedBy[0].wdcID
    this.contaminatedBy.push(contaminatedBy[0])

    return
  }

  // Double contamination
  // - If either is WDC-5
  const [innerContamination, outerContamination] = contaminatedBy
  const firstWDC5 = contaminatedBy.find((x) => x.wdcID === 'WDC-5')

  if (firstWDC5) {
    this.finalWDCID = 'WDC-5'
    this.contaminatedBy.push(firstWDC5)

    return
  }

  // - If combined are WDC-5
  const combinedWDCID = deriveWDCID([
    innerContamination.wdcID as IWDCID,
    outerContamination.wdcID as IWDCID,
  ])

  if (combinedWDCID === 'WDC-5') {
    this.finalWDCID = 'WDC-5'
    this.contaminatedBy.push(innerContamination)
    this.contaminatedBy.push(outerContamination)

    return
  }

  // - If they are the same
  if (innerContamination.wdcID === outerContamination.wdcID) {
    this.finalWDCID = innerContamination.wdcID
    this.contaminatedBy.push(innerContamination)

    return
  }

  // Find the worst one
  if (
    getWDCContaminationScale(innerContamination.wdcID as IWDCID) >
    getWDCContaminationScale(outerContamination.wdcID as IWDCID)
  ) {
    this.finalWDCID = innerContamination.wdcID
    this.contaminatedBy.push(innerContamination)

    return
  }

  // If outer was the worst one
  this.finalWDCID = outerContamination.wdcID
  this.contaminatedBy.push(outerContamination)
}
