/* eslint-disable no-use-before-define */
import { CleaningTask, INotice } from '@/globals/javascript/models/CleaningTask'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import {
  getWDCContaminationScale,
  isProCoatingType,
  isProType,
} from '@/globals/javascript/_utils/util'
import { IWDCID } from '@/__types__/utils/wdcID'

export const deriveCleaningTasks = (oType: OnlineType): void => {
  setInnerCleaningTasks(oType)
  setOuterCleaningTasks(oType)
}

function setInnerCleaningTasks(oType: OnlineType) {
  // 1. Pre check
  // 1.1 Has any inner online types
  if (!oType.innerOnlineTypes.length) {
    // 1.1.1 When inner type is adhesive that contaminates inwards
    innerAdhesiveCleaningTask(oType)
    return
  }

  // 2. When first pro-type has 'isShownAlone'
  const stopChecking = innerIsShownAloneCleaningTasks(oType)

  if (stopChecking) {
    return
  }

  // 3. Normal flow - Is inner pro-type cleanable
  innerPTypeIsCleanable(oType)
}

function setOuterCleaningTasks(oType: OnlineType) {
  // 1. Pre check
  // 1.1 Has any outer online types
  if (!oType.outerOnlineTypes.length) {
    return
  }

  const [outerOnlineType] = oType.outerOnlineTypes
  const outerOnlineTypeIsSoot = !!outerOnlineType.specialCases.find(
    (x) => x.caseID === 'special-case-11'
  )

  if (outerOnlineTypeIsSoot) {
    sootCleaningTask(oType)
    return
  }

  // 2. When last pro-type has 'isShownAlone'
  const stopChecking = outerIsShownAloneCleaningTasks(oType)

  if (stopChecking) {
    return
  }

  // 3. Normal flow - Is outer pro-type cleanable
  outerPTypeIsCleanable(oType)
}

function sootCleaningTask(oType: OnlineType) {
  const [outerOnlineType] = oType.outerOnlineTypes.slice(-1)

  if (!outerOnlineType) {
    return
  }

  createCleaningTask({
    toBeCleaned: oType,
    toBeRemoved: outerOnlineType,
    side: 'outer',
  })
}

function innerAdhesiveCleaningTask(oType: OnlineType) {
  if (
    oType.firstTypeID.includes('adhesive-inner-') &&
    oType.innerWDCID !== 'WDC-1'
  ) {
    createCleaningTask({
      toBeRemoved: oType,
      side: 'outer',
    })
  }
}

function innerIsShownAloneCleaningTasks(oType: OnlineType) {
  const firstPType = oType.types[0]
  const firstPTypeRType = isProType(firstPType) && firstPType.getResourceType()
  const isFirstPTypeRTypeIsShownAlone =
    firstPTypeRType && firstPTypeRType.options.isShownAlone
  const isFirstPTypeContaminatedSoftJoints =
    isFirstPTypeRTypeIsShownAlone && oType.finalWDCID !== 'WDC-1'

  if (!isFirstPTypeContaminatedSoftJoints) {
    return !!isFirstPTypeRTypeIsShownAlone
  }

  oType.innerOnlineTypes.forEach((innerOnlineType) => {
    // 2.1 Check route of infection
    if (!['left', 'both'].includes(oType.innerEffectDirection)) {
      return
    }

    // 2.2 Check that inner online type is not WDG-2
    if (innerOnlineType.outerWDGID === 'WDG-2') {
      return
    }

    // 2.3 Create notice
    const notice = createIsShownAloneNotice(
      oType,
      innerOnlineType,
      firstPType.getTitle()
    )

    // 2.4 Create cleaning task
    createCleaningTask({
      toBeCleaned: innerOnlineType,
      toBeRemoved: oType,
      side: 'outer',
      notice,
    })
  })

  return !!isFirstPTypeRTypeIsShownAlone
}

function innerPTypeIsCleanable(oType: OnlineType) {
  // 1.3 Is first pType WDG-2
  const firstPType = oType.types[0]
  const isFirstPTypeWDG2 =
    isProType(firstPType) && firstPType.getWDGID() === 'WDG-2'

  if (!isFirstPTypeWDG2) {
    return
  }

  // 2. Check each inner online type
  oType.innerOnlineTypes.forEach((innerOnlineType) => {
    // 2.1 Check route of infection
    if (!['right', 'both'].includes(innerOnlineType.outerEffectDirection)) {
      return
    }

    // 2.2 Compare contamination
    if (willNotContaminate(oType, innerOnlineType, 'inner')) {
      return
    }

    // 2.3 Create cleaning task
    createCleaningTask({
      toBeCleaned: oType,
      toBeRemoved: innerOnlineType,
      side: 'inner',
    })
  })
}

function outerIsShownAloneCleaningTasks(oType: OnlineType) {
  const lastPType = oType.types.slice(-1)[0]
  const lastPTypeRType = isProType(lastPType) && lastPType.getResourceType()
  const isLastPTypeRTypeIsShownAlone =
    lastPTypeRType && lastPTypeRType.options.isShownAlone
  const isLastPTypeContaminatedSoftJoints =
    isLastPTypeRTypeIsShownAlone && oType.finalWDCID !== 'WDC-1'

  if (!isLastPTypeContaminatedSoftJoints) {
    return !!isLastPTypeRTypeIsShownAlone
  }

  oType.outerOnlineTypes.forEach((outerOnlineType) => {
    // 2.1 Check route of infection
    if (!['right', 'both'].includes(oType.outerEffectDirection)) {
      return
    }

    // 2.2 Check that outer online type is not WDG-2
    if (outerOnlineType.innerWDGID === 'WDG-2') {
      return
    }

    // 2.3 Create notice
    const notice = createIsShownAloneNotice(
      oType,
      outerOnlineType,
      lastPType.getTitle()
    )

    // 2.4 Create cleaning task
    createCleaningTask({
      toBeCleaned: outerOnlineType,
      toBeRemoved: oType,
      side: 'inner',
      notice,
    })
  })

  return !!isLastPTypeRTypeIsShownAlone
}

function outerPTypeIsCleanable(oType: OnlineType) {
  // 1. Check if outer side is WDG-2 or is solo coating type
  const isSoloCoating =
    oType.types.length === 1 && isProCoatingType(oType.types[0])

  if (oType.outerWDGID !== 'WDG-2' && !isSoloCoating) {
    return
  }

  // 2. Check each outer online type
  oType.outerOnlineTypes.forEach((outerOnlineType) => {
    // 2.1 Check for special cases
    // 2.1.1 Wallpaper with contamination
    const firstOuterOnlineTypeProType = outerOnlineType.types[0]
    const firstOuterOnlineTypeProTypeRTypeID =
      isProType(firstOuterOnlineTypeProType) &&
      firstOuterOnlineTypeProType.getResourceType()?.id

    const isContaminatedWallPaper =
      firstOuterOnlineTypeProTypeRTypeID === 'T-64' &&
      outerOnlineType.finalWDCID !== 'WDC-1'

    if (isContaminatedWallPaper) {
      createCleaningTask({
        toBeCleaned: oType,
        toBeRemoved: outerOnlineType,
        side: 'outer',
      })
      return
    }

    // 2.2 Check if contamination is possible
    const hasRouteOfInfection = ['left', 'both'].includes(
      outerOnlineType.innerEffectDirection
    )
    const isOuterOnlineTypeNeverAlone = outerOnlineType.getIsNeverAloneStatus()

    if (!hasRouteOfInfection && !isOuterOnlineTypeNeverAlone) {
      return
    }

    // 2.3 Compare contamination
    if (willNotContaminate(oType, outerOnlineType, 'outer')) {
      return
    }

    // 2.4 Create cleaning task
    createCleaningTask({
      toBeCleaned: oType,
      toBeRemoved: outerOnlineType,
      side: 'outer',
    })
  })
}

function createIsShownAloneNotice(
  oType: OnlineType,
  adjacentOType: OnlineType,
  typeTitle: string
): undefined | INotice {
  // Get fraction of adjacent type
  // - Pre set the fraction and EWC code of the adjacent type
  adjacentOType.setFractionAndEWCCode()
  const adjacentOTypeFraction = adjacentOType.fraction

  if (!adjacentOTypeFraction) {
    return
  }

  let createNotice = false
  if (oType.finalWDCID === 'WDC-2') {
    createNotice = ['F-5', 'F-9', 'F-7', 'F-8'].includes(
      adjacentOTypeFraction.id
    )
  }
  if (oType.finalWDCID === 'WDC-3') {
    createNotice = ['F-9', 'F-8'].includes(adjacentOTypeFraction.id)
  }
  if (['WDC-4', 'WDC-5'].includes(oType.finalWDCID)) {
    createNotice = ['F-12'].includes(adjacentOTypeFraction.id)
  }

  if (!createNotice) {
    return
  }

  return {
    id: 'IS_SHOWN_ALONE_NOTICE',
    typeTitle,
    fractionTranslation: adjacentOTypeFraction.translation,
  }
}

function willNotContaminate(
  current: OnlineType,
  adjecent: OnlineType,
  side: 'inner' | 'outer'
) {
  const wdcTarget = side === 'inner' ? adjecent.outerWDCID : adjecent.innerWDCID
  const adjecentContaminationScaleValue = getWDCContaminationScale(
    wdcTarget as IWDCID
  )
  const currentContaminationScaleValue = getWDCContaminationScale(
    current.finalWDCID as IWDCID
  )

  return adjecentContaminationScaleValue <= currentContaminationScaleValue
}

function createCleaningTask({
  toBeCleaned,
  toBeRemoved,
  side,
  notice,
}: {
  toBeCleaned?: OnlineType
  toBeRemoved: OnlineType
  side: 'inner' | 'outer'
  notice?: INotice
}) {
  let m2 = NaN
  if (toBeCleaned) {
    m2 = toBeCleaned.stackData.stackM2 || NaN
  } else {
    m2 = toBeRemoved.stackData.stackM2 || NaN
  }

  // 1. Create cleaning task
  const cleaningTask = new CleaningTask({
    toBeCleaned,
    toBeRemoved,
    side,
    m2,
    notice,
  })

  // 2. Add task to both parties
  if (toBeCleaned) {
    toBeCleaned.cleaningTasks.push(cleaningTask)
  }

  toBeRemoved.cleaningTasks.push(cleaningTask)
}
