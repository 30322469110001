/* eslint-disable no-use-before-define */
import versionKeys from '@/globals/javascript/constants/versionKeys'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { IInfoText } from '@/__types__/utils/infoText'
import { currentStore } from '@/store'
import { compare } from 'compare-versions'

export function getTestResultsTexts(this: OnlineType) {
  const infoTexts: IInfoText[] = []

  // Add text for internal representative samples
  addRepresentativeInternalSampleText.call(this, infoTexts)

  // Add text for external representative samples
  addRepresentativeExternalSampleText.call(this, infoTexts)

  return infoTexts
}

function addRepresentativeInternalSampleText(
  this: OnlineType,
  infoTexts: IInfoText[]
) {
  const { projectProVersion } = currentStore()

  // VERSION CHECK: Same or newer than 2.8.4
  if (compare(projectProVersion, versionKeys.HIDE_EQUIVALENT_NUMBERS, '>=')) {
    const { samples } = currentStore()

    const internalEquivalentSamples = this.samples.filter(
      (x) => x.equivalentSampleNumber && !x.otherScreeningID
    )

    if (!internalEquivalentSamples?.length) {
      return
    }

    const originalSampleNumbers = internalEquivalentSamples.map(
      (equivalentSample) => {
        const originalSample = samples.standard.find(
          (x) => x.id === equivalentSample.equivalentSampleID
        )

        return originalSample?.sampleNumber || ''
      }
    )

    const pNumbers = originalSampleNumbers.sort().reduce((prev, number) => {
      if (!number) {
        return prev
      }

      prev.push(`P${number}`)
      return prev
    }, [] as string[])

    if (pNumbers.length === 1) {
      infoTexts.push({
        text: mixWB('X_IS_A_REPRESENTATIVE_SAMPLE_IN_BUILDING_PART', [
          pNumbers.join(', '),
        ]),
      })
      return
    }

    infoTexts.push({
      text: mixWB('X_ARE_REPRESENTATIVE_SAMPLES_IN_BUILDING_PART', [
        pNumbers.join(', '),
      ]),
    })
  }

  // VERSION CHECK: Same or older than 2.8.4
  else {
    return
  }
}

function addRepresentativeExternalSampleText(
  this: OnlineType,
  infoTexts: IInfoText[]
) {
  const externalEquivalentSamples = this.samples.filter(
    (x) => x.equivalentSampleNumber && x.otherScreeningID
  )

  if (!externalEquivalentSamples?.length) {
    return
  }

  const equivalentSampleNumbers = externalEquivalentSamples.map(
    (x) => x.equivalentSampleNumber
  )

  const tNumbers = equivalentSampleNumbers.sort().map((x) => `T${x}`)

  if (tNumbers.length === 1) {
    infoTexts.push({
      text: mixWB(
        'X_IS_A_REPRESENTATIVE_SAMPLE_FROM_OTHER_PROJECT_IN_BUILDING_PART',
        [tNumbers.join(', ')]
      ),
    })
    return
  }

  infoTexts.push({
    text: mixWB(
      'X_ARE_REPRESENTATIVE_SAMPLES_FROM_OTHER_PROJECT_IN_BUILDING_PART',
      [tNumbers.join(', ')]
    ),
  })
}
