import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PageGuide = _resolveComponent("PageGuide")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_FeedbackFlow = _resolveComponent("FeedbackFlow")!
  const _component_GalleryOverlay = _resolveComponent("GalleryOverlay")!
  const _component_FeedbackPin = _resolveComponent("FeedbackPin")!
  const _component_PageLoader = _resolveComponent("PageLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorState)
      ? (_openBlock(), _createBlock(_component_ErrorPage, {
          key: 0,
          errorState: _ctx.errorState
        }, null, 8, ["errorState"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_router_view, {
            key: _ctx.$route.params.projectID
          }),
          (!_ctx.booting)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_Dialog, {
                  name: "pageGuide",
                  hasEasyClose: false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PageGuide)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Dialog, {
                  name: "feedback",
                  hasEasyClose: false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FeedbackFlow)
                  ]),
                  _: 1
                }),
                _createVNode(_component_GalleryOverlay),
                _createVNode(_component_FeedbackPin, {
                  class: _normalizeClass({ IsHidden: !_ctx.feedback.showPin })
                }, null, 8, ["class"])
              ], 64))
            : _createCommentVNode("", true)
        ], 64)),
    (_ctx.booting)
      ? (_openBlock(), _createBlock(_component_PageLoader, {
          key: 2,
          texts: _ctx.loaderTexts
        }, null, 8, ["texts"]))
      : _createCommentVNode("", true)
  ], 64))
}