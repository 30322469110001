import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import {
  mixContaminationClass,
  mixWB,
} from '@/globals/javascript/_utils/mixins'
import { currentStore, proProjectsStore, resourcesStore } from '@/store'
import wdcJSON from '@/globals/database/WorkDescriptionCases.json'
import { ProCoatingType } from '@/globals/javascript/models/proCoatingType/ProCoatingType'
import { isProCoatingType } from '@/globals/javascript/_utils/util'

export function getOnlineType(this: ProSample | ProSplitSample) {
  const { onlineTypes } = proProjectsStore()

  return onlineTypes.find((x) => x.samples.find((s) => s.id === this.id))
}

export function getProject(this: ProSample | ProSplitSample, projectID = '') {
  const { projects } = proProjectsStore()

  if (projectID) {
    return projects.find((x) => x.id === projectID)
  }

  return projects.find((x) => x.id === this.projectID)
}

export function getReferenceSample(
  this: ProSample | ProSplitSample
): ProSample | ProSplitSample {
  if (this.options.isInternalEquivalent) {
    const { samples } = currentStore()

    return samples.standard.filter((x) => x.id === this.equivalentSampleID)[0]
  }

  return this
}

export function getSampledType(this: ProSample | ProSplitSample) {
  const { kindOfSample } = this

  return kindOfSample === 'material'
    ? this.getProType()
    : this.getProCoatingType()
}

export function getOverallResultClass(this: ProSample | ProSplitSample) {
  // Ålholm Slots historiske støv prøver
  if (this.options.isAalholmSlotDustSample) {
    return mixContaminationClass('')
  }

  switch (this.sampleType) {
    case 'standard':
    case 'equivalent':
    case 'assessed':
    case 'existing':
      return mixContaminationClass(this.wdcID)

    case 'postponed':
      return mixContaminationClass('')
  }
}

export function getResultLabel(this: ProSample | ProSplitSample) {
  // Ålholm Slots historiske støv prøver
  if (this.options.isAalholmSlotDustSample) {
    return mixWB('SEE_COMMENTS')
  }

  switch (this.sampleType) {
    case 'standard':
      return this.wdcID
        ? mixWB(wdcJSON[this.wdcID].translation)
        : mixWB('STATUS_AWAITING_TEST_RESULTS')

    case 'equivalent':
    case 'assessed':
    case 'existing':
      return mixWB(
        wdcJSON[this.wdcID ?? '']?.translation ?? 'STATUS_AWAITING_TEST_RESULTS'
      )

    case 'postponed':
      return mixWB('SAMPLE_POSTPONED')
  }
}

export function getResultNotes(this: ProSample | ProSplitSample) {
  if (this.sampleType === 'existing') {
    return {
      explanation: this.existingResultExplanation,
    }
  }

  return null
}

export function getCombinedTypeTitle(this: ProSample | ProSplitSample) {
  const { kindOfSample } = this
  const proType = this.getProType(true)

  if (!proType) {
    return ''
  }

  if (kindOfSample === 'material') {
    let title = proType.getTitle()

    if (proType.hasTags(['TAG-2'])) {
      title += ` ${mixWB('WITH_JOINTS_AND_ADHESIVES')}`
    }

    return title
  }

  const proCoatingType = this.getProCoatingType()

  if (!proCoatingType) {
    return proType.getTitle()
  }

  return `${proType?.getTitle()} ${mixWB(
    'WITH_SHORT'
  )} ${proCoatingType?.getTitle()}`
}

export function getFullTitle(
  this: ProSample | ProSplitSample,
  hideSampleTypeID = false
) {
  const sampledType = this.getSampledType()

  if (sampledType) {
    const category = sampledType.getCategory().translation ?? ''
    const title = `${mixWB(category)} - ${this.getCombinedTypeTitle()}`

    if (hideSampleTypeID) {
      return title
    }

    return `${this.sampleTypeID} - ${title}`
  }

  const title = this.getCombinedTypeTitle()

  if (hideSampleTypeID) {
    return title
  }

  return `${this.sampleTypeID} - ${title}`
}

export function getStatus(this: ProSample | ProSplitSample) {
  return {
    className: this.getOverallResultClass(),
    text: this.getResultLabel(),
  }
}

// Compile list of tests that the postponed sample is to be tested for
export function getTestList(this: ProSample | ProSplitSample) {
  const sampledType = this.getSampledType()

  if (!sampledType) {
    return []
  }

  const { sampleTypesAsObject } = resourcesStore()
  const allSampleIDs = sampledType.getAllSampleIDs()

  return allSampleIDs.map((id) => {
    const sampleType = sampleTypesAsObject[id]

    return {
      id: sampleType.id,
      text: mixWB(sampleType.translation),
    }
  })
}

export function getProCoatingType(
  this: ProSample | ProSplitSample
): ProCoatingType | null {
  const { onlineTypes } = proProjectsStore()

  let pType: null | ProCoatingType = null

  onlineTypes.forEach((oType) => {
    if (pType) {
      return
    }

    pType =
      (oType.types.find(
        (x) => isProCoatingType(x) && x.originID === this.typeID
      ) as ProCoatingType) ?? null
  })

  return pType
}
