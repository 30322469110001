import { ProCoatingType } from '@/globals/javascript/models/proCoatingType/ProCoatingType'
import { resourcesStore } from '@/store'
import colorsAsObject from '@/globals/database/Colors.json'
import { union } from 'lodash-es'
import { mixWB } from '@/globals/javascript/_utils/mixins'

export function getAllSampleIDs(this: ProCoatingType) {
  const pcb = this.options.hasPCBFromScreening ? ['ST-4'] : []

  const allSampleIDs = union(
    this.samples.sampleIDs,
    pcb,
    this.samples.manualSampleIDs
  )

  // If ST-8 and ST-2 is both present - remove ST-2
  if (allSampleIDs.includes('ST-8') && allSampleIDs.includes('ST-2')) {
    const st2Index = allSampleIDs.indexOf('ST-2')

    allSampleIDs.splice(st2Index, 1)
  }

  return allSampleIDs
}

export function getTitle(this: ProCoatingType, format = '%n (%c)') {
  const { coatingTypesAsObject } = resourcesStore()
  const coating =
    coatingTypesAsObject[this.coating.coatingTypeIDs[0]]?.translation

  const colors = this.coating.colorIDs.map((x) =>
    mixWB(colorsAsObject[x]?.translation)
  )

  if (colors.length) {
    return format.replace('%n', mixWB(coating)).replace('%c', colors.join(', '))
  }

  return format.replace('%n', mixWB(coating)).replace(' (%c)', '')
}

export function getCategory(this: ProCoatingType) {
  const { categoriesAsObject } = resourcesStore()
  return categoriesAsObject[this.categoryID]
}

export function getMaterial(this: ProCoatingType) {
  const { materialsAsObject } = resourcesStore()
  return materialsAsObject[this.materialID]
}

export function getFraction(this: ProCoatingType, wdcID: string) {
  const { fractionsAsObject } = resourcesStore()
  const material = this.getMaterial()

  if (!material) {
    return null
  }

  const fractionReference = Object.values(material.fractions.fractions).find(
    (fraction) => fraction.wdcID === wdcID
  )

  if (!fractionReference) {
    return null
  }

  return fractionsAsObject[fractionReference.fractionID]
}

export function getEWCCode(this: ProCoatingType, wdcID: string) {
  const { ewcCodesAsObject } = resourcesStore()
  const material = this.getMaterial()

  if (!material) {
    return null
  }

  const fractionReference = Object.values(material.fractions.fractions).find(
    (fraction) => fraction.wdcID === wdcID
  )

  if (!fractionReference) {
    return null
  }

  return ewcCodesAsObject[fractionReference.ewcID]
}

export function getWDGID(this: ProCoatingType) {
  return this.wdgID
}

export function getDensity(this: ProCoatingType) {
  const coatingMaterial = this.getMaterial()
  return coatingMaterial.metaData.density.value
}

export function getCanBurnStatus(this: ProCoatingType) {
  const hazardousFraction = this.getFraction('WDC-3')
  return hazardousFraction && hazardousFraction.id === 'F-9'
}
