import { ProType } from '@/globals/javascript/models/proType/ProType'
import { proProjectsStore } from '@/store'
import { union } from 'lodash-es'

export function setMissingTestResultsStatus(this: ProType) {
  const sampleIDs = [
    ...this.samples.sampleIDs,
    ...this.assessments.sampleIDs,
    ...this.samples.manualSampleIDs,
  ]

  const isSampleRequired = !!sampleIDs.length

  if (!isSampleRequired) {
    return
  }

  this.options.isSampleRequired = true

  // Check for sample
  const { allSamples, allSplitSamples } = proProjectsStore()

  const combinedSamples = [...allSamples, ...allSplitSamples]

  const sample = combinedSamples.find(
    (x) => x.typeID === this.id && x.kindOfSample === 'material'
  )

  if (!sample) {
    this.options.hasMissingTestResult = true
    return
  }

  this.options.hasMissingTestResult = sample.options.hasMissingTestResult
}

export function setPCBFromScreeningStatus(this: ProType) {
  const sampleIDs = [...this.samples.sampleIDs, ...this.samples.manualSampleIDs]

  // Check for any samples
  if (!sampleIDs.length) {
    return
  }

  // Check if PCB is already in the list
  if (sampleIDs.includes('ST-4')) {
    return
  }

  // Check category - only inside + installations + soft joints
  const category = this.getCategory()
  if (category.areaID !== 'AREA-2') {
    if (!['C-12', 'C-15'].includes(category.id)) {
      return
    }
  }

  // Check material
  const material = this.getMaterial()
  if (!material || material?.options?.excludeFromPCBScreening) {
    return
  }

  // Get building IDs
  const { projects } = proProjectsStore()
  const project = projects.find((x) => x.id === this.projectID)

  if (!project) {
    return
  }

  const { units, pcbScreenings } = project
  const typeUnits = units.filter((x) => this.unitIDs.includes(x.id))
  const buildingIDs = typeUnits.reduce((prev, unit) => {
    const buildingID = unit.getBuildingID()

    if (buildingID) {
      prev = union(prev, [buildingID])
    }

    return prev
  }, [] as string[])

  // Check if type units requires PCB testing
  let needsPCBTesting = false
  buildingIDs.forEach((buildingID: string) => {
    if (needsPCBTesting) {
      return
    }

    const pcbScreening = pcbScreenings.find((x) => x.id === buildingID)

    if (pcbScreening && pcbScreening.needsTesting) {
      needsPCBTesting = true
    }
  })

  this.options.hasPCBFromScreening = needsPCBTesting
}

export function setIsNeverAloneStatus(this: ProType) {
  const rType = this.getResourceType()
  this.options.isNeverAlone = !!rType?.options?.isNeverAlone
}
