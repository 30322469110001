import { App } from 'vue'

export default {
  install: (app: App) => {
    const append = (pathToAppend: string, path = ''): string => {
      const base = path || app.config.globalProperties.$route.path
      return base + (base.endsWith('/') ? '' : '/') + pathToAppend
    }

    app.config.globalProperties.$routeAppend = append
  },
}
