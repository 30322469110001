import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import { IProRawProjectStandardSamplesStatus } from '@/__types__/_pro/raw-project/pro-raw-project-standardSamplesStatus'

export function getProjectStandardSamplesStatus(
  this: ProSample | ProSplitSample
): IProRawProjectStandardSamplesStatus | null {
  /**
   * For external equivanelt samples this will return the data from
   * the other project.
   * Otherwise it will return the data from this project.
   */
  const project = this.getProject(this.otherScreeningID || '')
  return project?.standardSamplesStatus || null
}

export function getProjectStandardSamplesStatusSampleItem(
  this: ProSample | ProSplitSample
) {
  const standardSamplesStatus = this.getProjectStandardSamplesStatus()

  if (!standardSamplesStatus) {
    return null
  }

  // For equivalent samples, return the standard sample data
  if (this.sampleType === 'equivalent') {
    return (
      standardSamplesStatus.list.find(
        (x) => x.sampleNumber === this.equivalentSampleNumber
      ) || null
    )
  }

  return (
    standardSamplesStatus.list.find(
      (x) => x.sampleNumber === this.sampleNumber
    ) || null
  )
}

export function getProjectPostponedSamplesStatus(
  this: ProSample | ProSplitSample
) {
  const project = this.getProject()
  return project?.postponedSamplesStatus || null
}

export function getProjectPostponedSamplesStatusSampleItem(
  this: ProSample | ProSplitSample
) {
  const postponedSamplesStatus = this.getProjectPostponedSamplesStatus()

  if (!postponedSamplesStatus) {
    return null
  }

  return (
    postponedSamplesStatus.list.find(
      (x) => x.postponeNumber === this.postponeNumber
    ) || null
  )
}

export function getProjectAssessedSamplesStatus(
  this: ProSample | ProSplitSample
) {
  const project = this.getProject()
  return project?.assessedSamplesStatus || null
}

export function getProjectAssessedSamplesStatusSampleItem(
  this: ProSample | ProSplitSample
) {
  const assessedSamplesStatus = this.getProjectAssessedSamplesStatus()

  if (!assessedSamplesStatus) {
    return null
  }

  return (
    assessedSamplesStatus.list.find(
      (x) => x.assessNumber === this.assessNumber
    ) || null
  )
}

export function getUsedAnalysisTestGroupsForSample(
  this: ProSample | ProSplitSample
) {
  if (['standard', 'equivalent'].includes(this.sampleType)) {
    const samplesStatusItem = this.getProjectStandardSamplesStatusSampleItem()

    return samplesStatusItem?.requiredAnalysisTestGroupIDs || []
  }

  if (this.sampleType === 'assessed') {
    const samplesStatusItem = this.getProjectAssessedSamplesStatusSampleItem()

    return samplesStatusItem?.requiredAnalysisTestGroupIDs || []
  }

  if (this.sampleType === 'postponed') {
    const samplesStatusItem = this.getProjectPostponedSamplesStatusSampleItem()

    return samplesStatusItem?.requiredAnalysisTestGroupIDs || []
  }

  return []
}
