import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46a1784c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "GalleryOverlay"
}
const _hoisted_2 = {
  key: 0,
  class: "ThumbnailList CustomScrollbar Horizontal",
  ref: "Thumbnails"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "Controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.selectedCollection)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("button", {
            class: "ButtonIcon Close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeCollection && _ctx.closeCollection(...args)))
          }, [
            _createVNode(_component_InlineSVG, {
              src: require('@/assets/svg/close.svg')
            }, null, 8, ["src"])
          ]),
          (_ctx.images.length > 1)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thumbnails, (src, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: src }, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_ctx.toImage(index))
                    }, [
                      _createElementVNode("img", {
                        src: src,
                        class: _normalizeClass(["Thumbnail", { IsInActive: index !== _ctx.currentIndex }])
                      }, null, 10, _hoisted_4)
                    ], 8, _hoisted_3)
                  ]))
                }), 128))
              ], 512))
            : _createCommentVNode("", true),
          _createVNode(_TransitionGroup, {
            ref: "Container",
            tag: "div",
            name: "Gallery",
            mode: "in-out",
            onAfterEnter: _ctx.addHammerJSEvents,
            onBeforeLeave: _ctx.removeHammerJSEvents,
            class: "Content"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Spinner, {
                key: "spinner",
                size: "large"
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (src, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  ([_ctx.currentIndex, _ctx.currentIndex + 1, _ctx.currentIndex - 1].includes(index))
                    ? (_openBlock(), _createElementBlock("img", {
                        class: _normalizeClass(["FocusImage", { NotActive: index !== _ctx.currentIndex }]),
                        key: src + '_' + index,
                        ref: "Image",
                        src: src + '?tx=t_resize_min_800',
                        onMousewheel: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args))),
                        style: _normalizeStyle(_ctx.imageTransform)
                      }, null, 46, _hoisted_5))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ]),
            _: 1
          }, 8, ["onAfterEnter", "onBeforeLeave"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "ButtonIcon",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onZoomButtonClick('out')))
            }, [
              _createVNode(_component_InlineSVG, {
                src: require('@/assets/svg/zoom-out.svg')
              }, null, 8, ["src"])
            ]),
            _createElementVNode("button", {
              class: "ButtonIcon",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onZoomButtonClick('in')))
            }, [
              _createVNode(_component_InlineSVG, {
                src: require('@/assets/svg/zoom-in.svg')
              }, null, 8, ["src"])
            ]),
            _createElementVNode("button", {
              class: "ButtonIcon",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onRotate && _ctx.onRotate(...args)))
            }, [
              _createVNode(_component_InlineSVG, {
                src: require('@/assets/svg/rotate-right.svg')
              }, null, 8, ["src"])
            ]),
            _createElementVNode("button", {
              class: _normalizeClass(["ButtonIcon", {
            IsDisabled: _ctx.images.length === 1 || !_ctx.allowLoop && _ctx.currentIndex === 0
          }]),
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.previous && _ctx.previous(...args)))
            }, [
              _createVNode(_component_InlineSVG, {
                src: require('@/assets/svg/angle-left.svg')
              }, null, 8, ["src"])
            ], 2),
            _createElementVNode("button", {
              class: _normalizeClass(["ButtonIcon", {
            IsDisabled: _ctx.images.length === 1 || !_ctx.allowLoop && _ctx.currentIndex === _ctx.images.length - 1
          }]),
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
            }, [
              _createVNode(_component_InlineSVG, {
                src: require('@/assets/svg/angle-right.svg')
              }, null, 8, ["src"])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}