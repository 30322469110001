
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'VideoPlayer',
  props: {
    video: {
      type: String as PropType<
        | 'intro'
        | 'fractions'
        | 'remediation'
        | 'samples'
        | 'materials'
        | 'units'
        | 'resale'
      >,
      required: true,
    },
    width: {
      type: String as PropType<'custom' | 'full'>,
      default: 'full',
    },
  },
  computed: {
    selection() {
      const data = {
        src: '',
        title: '',
      }

      switch (this.video) {
        case 'intro':
          data.src =
            'https://player.vimeo.com/video/738388840?h=15caa6e4bf&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          data.title = 'Online introduktion'
          break

        case 'resale':
          data.src =
            'https://player.vimeo.com/video/738390381?h=bcc743ef78&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          data.title = 'Gensalg'
          break

        case 'fractions':
          data.src =
            'https://player.vimeo.com/video/738390215?h=2c31b0233d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          data.title = 'Fraktioner'
          break

        case 'remediation':
          data.src =
            'https://player.vimeo.com/video/738389637?h=4c8304f670&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          data.title = 'Saneringsopgaver'
          break

        case 'units':
          data.src =
            'https://player.vimeo.com/video/738389890?h=f6f491d4ce&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          data.title = 'Bygninger og grundplaner'
          break

        case 'materials':
          data.src =
            'https://player.vimeo.com/video/738389419?h=a20c137106&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          data.title = 'Registrerede materialer'
          break

        case 'samples':
          data.src =
            'https://player.vimeo.com/video/738389143?h=d12ff2fea2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          data.title = 'Prøver og vurderinger'
          break
      }

      return data
    },
  },
})
