/* eslint-disable no-use-before-define */
import Building from '@/globals/javascript/models/proUnit/Building'
import Unit from '@/globals/javascript/models/proUnit/Unit'
import { mixWB } from '@/globals/javascript/_utils/mixins'

interface IApartmentData {
  floorID: string | null
  buildingID: string | null
  title: string
  bbrAccessAddressID: string | null
  numericFloorValue: number
}

interface IApartmentConstructor {
  id: string
  isSelected: boolean
  bbrID: string | null
  bbrData: unknown
  type: string
  data: IApartmentData
}

export default class Apartment extends Unit {
  // Apartment
  data = {} as IApartmentData

  constructor(
    { id, isSelected, bbrID, bbrData, type, data }: IApartmentConstructor,
    projectID: string
  ) {
    super({
      id,
      isSelected,
      bbrID,
      bbrData,
      type,
      projectID,
    })

    this.data = {
      floorID: data.floorID || null,
      buildingID: data.buildingID || null,
      bbrAccessAddressID: data.bbrAccessAddressID || null,
      title: data.title || '',
      numericFloorValue:
        data.numericFloorValue !== null && data.numericFloorValue !== undefined
          ? data.numericFloorValue
          : 0,
    }
  }

  getFullTitle() {
    const floorText = getShortFloorText(this.data.numericFloorValue)
    const building = Apartment.getUnitById(this.data.buildingID || '')
    const buildingText =
      building instanceof Building ? building.getShortTitle() : mixWB('UNKNOWN')

    if (this.bbrID) {
      if (!this.bbrData.dør) {
        return `${buildingText} - ${floorText} - ${this.bbrData.etage.toUpperCase()}`
      }
      if (Number.isNaN(this.bbrData.dør)) {
        return `${buildingText} - ${floorText} ${this.bbrData.dør}`
      }

      return `${buildingText} - ${getFloorText(
        this.data.numericFloorValue
      )} - ${mixWB('DOOR_X', [this.bbrData.dør])}`
    }

    return `${buildingText} - ${floorText}`
  }

  getTitle() {
    const floorText = getFloorText(this.data.numericFloorValue)

    if (this.bbrID) {
      if (!this.bbrData.dør) {
        return `${floorText} - ${this.bbrData.etage.toUpperCase()}`
      }
      if (Number.isNaN(this.bbrData.dør)) {
        return `${floorText} - ${this.bbrData.dør}`
      }

      return `${floorText} - ${mixWB('DOOR_X', [this.bbrData.dør])}`
    }

    return floorText
  }

  getBuildingID() {
    return this.data.buildingID
  }
}

// Private functions
const getFloorText = (numericFloorValue: number) => {
  if (numericFloorValue > 0) {
    return mixWB('X_FLOOR', [numericFloorValue.toString()])
  }
  if (numericFloorValue < 0) {
    return mixWB('BASEMENT_X_SHORT', [numericFloorValue.toString()])
  }
  return mixWB('GROUND_FLOOR')
}

const getShortFloorText = (numericFloorValue: number) => {
  if (numericFloorValue > 0) {
    return `${numericFloorValue}.`
  }
  if (numericFloorValue < 0) {
    return mixWB('BASEMENT_X_SHORT', [numericFloorValue.toString()])
  }
  return mixWB('GROUND_FLOOR_SHORT')
}
