import { ENV } from '@/globals/javascript/_utils/env'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default async function checkSWState(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  // Never use serviceWorker in development
  if (ENV.isDevelopment) {
    next()
    return
  }

  // Trying to re-register our SW on route changes to force updates if chache or SW file has changed
  const reg = await navigator.serviceWorker.register(
    `${process.env.BASE_URL}service-worker.js`
  )

  reg.update()

  next()
}
