export default [
  {
    name: 'Overview',
    url: 'overview',
    translation: 'OVERVIEW',
    menuTranslation: 'OVERVIEW',
    icon: 'house',
  },
  {
    name: 'Fractions',
    url: 'fractions',
    translation: 'FRACTIONS',
    menuTranslation: 'FRACTIONS',
    icon: 'fractions',
  },
  {
    name: 'BuildingsAndFloorPlans',
    url: 'buildings-and-floor-plans',
    translation: 'BUILDINGS_AND_FLOOR_PLANS',
    menuTranslation: 'BUILDINGS_AND_FLOOR_PLANS',
    icon: 'buildings',
  },
  {
    name: 'Paradigm',
    url: 'paradigm',
    translation: 'PARADIGM',
    menuTranslation: 'PARADIGM',
    icon: 'paradigm',
  },
  {
    name: 'SamplesAndAssesments',
    url: 'samples-and-assessments',
    translation: 'SAMPLES_AND_ASSESSMENTS',
    menuTranslation: 'SAMPLES_AND_ASSESSMENTS',
    icon: 'analysis',
  },
  {
    name: 'RegistredMaterials',
    url: 'registred-materials',
    translation: 'REGISTERED_TYPES',
    menuTranslation: 'REGISTERED_TYPES',
    icon: 'types',
  },
  {
    name: 'Resale',
    url: 'resale',
    translation: 'RESALE',
    menuTranslation: 'RESALE',
    icon: 'resale',
  },
  {
    name: 'RemediationTasks',
    url: 'remediation-tasks',
    translation: 'REMEDIATION_TASKS',
    menuTranslation: 'REMEDIATION_TASKS',
    icon: 'remediation',
  },
  {
    name: 'Comments',
    url: 'comments',
    translation: 'COMMENTS_2',
    menuTranslation: 'COMMENTS_2',
    icon: 'text',
  },
  {
    name: 'Documents',
    url: 'documents',
    translation: 'DOCUMENTS',
    menuTranslation: 'DOCUMENTS',
    icon: 'download',
  },
  {
    name: 'FullReport',
    url: 'pdf/full-report',
    translation: 'FULL_REPORT',
    menuTranslation: 'FULL_REPORT',
    icon: '',
  },
  {
    name: 'ScreeningReport',
    url: 'pdf/screening-report',
    translation: 'SCREENING_REPORT',
    menuTranslation: 'SCREENING_REPORT',
    icon: '',
  },
  {
    name: 'SamplesDocument',
    url: 'pdf/samples-document',
    translation: 'WORK_DOCUMENT_SAMPLES_AND_ASSESSMENTS',
    menuTranslation: 'WORK_DOCUMENT_SAMPLES_AND_ASSESSMENTS',
    icon: '',
  },
  {
    name: 'DamageRelatedScreeningReport',
    url: 'pdf/damage-related-screening-report',
    translation: 'DAMAGE_RELATED_ENVIRONMENTAL_SCREENING',
    menuTranslation: 'DAMAGE_RELATED_ENVIRONMENTAL_SCREENING',
    icon: '',
  },
]
