
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaneLink',
  props: {
    // hashID (x-0000) of pane this link is targetting
    paneId: {
      type: String,
    },
  },
  data() {
    return {
      paneIndex: NaN,
    }
  },
  computed: {
    paneIDs() {
      const { paneIDs } = this.$route.params

      if (typeof paneIDs === 'string') {
        return []
      }

      return paneIDs
    },
    isActive() {
      if (this.isDisabled) {
        return false
      }

      const nextPaneID = this.paneIDs?.[this.paneIndex + 1]

      return this.paneId === nextPaneID
    },
    isCurrent() {
      if (this.isDisabled) {
        return false
      }

      return this.paneId === this.paneIDs?.slice(-1)[0]
    },
    isDisabled() {
      return !this.paneId || Number.isNaN(this.paneIndex)
    },
    paneHref() {
      if (!this.paneId || Number.isNaN(this.paneIndex)) {
        return ''
      }

      if (!this.paneIDs || this.paneIDs.length === 0) {
        return this.$routeAppend(this.paneId)
      }

      const nextPane = this.paneIDs[this.paneIndex + 1]
      const previousPane = this.paneIDs[this.paneIndex - 1]

      // If the requested pane ID exist right before the current - go back one pane
      if (previousPane === this.paneId) {
        return this.$routeAppend(
          this.paneId,
          this.$route.fullPath.split(`/${previousPane}`)[0]
        )
      }

      // The next pane is already active - let's replace it.
      if (nextPane) {
        return this.$routeAppend(
          this.paneId,
          this.$route.fullPath.split(nextPane)[0]
        )
      }

      return this.$routeAppend(this.paneId)
    },
  },
  methods: {
    setPaneIndex() {
      // Grab pane-index from pane page element which current panelink resides in
      const panePageElement = this.$el.closest('[data-pane-index]')

      if (!panePageElement) {
        return NaN
      }

      this.paneIndex = Number(panePageElement.dataset.paneIndex)
    },
  },
  mounted() {
    this.setPaneIndex()
  },
})
