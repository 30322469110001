import { ProType } from '@/globals/javascript/models/proType/ProType'

export function hasTags(this: ProType, tags: string[] = []) {
  return tags.length === 0
    ? false
    : tags.every((tag) => this.tags.tagIDs.includes(tag))
}

export function hasCoating(this: ProType) {
  return !!this.coating.coatingTypeIDs.length
}
