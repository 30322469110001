/* eslint-disable no-use-before-define */
import { mixWB, mixWBPreRender } from '@/globals/javascript/_utils/mixins'
import {
  appStore,
  countriesStore,
  filterStore,
  proProjectsStore,
  resourcesStore,
  translationsStore,
} from '@/store'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default async function setContentData(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { getTranslationPromise } = translationsStore()
  const appStoreRef = appStore()

  // Shortcircuit all 404 hits
  if (to.name === '404') {
    appStoreRef.setErrorState(
      'page-not-found',
      to.path === '' ? 'ROOT' : mixWBPreRender('THE_PAGE_DOES_NOT_EXIST')
    )

    appStoreRef.setBootingState(false)
    next()
    return
  }

  const proStoreRef = proProjectsStore()

  if (!proStoreRef.dataFetched) {
    const { pathMatch, projectID } = to.params

    const response = await fetchProject(to)

    // If projectID is faulty
    if (typeof projectID !== 'string') {
      appStoreRef.setErrorState(
        'internal-error',
        mixWB('ERROR_PROJECT_ID_IS_MISSING')
      )

      await getTranslationPromise()
      next()

      return
    }

    // Check for firebase error
    if (response === 'ERROR') {
      await getTranslationPromise()
      next()

      return
    }

    const filterStoreRef = filterStore()
    const isEnterprisePath = pathMatch === 'enterprise'

    // Set initial project id
    let initialProjectID = projectID

    // If old enterprise url
    if (isEnterprisePath) {
      initialProjectID = (
        to.query.projectId ? to.query.projectId : proStoreRef.projects[0].id
      ) as string
    }

    filterStoreRef.setInitialProjectID(initialProjectID)

    // If requested project is old Enterprise URL
    if (isEnterprisePath) {
      const newPath = to.path
        .replace('enterprise', 'project')
        .replace(projectID, initialProjectID)

      next({
        path: newPath,
        query: { ...to.query, projectId: undefined },
      })

      return
    }
  }

  // Setting marker concerning if current request is in Resale flow
  appStoreRef.setIsResale(to.name === 'Resale')

  // Show consent banner if no consent have been given
  if (document.cookie.indexOf('CookieInformationConsent') === -1) {
    window.hideCookieBanner && window.showCookieBanner()
  }

  // All passed
  next()
}

const fetchProject = async (to: RouteLocationNormalized) => {
  const { setErrorState } = appStore()
  const { fetchProjects, instantiateProjects } = proProjectsStore()
  const { dataFetched: ressourcesFetched, fetchAllResources } = resourcesStore()
  const { dataFetched: countriesFetched, getCountries } = countriesStore()
  const { getTranslationPromise } = translationsStore()

  const promises = [
    fetchProjects({
      idType: to.params.pathMatch,
      id: to.params.projectID,
      projectID: to.query.projectId,
    }),
  ]

  // Only request ressources if they haven't been loaded
  if (!ressourcesFetched) {
    promises.push(fetchAllResources())
  }

  // Only request countries if they haven't been loaded
  if (!countriesFetched) {
    promises.push(getCountries())
  }

  // Improving performance by running parallel promises
  const [responseData] = await Promise.all(promises)

  // Check for errors
  if (responseData && 'code' in responseData) {
    // Set error state in store
    setErrorState(
      responseData.code,
      mixWB(responseData.message, [responseData.id])
    )

    return 'ERROR'
  }

  await getTranslationPromise()

  instantiateProjects()

  return null
}
