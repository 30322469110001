import routeObjects from '@/router/routeObjects'
import { RouteRecordRaw } from 'vue-router'

const panePages = routeObjects.filter(
  function (this: string[], r) {
    return this.includes(r.name)
  },
  [
    'Fractions',
    'BuildingsAndFloorPlans',
    'Paradigm',
    'SamplesAndAssesments',
    'RegistredMaterials',
    'RemediationTasks',
    'Resale',
  ]
)

const miscPages = routeObjects.filter(
  function (this: string[], r) {
    return this.includes(r.name)
  },
  ['Overview', 'Comments', 'Documents']
)

const pdfPages = routeObjects.filter(
  function (this: string[], r) {
    return this.includes(r.name)
  },
  [
    'FullReport',
    'ScreeningReport',
    'SamplesDocument',
    'DamageRelatedScreeningReport',
  ]
)

const routes: Array<RouteRecordRaw> = [
  {
    name: 'Root',
    path: '/:pathMatch(enterprise|project)',
    component: () =>
      import(/* webpackChunkName: "Root" */ '@/views/project/Root.vue'),
    children: [
      // Setting up root redirect
      {
        name: 'ProjectRoot',
        path: ':projectID',
        redirect: { name: 'Overview' },
      },

      {
        name: 'RegistredTypes',
        path: ':projectID/registred-types/:paneIDs*',
        redirect: { name: 'RegistredMaterials' },
      },

      // Pane pages
      ...panePages.map((p) => ({
        name: p.name,
        path: `:projectID/${p.url}/:paneIDs*`,
        props: true,
        meta: {
          translation: p.translation,
          menuTranslation: p.menuTranslation,
          url: p.url,
          robots: 'noindex,nofollow',
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "[request]" */ `@/views/project/${p.name}.vue`
            ),
          top: () =>
            import(
              /* webpackChunkName: "Breadcrumb" */ '@/views/Breadcrumb.vue'
            ),
        },
      })),

      // Misc pages
      ...miscPages.map((p) => ({
        name: p.name,
        path: `:projectID/${p.url}`,
        props: true,
        meta: {
          translation: p.translation,
          menuTranslation: p.menuTranslation,
          url: p.url,
          robots: 'noindex,nofollow',
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "[request]" */ `@/views/project/${p.name}.vue`
            ),
          top: () =>
            import(
              /* webpackChunkName: "Breadcrumb" */ '@/views/Breadcrumb.vue'
            ),
        },
      })),

      ...pdfPages.map((p) => ({
        name: p.name,
        path: `:projectID/${p.url}`,
        props: true,
        meta: {
          translation: p.translation,
          menuTranslation: p.menuTranslation,
          url: p.url,
          robots: 'noindex,nofollow',
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "[request]" */ `@/views/pdfs/${p.name}.vue`
            ),
          top: () =>
            import(
              /* webpackChunkName: "Breadcrumb" */ '@/views/Breadcrumb.vue'
            ),
        },
      })),
    ],
  },

  {
    // Docs: https://next.router.vuejs.org/guide/migration/#removed-star-or-catch-all-routes
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      translation: '404',
    },
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
  },
]

export default routes
