/* eslint-disable no-use-before-define */
import OnlineType, {
  ISpecialCase,
} from '@/globals/javascript/models/onlineType/OnlineType'
import { ProCoatingType } from '@/globals/javascript/models/proCoatingType/ProCoatingType'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { isProCoatingType, isProType } from '@/globals/javascript/_utils/util'
import { IInfoText } from '@/__types__/utils/infoText'
import { union } from 'lodash-es'

export function getInformationTexts(this: OnlineType): IInfoText[] {
  const infoTextsMap = {
    uniqueCases: [] as IInfoText[],
    noMaterialSampleRequired: [] as IInfoText[],
    requiredAnalyses: [] as IInfoText[],
    remediation: [] as IInfoText[],
    assessments: [] as IInfoText[],
    contaminatedByAdjecent: [] as IInfoText[],
  }

  const showRisks =
    this.options.hasPostponedSample || this.options.hasMissingTestResults

  // Get risks when required
  const testNames = getTestNames(this, showRisks)

  // Could be contaminated by adjecent onlineTypes
  if (this.options.isPotentiallyContaminated) {
    return getPotentiallyContaminatedTexts(this)
  }

  // Has postponed sample
  if (this.options.hasPostponedSample) {
    return getHasPostponedSampleTexts(testNames)
  }

  // Has missing test results
  if (this.options.hasMissingTestResults) {
    return getHasMissingTestResults(testNames)
  }

  // 1. Find initial data
  const lastType = this.types[this.types.length - 1]
  const nextToLastType = this.types[this.types.length - 2]
  const hasCoating = isProCoatingType(lastType)
  const coatingWDCID = hasCoating && this.outerWDCID

  // 1.1 Combine all of the same special cases into one object
  const { uniqueCases, usedTypeIDs } = getUniqueCases(this.specialCases)

  // 2. Add texts from special cases
  infoTextsMap.uniqueCases = getUniqueCasesTexts(this, uniqueCases)

  // 3. No material sample required text
  infoTextsMap.noMaterialSampleRequired = getNoMaterialSampleRequiredTexts(
    this,
    usedTypeIDs
  )

  // 4. Add required analyses text
  infoTextsMap.requiredAnalyses = getRequiredAnalysesTexts(
    this,
    hasCoating,
    uniqueCases
  )

  // 5. Remediation texts
  infoTextsMap.remediation = getRemediationTexts(
    this,
    uniqueCases,
    nextToLastType,
    hasCoating
  )

  // 6. Assessments
  infoTextsMap.assessments = getAssessmentTexts(this)

  // 7. Contaminated by adjacent type
  infoTextsMap.contaminatedByAdjecent = getContaminatedByAdjecentTexts(
    this,
    hasCoating,
    coatingWDCID
  )

  if (this.courseOfAction === 'prevention') {
    return [
      ...infoTextsMap.uniqueCases,
      ...infoTextsMap.noMaterialSampleRequired,
      ...infoTextsMap.requiredAnalyses,
      ...infoTextsMap.assessments,
      ...infoTextsMap.contaminatedByAdjecent,
    ]
  }

  return [
    ...infoTextsMap.uniqueCases,
    ...infoTextsMap.noMaterialSampleRequired,
    ...infoTextsMap.requiredAnalyses,
    ...infoTextsMap.remediation,
    ...infoTextsMap.assessments,
    ...infoTextsMap.contaminatedByAdjecent,
  ]
}

/**
 * LOCAL HELPERS
 */
interface IUniqueCase {
  caseID: string
  pTypes: (ProType | ProCoatingType)[]
  predefinedHeadline?: string
  answer?: 0 | 1 | 2
}

function getTestNames(oType: OnlineType, showRisks: boolean) {
  let testNames: string[] = []

  if (showRisks) {
    oType.samples.forEach((s) => {
      testNames = union(
        testNames,
        s.getTestList().map((x) => x.text)
      )
    })
  }

  return testNames
}

function getPotentiallyContaminatedTexts(oType: OnlineType) {
  const texts: IInfoText[] = []

  const { oTypesMissingTestResults, oTypesPostponedSample } =
    oType.potentiallyContaminatedBy.reduce(
      (prev, onlineType) => {
        const { options, titles } = onlineType
        if (options.hasPostponedSample) {
          prev.oTypesPostponedSample.push(titles.complete)
        } else if (options.hasMissingTestResults) {
          prev.oTypesMissingTestResults.push(titles.complete)
        }
        return prev
      },
      {
        oTypesMissingTestResults: [] as string[],
        oTypesPostponedSample: [] as string[],
      }
    )

  texts.push({
    type: 'header',
    text: mixWB('POTENTIALLY_CONTAMINATED'),
  })

  // Text for missing test results
  if (oTypesMissingTestResults.length === 1) {
    texts.push({
      text: mixWB('POTENTIALLY_CONTAMINATED_TEXT_1', [
        oTypesMissingTestResults[0],
      ]),
    })
  }

  if (oTypesMissingTestResults.length > 1) {
    texts.push({
      text: mixWB('POTENTIALLY_CONTAMINATED_TEXT_2', [
        oTypesMissingTestResults.join(', '),
      ]),
    })
  }

  // Text for postponed sample
  if (oTypesPostponedSample.length === 1) {
    texts.push({
      text: mixWB('POTENTIALLY_CONTAMINATED_TEXT_3', [
        oTypesPostponedSample[0],
      ]),
    })
  }

  if (oTypesPostponedSample.length > 1) {
    texts.push({
      text: mixWB('POTENTIALLY_CONTAMINATED_TEXT_4', [
        oTypesPostponedSample.join(', '),
      ]),
    })
  }

  return texts
}

function getHasPostponedSampleTexts(testNames: string[]) {
  const texts: IInfoText[] = []

  texts.push({
    type: 'header',
    text: mixWB('POSTPONED_SAMPLE'),
  })

  texts.push({
    text: mixWB('SAMPLING_WAS_NOT_POSSIBLE_DURING_THE_MAPPING'),
  })

  if (testNames.length) {
    texts.push({
      text: `${mixWB('ASSESSED_RISK_OF')}: ${testNames.join(', ')}.`,
    })
  }

  texts.push({
    text: mixWB('POSTPONED_SAMPLE_INFO_TEXT'),
  })

  return texts
}

function getHasMissingTestResults(testNames: string[]) {
  const texts: IInfoText[] = []

  if (testNames.length) {
    texts.push({
      type: 'header',
      text: `${mixWB('STATUS_AWAITING_TEST_RESULTS')}`,
    })
    texts.push({
      text: `${mixWB('ASSESSED_RISK_OF')}: ${testNames.join(', ')}.`,
    })

    return texts
  }

  texts.push({
    text: `${mixWB('STATUS_AWAITING_TEST_RESULTS')}.`,
  })

  return texts
}

function getUniqueCases(specialCases: ISpecialCase[]) {
  let usedTypeIDs: string[] = []
  const uniqueCases: IUniqueCase[] = []

  specialCases.forEach((specialCase) => {
    let item = uniqueCases.find((x) => x.caseID === specialCase.caseID)

    if (!item) {
      item = {
        caseID: specialCase.caseID,
        pTypes: [],
        predefinedHeadline: specialCase.title ?? '',
        answer: specialCase.answer,
      }

      uniqueCases.push(item)
    }

    item.pTypes.push(specialCase.pType)

    if (specialCase.overrideNormalInformationText) {
      usedTypeIDs = union(usedTypeIDs, [specialCase.pType.id])
    }
  })

  return {
    usedTypeIDs,
    uniqueCases,
  }
}

function getUniqueCasesTexts(oType: OnlineType, uniqueCases: IUniqueCase[]) {
  const texts: IInfoText[] = []

  uniqueCases.forEach((uniqueCase) => {
    if (uniqueCase.caseID === 'special-case-1') {
      texts.push(oType.getInfoTextHeadlineFromTypes(uniqueCase.pTypes))

      texts.push({
        text: mixWB('ISOLATION_BEFORE_1998_SPECIAL_TEXT_1'),
      })
      texts.push({
        text: mixWB('INSULATION_AUTO_CONTAMINATED_TEXT'),
      })
    }

    if (uniqueCase.caseID === 'special-case-2') {
      texts.push(oType.getInfoTextHeadlineFromTypes(uniqueCase.pTypes))
      texts.push({
        text: mixWB('ISOLATION_AFTER_1998_SPECIAL_TEXT_1'),
      })
      texts.push({
        text: mixWB('INSULATION_AUTO_CONTAMINATED_TEXT'),
      })
    }

    if (uniqueCase.caseID === 'special-case-3') {
      texts.push(oType.getInfoTextHeadlineFromTypes(uniqueCase.pTypes))
      texts.push({
        text: mixWB('PRESSURE_IMPREGNATED_WOOD_SPECIAL_CASE_TEXT_1'),
      })
      if (oType.finalWDCID === 'WDC-2') {
        texts.push({
          text: mixWB('PRESSURE_IMPREGNATED_WOOD_SPECIAL_TEXT_2'),
        })
      }
    }

    if (uniqueCase.caseID === 'special-case-4') {
      texts.push(oType.getInfoTextHeadlineFromTypes(uniqueCase.pTypes))
      texts.push({
        text: mixWB('NO_SAMPLE_FOR_ASBESTOS_TEXT'),
      })
    }

    if (uniqueCase.caseID === 'special-case-5') {
      texts.push(oType.getInfoTextHeadlineFromTypes(uniqueCase.pTypes))
      texts.push({
        text: mixWB('NO_SAMPLE_FOR_ASBESTOS_TILES_TEXT'),
      })
    }

    if (uniqueCase.caseID === 'special-case-6') {
      texts.push(oType.getInfoTextHeadlineFromTypes(uniqueCase.pTypes))
      texts.push({
        text: mixWB('SOOT_CONTAMINATED_TEXT'),
      })
    }

    if (uniqueCase.caseID === 'special-case-7') {
      texts.push({
        type: 'header',
        text: uniqueCase.predefinedHeadline ?? '',
      })
      texts.push({
        text: mixWB('RISK_REQUIRES_SAMPLE_TEXT'),
      })
    }

    if (uniqueCase.caseID === 'special-case-10') {
      let conclusion = mixWB('NOT_ASSESSED')
      if (uniqueCase.answer === 0) {
        conclusion = mixWB('NO')
      }
      if (uniqueCase.answer === 1) {
        conclusion = mixWB('YES')
      }

      texts.push(oType.getInfoTextHeadlineFromTypes(uniqueCase.pTypes))
      texts.push({
        text: `${mixWB(
          'CAN_BRICKS_BE_CLEANED_OF_MORTAR_AND_REUSED'
        )}: ${conclusion}.`,
      })
    }

    if (uniqueCase.caseID === 'special-case-11') {
      texts.push(oType.getInfoTextHeadlineFromTypes(uniqueCase.pTypes))
      texts.push({
        text: mixWB('SOOT_SPECIAL_CASE_TEXT_1'),
      })
    }
  })

  return texts
}

function getNoMaterialSampleRequiredTexts(
  oType: OnlineType,
  usedTypeIDs: string[]
) {
  const texts: IInfoText[] = []

  // 3.1 Find all non-special case materials with no samples required
  const materialTypes = oType.types.filter((pType) => {
    if (!(pType instanceof ProType)) {
      return false
    }

    if (usedTypeIDs.includes(pType.id)) {
      return false
    }

    if (pType.options.isSampleRequired) {
      return false
    }

    return true
  })

  // 3.2 Show explanation text
  if (materialTypes.length) {
    const header = oType.getInfoTextHeadlineFromTypes(materialTypes)
    const existingHeaderIndex = texts.findIndex(
      (x) => x.type === 'header' && x.text === header.text
    )
    const infoTextItem = {
      text: mixWB('NO_ANALYSIS_MADE_TEXT'),
    }

    // Check to add to existing header if any
    if (existingHeaderIndex > -1) {
      texts.splice(existingHeaderIndex + 1, 0, infoTextItem)
    } else {
      texts.push(header)
      texts.push(infoTextItem)
    }
  }

  return texts
}

function getRequiredAnalysesTexts(
  oType: OnlineType,
  hasCoating: boolean,
  uniqueCases: IUniqueCase[]
) {
  const texts: IInfoText[] = []

  // 4.1 For materials
  oType.types.forEach((pType) => {
    if (isProCoatingType(pType)) {
      return
    }

    // If no samples attacthed to type
    if (
      !pType.samples.manualSampleIDs.length &&
      !pType.samples.sampleIDs.length
    ) {
      return
    }

    // In case wallpaper has a manual sample
    const pTypeMaterial = pType.getResourceType()
    if (
      pTypeMaterial?.id === 'T-64' &&
      uniqueCases.find((x) => x.caseID === 'special-case-7')
    ) {
      return
    }

    texts.push({
      type: 'header',
      text: pType.getTitle(),
    })
    texts.push({
      text: mixWB('RISK_REQUIRES_SAMPLE_TEXT'),
    })
  })

  // 4.2 For coating
  if (hasCoating && !uniqueCases.find((x) => x.caseID === 'special-case-7')) {
    texts.push({
      type: 'header',
      text: mixWB('COATING'),
    })
    texts.push({
      text: mixWB('RISK_REQUIRES_SAMPLE_TEXT'),
    })
  }

  return texts
}

function getRemediationTexts(
  oType: OnlineType,
  uniqueCases: IUniqueCase[],
  nextToLastType: ProType | ProCoatingType,
  hasCoating: boolean
) {
  const texts: IInfoText[] = []

  // 5.1 Metal with coating
  if (uniqueCases.find((x) => x.caseID === 'special-case-9')) {
    texts.push({
      type: 'header',
      text: mixWB('CLEANING'),
    })

    // 5.1.1 Clean or contaminated coating
    if (['WDC-1', 'WDC-2'].includes(oType.finalWDCID)) {
      texts.push({
        text: mixWB('CLEANING_UNNECESSARY'),
      })
    } else {
      texts.push({
        text: mixWB('POTENTIAL_CLEANING_MUST_BE_CLARIFIED_TEXT'),
      })
    }
  } else if (isProType(nextToLastType) && hasCoating) {
    texts.push({
      type: 'header',
      text: mixWB('CLEANING'),
    })

    // 5.2 WDG-2 + coating
    if (nextToLastType.getWDGID() === 'WDG-2') {
      if (oType.finalWDCID === 'WDC-1') {
        texts.push({
          text: mixWB('NO_CLEANING_OF_COATING_TEXT_4'),
        })
      } else {
        texts.push({
          text: mixWB('COATING_CLEANING_UNPROFITABLE_TEXT', [
            nextToLastType.getTitle(),
          ]),
        })
      }
    }
    // 5.3 Not WDG-2 + coating
    else {
      texts.push({
        text: mixWB('COATING_CLEANING_UNPROFITABLE_TEXT', [
          nextToLastType.getTitle(),
        ]),
      })
    }
  }

  return texts
}

function getAssessmentTexts(oType: OnlineType) {
  const texts: IInfoText[] = []
  let isAssessHeadlineAdded = false

  oType.samples.forEach((sample) => {
    if (!sample.assessNumber) {
      return
    }

    // 6.1 Find associated type
    let pType = null
    if (sample.kindOfSample === 'material') {
      pType = oType.types.find(
        (x) => isProType(x) && x.id.includes(sample.typeID)
      )
    }
    if (sample.kindOfSample === 'coating') {
      pType = oType.types.find(
        (x) => isProCoatingType(x) && x.id.includes(sample.typeID)
      )
    }

    if (!pType) {
      return
    }

    // 6.2 Add assessment header
    if (!isAssessHeadlineAdded) {
      texts.push({
        type: 'header',
        text: mixWB('ASSESSMENTS'),
      })
      isAssessHeadlineAdded = true
    }

    // 6.3 Add text
    const assessmentTag = `V${sample.assessNumber}`

    if (sample.status === 'skip-hazardous-waste') {
      texts.push({
        text: `${assessmentTag} - ${mixWB('WDC_3_ASSESSMENT_TEXT_FOR_X', [
          pType.getTitle(),
        ])}`,
      })
    }

    if (sample.status === 'skip-asbest') {
      texts.push({
        text: `${assessmentTag} - ${mixWB('WDC_4_ASSESSMENT_TEXT_FOR_X', [
          pType.getTitle(),
        ])}`,
      })
    }

    if (sample.status === 'skip-hazardous-asbestos-waste') {
      texts.push({
        text: `${assessmentTag} - ${mixWB('WDC_5_ASSESSMENT_TEXT_FOR_X', [
          pType.getTitle(),
        ])}`,
      })
    }

    if (sample.status === 'auto-assess-asbestos-perforated-ceiling-tiles') {
      texts.push({
        text: `${assessmentTag} - ${mixWB(
          'PERFORATED_CEILING_TILES_ASBESTOS_EXPLANATION'
        )}`,
      })
    }
  })

  return texts
}

function getContaminatedByAdjecentTexts(
  oType: OnlineType,
  hasCoating: boolean,
  coatingWDCID: string | false
) {
  const texts: IInfoText[] = []

  oType.contaminatedBy.forEach((contamination, index) => {
    if (index === 0) {
      texts.push({
        type: 'header',
        text: mixWB('CONTAMINATED_BY_ADJACENT_TYPE'),
      })
    }

    // 7.1 Contamidated from inner type
    if (contamination.side === 'inner') {
      if (oType.wdcID === 'WDC-1') {
        texts.push({
          text: mixWB(
            'THE_ADJACENT_UNDERLYING_TYPE_X_HAS_CONTAMINATED_THIS_TYPE',
            [contamination.type.titles.complete]
          ),
        })
      } else {
        texts.push({
          text: mixWB(
            'THE_ADJACENT_UNDERLYING_TYPE_X_HAS_FURTHER_CONTAMINATED_THIS_TYPE',
            [contamination.type.titles.complete]
          ),
        })
      }
    }

    // 7.2 Contamidated from outer type
    if (contamination.side === 'outer') {
      // 7.3 With coating
      if (hasCoating) {
        if (coatingWDCID === 'WDC-1') {
          texts.push({
            text: mixWB(
              'THE_ADJACENT_OVERLYING_TYPE_X_HAS_CONTAMINATED_THE_COATING_OF_THIS_TYPE',
              [contamination.type.titles.complete]
            ),
          })
        } else {
          texts.push({
            text: mixWB(
              'THE_ADJACENT_OVERLYING_TYPE_X_HAS_FURTHER_CONTAMINATED_THE_COATING_OF_THIS_TYPE',
              [contamination.type.titles.complete]
            ),
          })
        }
      }
      // 7.4 Without coating
      else {
        if (oType.wdcID === 'WDC-1') {
          texts.push({
            text: mixWB(
              'THE_ADJACENT_OVERLYING_TYPE_X_HAS_CONTAMINATED_THIS_TYPE',
              [contamination.type.titles.complete]
            ),
          })
        } else {
          texts.push({
            text: mixWB(
              'THE_ADJACENT_OVERLYING_TYPE_X_HAS_FURTHER_CONTAMINATED_THIS_TYPE',
              [contamination.type.titles.complete]
            ),
          })
        }
      }
    }
  })

  return texts
}
