import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c1f764e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PageLoader" }
const _hoisted_2 = { class: "Logo" }
const _hoisted_3 = {
  key: 0,
  class: "TextWrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_LoadingTextChanger = _resolveComponent("LoadingTextChanger")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InlineSVG, {
        src: require('@/assets/logo/milva-logo-dark.svg')
      }, null, 8, ["src"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.mixWBPreRender('ONLINE_REPORT')), 1)
    ]),
    _createVNode(_component_Spinner, { size: "large" }),
    (_ctx.texts.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_LoadingTextChanger, { texts: _ctx.texts }, null, 8, ["texts"])
        ]))
      : _createCommentVNode("", true)
  ]))
}