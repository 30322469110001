/* eslint-disable no-use-before-define */

import { ProCoatingType } from '@/globals/javascript/models/proCoatingType/ProCoatingType'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { IResourcesEWCCode } from '@/__types__/_pro/resources/resources-ewcCode'
import { IResourcesFraction } from '@/__types__/_pro/resources/resources-fraction'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { getInformationTexts } from '@/globals/javascript/models/onlineType/getInformationTexts'
import { setAmount } from '@/globals/javascript/models/onlineType/setAmount'
import {
  setCategoryTitle,
  setCustomTypeState,
  setFractionAndEWCCode,
  setFractionTitle,
  setHashID,
  setHasPostponedSampleStatus,
  setMissingTestResultsStatus,
  setMultiTypeStatus,
  setRooms,
  setSamples,
  setTitles,
  setTypeTitles,
  setUnitIDsAndText,
  setStatus,
  setIsPotentiallyContaminated,
  setCourseOfAction,
} from '@/globals/javascript/models/onlineType/setters'
import { setRecycableState } from '@/globals/javascript/models/onlineType/setRecycableState'
import {
  deriveFractionAndEWCCode,
  getContamination,
  getInfoTextHeadlineFromTypes,
} from '@/globals/javascript/models/onlineType/helpers'
import { setFinalWCDID } from '@/globals/javascript/models/onlineType/setFinalWCDID'
import { getWorkdescriptionTexts } from '@/globals/javascript/models/onlineType/getWorkdescriptionTexts'
import {
  getAmountData,
  getCanBurnStatus,
  getCO2AmountData,
  getCO2TextWithDetails,
  getDisposalCostAsText,
  getIsNeverAloneStatus,
  getTonsAsText,
  getTonsTextWithDetails,
} from '@/globals/javascript/models/onlineType/getters'
import { setSpecialCases } from '@/globals/javascript/models/onlineType/setSpecialCases'
import { setOwnWDCIDs } from '@/globals/javascript/models/onlineType/setOwnWDCIDs'
import { CleaningTask } from '@/globals/javascript/models/CleaningTask'
import { setDisposalCost } from '@/globals/javascript/models/onlineType/setDisposalCost'
import { setAmountFromCleaningTasks } from '@/globals/javascript/models/onlineType/setAmountFromCleaningTasks'
import { setWDGIDs } from '@/globals/javascript/models/onlineType/setWDGIDs'
import { getTestResultsTexts } from '@/globals/javascript/models/onlineType/getTestResultsTexts'
import { getTypeComments } from '@/globals/javascript/models/onlineType/getTypeComments'
import { IAmountTypes } from '@/__types__/utils/amountTypes'
import { setCO2 } from '@/globals/javascript/models/onlineType/setCO2'

export interface ISpecialCase {
  caseID: string
  wdcID: '' | 'WDC-1' | 'WDC-2' | 'WDC-3' | 'WDC-4' | 'WDC-5'
  pType: ProType | ProCoatingType
  overrideNormalInformationText: boolean
  title?: string
  answer?: 0 | 1 | 2
}

export interface ICombinedContamination {
  type: OnlineType
  wdcID: string
  side: 'inner' | 'outer'
}

export interface IPTypeAmountItem {
  pTypeID: string
  status: 'OK' | 'FAIL'
  amountType: IAmountTypes
  amountValue: number
  tons: number
  tonsAsText: string
  calculation: string
}
export interface IPTypeCO2Item {
  pTypeID: string
  type: string
  density: number
  amount: number
  tons: number
  texts: {
    calculation: string
    tons: string
  }
}

export interface IOTypeAmountCleaningTaskItem {
  taskID: string
  status: 'OK' | 'FAIL' | 'TYPE-UNKNOWN'
  tons: number
  tonsAsText: string
  calculation: string
}

interface IOnlineTypeConstructor {
  id: string
  categoryID: string
  stackID?: string
  types?: (ProType | ProCoatingType)[]
  innerOnlineTypes?: OnlineType[]
  outerOnlineTypes?: OnlineType[]
  firstTypeID?: string
  lastTypeID?: string
  typeIDs?: string[]
  innerEffectDirection?: string
  outerEffectDirection?: string
  options?: {
    hasMissingTestResults?: boolean
    hasPostponedSample?: boolean
    isCustomType?: boolean
    isRecycable?: boolean
    isHighlighted?: boolean
    isMultiType?: boolean
  }

  stackData: {
    stackType:
      | ''
      | 'solo-isolated'
      | 'solo-partial'
      | 'group-isolated'
      | 'group-partial'
    stackID: string
    stackM2: number
    useStackAmount: boolean
  }

  // WDC data
  wdcID?: string
  innerWDCID?: string // How this online type contaminates inwards
  outerWDCID?: string
}

export default class OnlineType {
  id: string
  projectID
  categoryID: string
  stackID: string
  firstTypeID: string
  lastTypeID: string
  typeIDs: string[]
  dubID = '' // used for matching dublicated instances

  types: (ProType | ProCoatingType)[]
  innerOnlineTypes: OnlineType[]
  outerOnlineTypes: OnlineType[]

  innerEffectDirection: string // MUST NOT be used after OnlineTypes have been merged!
  outerEffectDirection: string // MUST NOT be used after OnlineTypes have been merged!

  options: {
    hasMissingTestResults: boolean
    hasPostponedSample: boolean
    isPotentiallyContaminated: boolean
    isCustomType: boolean
    isRecycable: boolean
    isHighlighted: boolean
    isMultiType: boolean
  }

  stackData: {
    stackType: string
    stackID: string
    stackM2: number
    useStackAmount: boolean
  }

  status: {
    text: string
    className: string
  }

  wdcID: string
  innerWDCID: string
  outerWDCID: string
  finalWDCID = ''
  contaminatedBy: ICombinedContamination[] = []
  potentiallyContaminatedBy: OnlineType[] = []

  innerWDGID = ''
  outerWDGID = ''
  wdgID = ''

  fraction: IResourcesFraction | null = null
  ewcCode: IResourcesEWCCode | null = null

  samples = [] as ProSample[]

  stackIDsToReduceAmountsFrom = [] as string[]

  courseOfAction = 'demolition' as 'demolition' | 'reuse' | 'prevention'

  // Web data
  fractionID = ''
  hashID = ''
  resaleID = ''
  index = {
    primary: NaN,
    secondary: NaN,
  }
  titles = {
    fraction: '',
    category: '',
    types: '',
    complete: '',
  }
  unitIDs = [] as string[]
  unitsAsString = ''

  co2Amounts = {
    amountsPerPType: [] as IPTypeCO2Item[],
    totalTons: 0,
    hasMissingAmounts: false,
  }
  amounts = {
    amountsPerPType: [] as IPTypeAmountItem[],
    amountsPerCleaningTask: [] as IOTypeAmountCleaningTaskItem[],
    totalTons: 0,
    hasMissingAmounts: false,
  }
  disposalCost = {
    totalCost: 0,
    calculation: '',
    hasMissingAmounts: false,
  }

  rooms = {
    roomIDs: [] as string[],
    otherRooms: [] as string[],
    listAsText: '',
  }

  recycleCases = [] as string[]
  specialCases = [] as ISpecialCase[]

  cleaningTasks = [] as CleaningTask[]

  constructor(
    {
      id,
      categoryID,
      stackID,
      firstTypeID,
      lastTypeID,
      typeIDs,

      types,
      innerOnlineTypes,
      outerOnlineTypes,

      innerEffectDirection,
      outerEffectDirection,

      // WDC data
      wdcID,
      innerWDCID,
      outerWDCID,

      options,
      stackData,
    }: IOnlineTypeConstructor,
    projectID: string
  ) {
    this.id = id
    this.projectID = projectID

    this.categoryID = categoryID
    this.stackID = stackID ?? ''
    this.typeIDs = typeIDs ?? []
    this.firstTypeID = firstTypeID ?? ''
    this.lastTypeID = lastTypeID ?? ''

    this.types = types ?? []
    this.innerOnlineTypes = innerOnlineTypes ?? []
    this.outerOnlineTypes = outerOnlineTypes ?? []

    this.innerEffectDirection = innerEffectDirection ?? ''
    this.outerEffectDirection = outerEffectDirection ?? ''

    this.options = {
      hasMissingTestResults: options?.hasMissingTestResults ?? false,
      hasPostponedSample: options?.hasPostponedSample ?? false,
      isPotentiallyContaminated: false,
      isCustomType: options?.isCustomType ?? false,
      isRecycable: options?.isRecycable ?? false,
      isHighlighted: false,
      isMultiType: false,
    }

    this.stackData = {
      stackType: stackData?.stackType ?? '',
      stackID: stackData?.stackID ?? '',
      stackM2: stackData?.stackM2 ?? NaN,
      useStackAmount: stackData?.useStackAmount,
    }

    this.status = {
      text: '',
      className: '',
    }

    this.wdcID = wdcID ?? ''
    this.innerWDCID = innerWDCID ?? ''
    this.outerWDCID = outerWDCID ?? ''
  }

  // Setters
  setAmount = setAmount.bind(this)
  setCourseOfAction = setCourseOfAction.bind(this)
  setAmountFromCleaningTasks = setAmountFromCleaningTasks.bind(this)
  setCustomTypeState = setCustomTypeState.bind(this)
  setCategoryTitle = setCategoryTitle.bind(this)
  setFractionTitle = setFractionTitle.bind(this)
  setHashID = setHashID.bind(this)
  setTypeTitles = setTypeTitles.bind(this)
  setTitles = setTitles.bind(this)
  setDisposalCost = setDisposalCost.bind(this)
  setUnitIDsAndText = setUnitIDsAndText.bind(this)
  setSamples = setSamples.bind(this)
  setRooms = setRooms.bind(this)
  setRecycableState = setRecycableState.bind(this)
  setWDGIDs = setWDGIDs.bind(this)
  setSpecialCases = setSpecialCases.bind(this)
  setOwnWDCIDs = setOwnWDCIDs.bind(this)
  setFractionAndEWCCode = setFractionAndEWCCode.bind(this)
  setFinalWCDID = setFinalWCDID.bind(this)
  setMissingTestResultsStatus = setMissingTestResultsStatus.bind(this)
  setHasPostponedSampleStatus = setHasPostponedSampleStatus.bind(this)
  setMultiTypeStatus = setMultiTypeStatus.bind(this)
  setIsPotentiallyContaminated = setIsPotentiallyContaminated.bind(this)
  setStatus = setStatus.bind(this)
  setCO2 = setCO2.bind(this)

  // Getters
  getTypeComments = getTypeComments.bind(this)
  getInformationTexts = getInformationTexts.bind(this)
  getWorkdescriptionTexts = getWorkdescriptionTexts.bind(this)
  getTestResultsTexts = getTestResultsTexts.bind(this)
  getAmountData = getAmountData.bind(this)
  getCO2AmountData = getCO2AmountData.bind(this)
  getTonsAsText = getTonsAsText.bind(this)
  getTonsTextWithDetails = getTonsTextWithDetails.bind(this)
  getCO2TextWithDetails = getCO2TextWithDetails.bind(this)
  getDisposalCostAsText = getDisposalCostAsText.bind(this)
  getIsNeverAloneStatus = getIsNeverAloneStatus.bind(this)
  getCanBurnStatus = getCanBurnStatus.bind(this)

  // Helper function
  deriveFractionAndEWCCode = deriveFractionAndEWCCode.bind(this)
  getContamination = getContamination.bind(this)
  getInfoTextHeadlineFromTypes = getInfoTextHeadlineFromTypes.bind(this)
}
