import { mixWB } from '@/globals/javascript/_utils/mixins'
import { createHashID } from '@/globals/javascript/_utils/pane-util'
import { IImage } from '@/__types__/_pro/partials/image'
import { ITime } from '@/__types__/_pro/partials/time'
import { proProjectsStore } from '@/store'
import { IProRawProjectSample } from '@/__types__/_pro/raw-project/pro-raw-project-sample'
import {
  setMissingTestResultsStatus,
  setProSampleWDCID,
  setSampleType,
  setSampleTypeID,
  setWebIDs,
} from '@/globals/javascript/models/ProSample/setters'
import {
  getCombinedTypeTitle,
  getFullTitle,
  getOnlineType,
  getOverallResultClass,
  getProCoatingType,
  getProject,
  getReferenceSample,
  getResultLabel,
  getResultNotes,
  getSampledType,
  getStatus,
  getTestList,
} from '@/globals/javascript/models/ProSample/getters'
import { getAnalysisTestListResults } from '@/globals/javascript/models/ProSample/getAnalysisTestListResults'
import {
  getProjectAssessedSamplesStatus,
  getProjectAssessedSamplesStatusSampleItem,
  getProjectPostponedSamplesStatus,
  getProjectPostponedSamplesStatusSampleItem,
  getProjectStandardSamplesStatus,
  getProjectStandardSamplesStatusSampleItem,
  getUsedAnalysisTestGroupsForSample,
} from '@/globals/javascript/models/ProSample/getSampleStatuses'
import { getAnalysisTestGroupSummaries } from '@/globals/javascript/models/ProSample/getAnalysisTestGroupSummaries'

export default class ProSample {
  id: string
  projectID: string
  equivalentNumber: number
  assessNumber: number
  createdAt: ITime
  equivalentSampleID: string
  otherScreeningID: string
  isAutoAssessment: false
  floorPlanUnit: string
  typeID: string
  images = {
    away: {} as IImage,
    close: {} as IImage,
  }
  floorPlanPosition = {
    y: NaN,
    x: NaN,
  }
  sampleNumber: number
  lastUpdated: ITime
  equivalentSampleNumber: number
  kindOfSample: string
  status: string

  existingResultExplanation: string
  existingResultNumber: number
  existingResultWDCID: string

  postponeNumber: number

  // Web data
  sampleTypeID: string
  hashID: string
  webID = {
    type: '',
    origin: '',
  }
  sampleType:
    | 'standard'
    | 'assessed'
    | 'equivalent'
    | 'existing'
    | 'postponed'
    | 'not-defined'
  title = ''
  wdcID: string | null = null // null is for setting wdc-id as unknown
  options = {
    hasMissingTestResult: false,
    isInternalEquivalent: false,
    isAalholmSlotDustSample: false,
  }

  aalholmSlotDustSamples = [61, 73, 79, 81, 90, 91, 94, 95, 98, 99]

  constructor(obj: IProRawProjectSample, projectID: string) {
    this.id = obj.id
    this.hashID = createHashID('S', obj.id)
    this.projectID = projectID
    this.kindOfSample = obj.kindOfSample
    this.status = obj.status
    this.sampleNumber = obj.sampleNumber
    this.otherScreeningID = obj.otherScreeningID
    this.equivalentNumber = obj.equivalentNumber
    this.equivalentSampleID = obj.equivalentSampleID
    this.equivalentSampleNumber = obj.equivalentSampleNumber
    this.existingResultExplanation = obj.existingResultExplanation
    this.existingResultNumber = obj.existingResultNumber
    this.existingResultWDCID = obj.existingResultWDCID
    this.postponeNumber = obj.postponeNumber
    this.assessNumber = obj.assessNumber
    this.isAutoAssessment = obj.isAutoAssessment
    this.floorPlanUnit = obj.floorPlanUnit
    this.typeID = obj.typeID
    this.images = obj.images
    this.floorPlanPosition = obj.floorPlanPosition
    this.createdAt = obj.createdAt
    this.lastUpdated = obj.lastUpdated

    this.sampleType = this.setSampleType()
    this.sampleTypeID = this.setSampleTypeID()
    this.webID = this.setWebIDs()

    this.options.isInternalEquivalent = !!(
      obj.equivalentNumber && !obj.otherScreeningID
    )

    this.options.isAalholmSlotDustSample =
      projectID === 'PXJdGV3OkYZ1dlVT400I' &&
      [61, 73, 79, 81, 90, 91, 94, 95, 98, 99].includes(obj.sampleNumber)
  }

  setWebData() {
    this.setMissingTestResultsStatus()
    this.setProSampleWDCID()
  }

  // Setters
  setSampleType = setSampleType.bind(this)
  setSampleTypeID = setSampleTypeID.bind(this)
  setMissingTestResultsStatus = setMissingTestResultsStatus.bind(this)
  setProSampleWDCID = setProSampleWDCID.bind(this)
  setWebIDs = setWebIDs.bind(this)

  // Getters
  getOnlineType = getOnlineType.bind(this)
  getProject = getProject.bind(this)
  getReferenceSample = getReferenceSample.bind(this)
  getSampledType = getSampledType.bind(this)
  getOverallResultClass = getOverallResultClass.bind(this)
  getResultLabel = getResultLabel.bind(this)
  getResultNotes = getResultNotes.bind(this)

  getProjectStandardSamplesStatus = getProjectStandardSamplesStatus.bind(this)
  getProjectStandardSamplesStatusSampleItem =
    getProjectStandardSamplesStatusSampleItem.bind(this)
  getProjectPostponedSamplesStatus = getProjectPostponedSamplesStatus.bind(this)
  getProjectPostponedSamplesStatusSampleItem =
    getProjectPostponedSamplesStatusSampleItem.bind(this)
  getProjectAssessedSamplesStatus = getProjectAssessedSamplesStatus.bind(this)
  getProjectAssessedSamplesStatusSampleItem =
    getProjectAssessedSamplesStatusSampleItem.bind(this)
  getUsedAnalysisTestGroupsForSample =
    getUsedAnalysisTestGroupsForSample.bind(this)

  getAnalysisTestListResults = getAnalysisTestListResults.bind(this)
  getAnalysisTestGroupSummaries = getAnalysisTestGroupSummaries.bind(this)

  getCombinedTypeTitle = getCombinedTypeTitle.bind(this)
  getFullTitle = getFullTitle.bind(this)

  getStatus = getStatus.bind(this)
  getTestList = getTestList.bind(this)

  getProCoatingType = getProCoatingType.bind(this)

  getProType(useOriginalType = false) {
    const { allPTypes } = proProjectsStore()

    return allPTypes.find((x) => x.id === this.typeID) || null
  }

  getKindOfSampleText() {
    if (this.kindOfSample === 'coating') {
      return mixWB('COATING')
    }

    return mixWB('MATERIAL')
  }
}
