/* eslint-disable no-use-before-define */
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { IStack } from '@/__types__/_pro/partials/stack'
import { IProRawProjectInterConnection } from '@/__types__/_pro/raw-project/pro-raw-project-interConnection'

export const fromStacksToOnlineTypes = (
  stacks: IStack[],
  projectID: string
) => {
  const onlineTypes: OnlineType[] = []

  stacks.forEach((stack) => {
    // If stack only has one layer
    if (!stack.options.isGroup) {
      const { pType } = stack.layers[0]

      const onlineType = new OnlineType(
        {
          id: `${stack.stackID}-${pType.id}`,
          categoryID: pType.categoryID,
          types: [pType],
          stackID: stack.stackID,
          firstTypeID: pType.id,
          lastTypeID: pType.id,
          stackData: {
            stackType: stack.stackType,
            stackID: stack.stackID,
            stackM2: stack.m2 || NaN,
            useStackAmount: stack.options.useStackAmount,
          },
        },
        projectID
      )
      onlineType.courseOfAction = pType.courseOfAction
      onlineTypes.push(onlineType)

      return
    }

    // When stack has multiple layers
    let initNewOnlineType = true

    stack.layers.forEach((layer, index) => {
      // Get Pro type from layer
      const { pType } = layer

      let onlineType = null

      if (initNewOnlineType) {
        onlineType = new OnlineType(
          {
            id: `${stack.stackID}-${pType.id}`,
            categoryID: pType.categoryID,
            stackID: stack.stackID,
            firstTypeID: pType.id,
            stackData: {
              stackType: stack.stackType,
              stackID: stack.stackID,
              stackM2: stack.m2 || NaN,
              useStackAmount: stack.options.useStackAmount,
            },
          },
          projectID
        )

        onlineTypes.push(onlineType)
      } else {
        onlineType = onlineTypes[onlineTypes.length - 1]
        onlineType.id += `-${pType.id}`
      }

      const previousLayerOnlineType = onlineTypes[onlineTypes.length - 2]

      // Connect groups
      const firstInLayer = initNewOnlineType
      const sameStack = previousLayerOnlineType?.stackID === onlineType.stackID

      if (firstInLayer && sameStack) {
        // Add current onlineType to previous layers onlineType
        previousLayerOnlineType.outerOnlineTypes.push(onlineType)
        // Add previous layers onlineType to current onlineType
        onlineType.innerOnlineTypes.push(previousLayerOnlineType)
      }

      onlineType.lastTypeID = pType.id
      onlineType.types.push(pType)

      let courseOfAction = pType.courseOfAction

      // Get previous layer's course of action (from same stack)
      if (previousLayerOnlineType && sameStack) {
        // Set next ProType's courseOfAction if not prevention
        if (previousLayerOnlineType.courseOfAction !== 'prevention') {
          const useProTypeCourseOfAction = ['demolition', 'reuse'].includes(
            previousLayerOnlineType.courseOfAction
          )

          // Set as demolition if not demolition or reuse
          courseOfAction = useProTypeCourseOfAction
            ? previousLayerOnlineType.courseOfAction
            : 'demolition'
        }
      }

      onlineType.courseOfAction = courseOfAction

      // Set inner side effect direction
      if (isSplittable(layer.innerInterConnection)) {
        onlineType.innerEffectDirection =
          layer.innerInterConnection.effectDirection
      }

      // Set outer side effect direction
      if (isSplittable(layer.outerInterConnection)) {
        onlineType.outerEffectDirection =
          layer.outerInterConnection.effectDirection
      }

      const nextLayerProType = stack.layers[index + 1]?.pType

      const canBeSplitFromNextLayer = isSplittable(layer.outerInterConnection)
      const differentCourseOfAction =
        nextLayerProType?.courseOfAction !== onlineType.courseOfAction
      const lastLayerInStack = !nextLayerProType

      initNewOnlineType =
        lastLayerInStack || canBeSplitFromNextLayer || differentCourseOfAction
    })
  })

  return onlineTypes
}

const isSplittable = (
  interConnection: IProRawProjectInterConnection | null
): interConnection is IProRawProjectInterConnection =>
  interConnection?.splitStatus === 'splitable'
