import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default function gaTracking(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  gtag('event', 'page_view', {
    page_title: to.name,
    page_location: window.location.href,
    page_path: to.fullPath,
    send_to: window.gtagID,
  })

  next()
}
