import { translationsStore } from '@/store'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default function setTranslation(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { translationsFethched, getTranslations } = translationsStore()

  if (!translationsFethched) {
    getTranslations()
  }

  next()
}
