import { mixWB } from '@/globals/javascript/_utils/mixins'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default async function setHeadContent(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { translation } = to.meta

  // Update title
  const title = !translation
    ? 'Milva Online'
    : `${mixWB(translation as string)} - Milva Online`

  document.title = title

  // Update robots meta
  const robotsMeta = document.querySelector("[name='robots']")

  if (to.path === '/' && robotsMeta) {
    robotsMeta.remove()
  } else if (!robotsMeta && to.meta.robots) {
    const meta = document.createElement('meta')
    meta.setAttribute('name', 'robots')
    meta.setAttribute('content', to.meta.robots as string)

    document.head.prepend(meta)
  }

  next()
}
