import Apartment from '@/globals/javascript/models/proUnit/Apartment'
import Building from '@/globals/javascript/models/proUnit/Building'
import Floor from '@/globals/javascript/models/proUnit/Floor'
import { orderBy, union } from 'lodash-es'

export const compileUnits = (
  units: (Building | Floor | Apartment)[]
): {
  all: (Building | Floor | Apartment)[]
  active: { all: (Building | Floor | Apartment)[]; buildings: Building[] }
  selected: {
    all: (Building | Floor | Apartment)[]
    buildings: Building[]
    floors: Floor[]
    apartments: Apartment[]
  }
  custom: (Building | Floor | Apartment)[]
} => {
  // Get all active units
  let activeUnits = [] as (Building | Floor | Apartment)[]

  units.forEach((u) => {
    if (!u.isSelected) {
      return
    }

    const containsCurrentUnit = activeUnits.find((x) => x.id === u.id)

    if (containsCurrentUnit) {
      return
    }

    activeUnits.push(u)

    // Get all parent units current apartment is nested in
    if (u instanceof Apartment) {
      const parentUnits = units.filter(
        (x) => !(x instanceof Apartment) && x.apartmentIDs.includes(u.id)
      )

      activeUnits = union(activeUnits, parentUnits)
    }

    // Get all parent units current floor is nested in
    if (u instanceof Floor) {
      const parentUnits = units.filter(
        (x) => x instanceof Building && x.floorIDs.includes(u.id)
      )

      activeUnits = union(activeUnits, parentUnits)
    }
  })

  // Get all selected units
  const allSelected: (Building | Floor | Apartment)[] = []
  const selectedBuildings: Building[] = []
  const selectedFloors: Floor[] = []
  const selectedApartments: Apartment[] = []

  units.forEach((x) => {
    if (!x.isSelected) {
      return
    }

    allSelected.push(x)

    if (x instanceof Building) {
      selectedBuildings.push(x)
    }

    if (x instanceof Floor) {
      selectedFloors.push(x)
    }

    if (x instanceof Apartment) {
      selectedApartments.push(x)
    }
  })

  const titleSort = (building: Building) => building.getFullTitle()
  const activeBuildings = activeUnits.filter(
    (x) => x instanceof Building
  ) as Building[]

  return {
    all: units,
    active: {
      all: activeUnits,
      buildings: orderBy(activeBuildings, titleSort, 'asc'),
    },
    selected: {
      all: allSelected,
      buildings: selectedBuildings,
      floors: selectedFloors,
      apartments: selectedApartments,
    },
    custom: activeUnits.filter((x) => !x.bbrData),
  }
}
