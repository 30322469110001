import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e369d518"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PageGuide" }
const _hoisted_2 = { class: "SlideContainer" }
const _hoisted_3 = { class: "Pagination" }
const _hoisted_4 = { class: "BulletsContainer" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Intro = _resolveComponent("Intro")!
  const _component_Fractions = _resolveComponent("Fractions")!
  const _component_Remediations = _resolveComponent("Remediations")!
  const _component_Types = _resolveComponent("Types")!
  const _component_Samples = _resolveComponent("Samples")!
  const _component_Resale = _resolveComponent("Resale")!
  const _component_Outro = _resolveComponent("Outro")!
  const _component_InlineSVG = _resolveComponent("InlineSVG")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Intro, {
        style: _normalizeStyle({
          transform: `translateX(${_ctx.currentIndex * -100}%)`,
        })
      }, null, 8, ["style"]),
      _createVNode(_component_Fractions, {
        style: _normalizeStyle({
          transform: `translateX(${_ctx.currentIndex * -100}%)`,
        })
      }, null, 8, ["style"]),
      _createVNode(_component_Remediations, {
        style: _normalizeStyle({
          transform: `translateX(${_ctx.currentIndex * -100}%)`,
        })
      }, null, 8, ["style"]),
      _createVNode(_component_Types, {
        style: _normalizeStyle({
          transform: `translateX(${_ctx.currentIndex * -100}%)`,
        })
      }, null, 8, ["style"]),
      _createVNode(_component_Samples, {
        style: _normalizeStyle({
          transform: `translateX(${_ctx.currentIndex * -100}%)`,
        })
      }, null, 8, ["style"]),
      _createVNode(_component_Resale, {
        style: _normalizeStyle({
          transform: `translateX(${_ctx.currentIndex * -100}%)`,
        })
      }, null, 8, ["style"]),
      _createVNode(_component_Outro, {
        style: _normalizeStyle({
          transform: `translateX(${_ctx.currentIndex * -100}%)`,
        })
      }, null, 8, ["style"])
    ]),
    _createElementVNode("nav", _hoisted_3, [
      _createElementVNode("button", {
        class: _normalizeClass(["Button PreviousStep", { IsHidden: _ctx.currentIndex === 0}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPrevButtonClick()))
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_InlineSVG, {
            class: "Icon",
            src: require('@/assets/svg/angle-left.svg')
          }, null, 8, ["src"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('BACK')), 1)
        ])
      ], 2),
      _createElementVNode("ol", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides.count, (n) => {
          return (_openBlock(), _createElementBlock("li", {
            key: n,
            class: _normalizeClass(["Bullet", { IsActive: n - 1 <= _ctx.currentIndex}])
          }, null, 2))
        }), 128))
      ]),
      _createElementVNode("button", {
        class: _normalizeClass(["Button NextStep", { EndStep: _ctx.currentIndex === _ctx.endIndex }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onNextButtonClick()))
      }, [
        _createElementVNode("div", null, [
          (_ctx.currentIndex !== _ctx.endIndex)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('NEXT')), 1),
                _createVNode(_component_InlineSVG, {
                  class: "Icon",
                  src: require('@/assets/svg/angle-right.svg')
                }, null, 8, ["src"])
              ], 64))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.mixWB('LETS_GO')), 1))
        ])
      ], 2)
    ])
  ]))
}