/* eslint-disable no-use-before-define */
import { createRouter, createWebHistory } from 'vue-router'

import setLanguage from '@/router/guards/setLanguage'
import setTranslation from '@/router/guards/setTranslation'
import setHeadContent from '@/router/guards/setHeadContent'
import setHotjar from '@/router/guards/setHotjar'
import setContentData from '@/router/guards/setContentData'
import persistQuery from '@/router/guards/persistQuery'
import checkSWState from '@/router/guards/checkSWState'
import gaTracking from '@/router/guards/gaTracking'
import routes from '@/router/routes'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showCookieBanner: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hideCookieBanner: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj: any
    gtagID: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cloudinary: any
  }
}

/**
 * Hiding consent banner as default until success is determined
 */
window.hideCookieBanner && window.hideCookieBanner()

/**
 * Setup Analytics
 */
gtag('config', window.gtagID, {
  // We do this manually in .beforeEach
  send_page_view: false,
  // Always enable debug mode outside our production site
  debug_mode: window.location.host !== 'online.milva.dk',
})

/**
 * Setting up Router
 */

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
})

/**
 * Route Navigation guard setup
 */

// Reregister ServiceWorker on file change
router.beforeEach(checkSWState)

// Push google page_view
router.beforeEach(gaTracking)

// Maintain query params on route change
router.beforeEach(persistQuery)

// Get and set language
router.beforeEach(setLanguage)

// Get and set translations
router.beforeEach(setTranslation)

// Fetch and set content data
router.beforeEach(setContentData)

// Update <head> content
router.beforeEach(setHeadContent)

// Check to add hotjar
router.beforeEach(setHotjar)

export default router
