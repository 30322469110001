import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore'

import initEmulators from '@/firebase/emulatorSetup'
import { PRO_COLLECTIONS } from '@/firebase/collections'
import { ENV } from '@/globals/javascript/_utils/env'

export interface IFirebaseConfig {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

// Dev config
const firebaseConfigDev: IFirebaseConfig = {
  apiKey: 'AIzaSyA-qi-BZqQzfojByqjQgV3ko8lbP1aVfUg',
  authDomain: 'milva-pro-dev.firebaseapp.com',
  databaseURL: 'https://milva-pro-dev.firebaseio.com',
  projectId: 'milva-pro-dev',
  storageBucket: 'milva-pro-dev.appspot.com',
  messagingSenderId: '686133547589',
  appId: '1:686133547589:web:2b04e3922192fbb301492c',
  measurementId: 'G-EQSH9VZ3GR',
}

// Prod config
const firebaseConfigProd: IFirebaseConfig = {
  apiKey: 'AIzaSyCkkxPMWVLgTVEF7io9MsgtollFjUvpMto',
  authDomain: 'milva-pro-prod.firebaseapp.com',
  databaseURL: 'https://milva-pro-prod.firebaseio.com',
  projectId: 'milva-pro-prod',
  storageBucket: 'milva-pro-prod.appspot.com',
  messagingSenderId: '559861270282',
  appId: '1:559861270282:web:08e8c3f2d3b7c38aca9cf3',
  measurementId: 'G-9MQ9NWPM6M',
}

const firebaseApp = ENV.isDevelopment
  ? initializeApp(firebaseConfigDev)
  : initializeApp(firebaseConfigProd)

const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const functions = getFunctions(firebaseApp, 'europe-west3')

initEmulators(db, storage, functions)

// Activate offline persistance when not on localhost
// Effort to removed "client offline" logs in sentry
if (!ENV.isLocalhost) {
  enableIndexedDbPersistence(db)
}

export { firebaseApp, db, storage, functions, PRO_COLLECTIONS }
