import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8901e26"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "FeedbackReceipt Success"
}
const _hoisted_2 = {
  key: 1,
  class: "FeedbackReceipt Error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.success)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_InlineSVG, {
            src: require('@/assets/svg/checkmark.svg')
          }, null, 8, ["src"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('THANK_YOU_FOR_YOUR_FEEDBACK')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('AN_ERROR_OCCURRED_PLEASE_TRY_AGAIN_LATER')), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}