import { getPotentiallyCO2SavingAmount } from '@/globals/javascript/models/proType/getPotentiallyCO2SavingAmount'
import {
  getAllSampleIDs,
  getCanBurnStatus,
  getCategory,
  getDensity,
  getEWCCode,
  getFraction,
  getMaterial,
  getResourceType,
  getTitle,
  getWDGID,
} from '@/globals/javascript/models/proType/getters'
import { getTonsFromM2 } from '@/globals/javascript/models/proType/getTonsFromM2'
import { getTonsFromRegisteredAmount } from '@/globals/javascript/models/proType/getTonsFromRegisteredAmount'
import {
  hasCoating,
  hasTags,
} from '@/globals/javascript/models/proType/helpers'
import {
  setIsNeverAloneStatus,
  setMissingTestResultsStatus,
  setPCBFromScreeningStatus,
} from '@/globals/javascript/models/proType/setters'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { proProjectsStore } from '@/store'
import {
  IProRawProjectType,
  IProRawProjectTypeCoating,
  IProRawProjectTypeSamples,
} from '@/__types__/_pro/raw-project/pro-raw-project-type'

export class ProType {
  id
  unitIDs
  categoryID
  autoTypeBaseID
  projectID
  originID

  type

  amounts
  images
  places
  comments
  tags
  samples: IProRawProjectTypeSamples
  recycling
  resale
  questionAnswers
  assessments
  coating: IProRawProjectTypeCoating

  // disposal hiearki
  condition
  courseOfAction: 'demolition' | 'reuse' | 'prevention'

  isOwnTypeAsWell
  createdAt
  lastUpdated

  // Web data
  options = {
    hasPCBFromScreening: false,
    hasMissingTestResult: false,
    isSampleRequired: false,
    isCustomType: false,
    isNeverAlone: false,
    isSplitType: false,
  }

  constructor(obj: IProRawProjectType, projectID: string) {
    this.id = obj.id
    this.projectID = projectID
    this.unitIDs = obj.unitIDs
    this.categoryID = obj.categoryID
    this.autoTypeBaseID = obj.autoTypeBaseID
    this.originID = obj.originID ?? ''

    this.type = obj.type

    this.options.isCustomType = !!obj.type.customTypeName

    this.amounts = obj.amounts
    this.images = obj.images
    this.places = obj.places
    this.comments = obj.comments
    this.tags = obj.tags
    this.samples = {
      ...{ manualSampleIDs: [] },
      ...obj.samples,
    }
    this.recycling = obj.recycling
    this.resale = obj.resale
    this.questionAnswers = obj.questionAnswers
    this.assessments = obj.assessments
    this.coating = {
      ...obj.coating,
      ...{
        samples: {
          ...{ manualSampleIDs: [] },
          ...obj.coating.samples,
        },
      },
    }

    this.condition = obj.condition
    this.courseOfAction = obj.courseOfAction

    this.isOwnTypeAsWell = obj.isOwnTypeAsWell
    this.createdAt = obj.createdAt
    this.lastUpdated = obj.lastUpdated
  }

  // Setters
  setWebData() {
    this.setMissingTestResultsStatus()
    this.setPCBFromScreeningStatus()
    this.setIsNeverAloneStatus()
  }

  getConditionText() {
    let text = `${this.condition} - `

    switch (this.condition) {
      case '1':
        text += `${mixWB('VERY_GOOD')}`
        break
      case '2':
        text += `${mixWB('GOOD')}`
        break
      case '3':
        text += `${mixWB('BAD')}`
        break
      case '4':
        text += `${mixWB('VERY_BAD')}`
        break
      default:
        text = `0 - ${mixWB('NOT_ASSESSED')}`
        break
    }

    return text
  }

  setMissingTestResultsStatus = setMissingTestResultsStatus.bind(this)
  setPCBFromScreeningStatus = setPCBFromScreeningStatus.bind(this)
  setIsNeverAloneStatus = setIsNeverAloneStatus.bind(this)

  // Getters
  getAllSampleIDs = getAllSampleIDs.bind(this)
  getTitle = getTitle.bind(this)
  getCategory = getCategory.bind(this)
  getMaterial = getMaterial.bind(this)
  getFraction = getFraction.bind(this)
  getEWCCode = getEWCCode.bind(this)
  getWDGID = getWDGID.bind(this)
  getResourceType = getResourceType.bind(this)
  getDensity = getDensity.bind(this)
  getCanBurnStatus = getCanBurnStatus.bind(this)
  getPotentiallyCO2SavingAmount = getPotentiallyCO2SavingAmount.bind(this)
  getTonsFromRegisteredAmount = getTonsFromRegisteredAmount.bind(this)
  getTonsFromM2 = getTonsFromM2.bind(this)

  getSamples() {
    const { allSamples, allSplitSamples } = proProjectsStore()
    const combinedSamples = [...allSamples, ...allSplitSamples]
    return combinedSamples.filter(
      (s) => s.typeID === this.id && s.kindOfSample === 'material'
    )
  }

  // Helpers
  hasTags = hasTags.bind(this)
  hasCoating = hasCoating.bind(this)
}
