import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { orderBy } from 'lodash-es'

export const compileSamples = (samples: ProSample[]) => {
  const all: ProSample[] = []
  const standard: ProSample[] = []
  const assessed: ProSample[] = []
  const equivalent: ProSample[] = []
  const existing: ProSample[] = []
  const postponed: ProSample[] = []

  samples.forEach((sample) => {
    all.push(sample)

    switch (sample.sampleType) {
      case 'standard':
        standard.push(sample)
        break

      case 'assessed':
        assessed.push(sample)
        break

      case 'equivalent':
        equivalent.push(sample)
        break

      case 'existing':
        existing.push(sample)
        break

      case 'postponed':
        postponed.push(sample)
        break
    }
  })

  return {
    all,
    standard: orderBy(standard, 'sampleNumber', 'asc'),
    assessed: orderBy(assessed, 'assessNumber', 'asc'),
    equivalent: orderBy(equivalent, 'equivalentNumber', 'asc'),
    existing: orderBy(existing, 'existingResultNumber', 'asc'),
    postponed: orderBy(postponed, 'postponeNumber', 'asc'),
  }
}
