import { translationsStore } from '@/store'
import { captureMessage, Severity, withScope } from '@sentry/vue'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default async function setLanguage(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { selectedLanguage, languages, getLanguages } = translationsStore()

  // 1. Check if language is set in store
  if (selectedLanguage.countryCode) {
    next()
    return
  }

  // 2. Check if languages are available
  if (!languages.length) {
    await getLanguages()
  }

  // 3. Get active langauges from store getter once languages are set
  const { activeLanguages } = translationsStore()

  // 4. Set default lang
  let newSelectedLanguage = activeLanguages.find((x) => x.countryCode === 'DK')

  // 5. Check to update from URL query
  const langQuery = to.query?.lang
  if (langQuery) {
    newSelectedLanguage =
      activeLanguages.find((x) => x.locale === langQuery) || newSelectedLanguage
  }

  // 6. Hardcode language if anything else fails
  if (!newSelectedLanguage) {
    newSelectedLanguage = {
      id: 'hard-coded-lang',
      countryCode: 'DK',
      locale: 'da-DK',
      isActive: true,
    }

    // Report to sentry
    withScope((scope) => {
      scope.setLevel(Severity.Critical)
      captureMessage('ERROR: No active languages to choose from')
    })
  }

  // 7. Set selected language
  const { setSelectedLanguage } = translationsStore()
  setSelectedLanguage(newSelectedLanguage)

  // 8. Update URL query
  const query = { ...to.query }
  if (newSelectedLanguage.countryCode === 'DK') {
    delete query.lang
  } else {
    query.lang = newSelectedLanguage.locale
  }

  // 9. Continue
  next({
    ...to,
    query,
  })
}
