
import PageGuideSlide from '@/components/pageGuide/PageGuideSlide.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Intro',
  computed: {
    introHTML() {
      const intro = this.mixWB('GUIDE_SLIDE_INTRO_TEXT_1')

      return intro.replace(/!\*\*/g, '</strong>').replace(/\*\*/g, '<strong>')
    },
  },
  components: { PageGuideSlide, VideoPlayer },
})
