import { ProType } from '@/globals/javascript/models/proType/ProType'
import { IStack } from '@/__types__/_pro/partials/stack'
import { IInterConnectionGroupAmount } from '@/__types__/_pro/raw-project/pro-raw-project-data'

export const setStackM2 = (
  stacks: IStack[],
  interConnectionGroupAmounts: IInterConnectionGroupAmount[],
  types: ProType[]
) => {
  let currentMissing = stacks.filter((x) => !x?.m2).length
  let lastMissing = 0

  while (currentMissing && currentMissing !== lastMissing) {
    lastMissing = currentMissing

    stacks.forEach((stack) => {
      // 1. Pre checks
      // 1.1 Check if m2 is already set
      if (stack?.m2) {
        return
      }

      // 2. Set m2 from stack amount
      if (stack.options.useStackAmount) {
        const stackAmount = interConnectionGroupAmounts.find(
          (x) => x.id === stack.stackID
        )

        if (!stackAmount || !stackAmount.m2) {
          return
        }

        stack.m2 = stackAmount.m2

        return
      }

      // 3. Try and set m2 if possible on each stack
      stack.typeIDs.forEach((typeID) => {
        const pType = types.find((x) => x.id === typeID)

        if (!pType || !pType?.amounts.length) {
          return
        }

        const amount = pType.amounts[0]
        const fullM2 = amount.data.type === 'm2' ? amount.data.m2 : 0

        if (!fullM2) {
          return
        }

        const otherStacks = stacks.filter(
          (x) => x.stackID !== stack.stackID && x.typeIDs.includes(pType.id)
        )

        if (!otherStacks.length) {
          stack.m2 = fullM2
          return
        }

        const { otherStacksCombinedM2 } = otherStacks.reduce(
          (prev, otherStack) => {
            if (!prev.allStacksHasAmounts) {
              return prev
            }

            if (!otherStack.m2) {
              prev.allStacksHasAmounts = false
              prev.otherStacksCombinedM2 = 0
              return prev
            }

            prev.otherStacksCombinedM2 += otherStack.m2
            return prev
          },
          { otherStacksCombinedM2: 0, allStacksHasAmounts: true }
        )

        if (!otherStacksCombinedM2 || fullM2 - otherStacksCombinedM2 <= 0) {
          return
        }

        stack.m2 = fullM2 - otherStacksCombinedM2
      })
    })

    // Update counts
    currentMissing = stacks.filter((x) => !x?.m2).length
  }
}
