import { ProType } from '@/globals/javascript/models/proType/ProType'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { resourcesStore } from '@/store'
import { union } from 'lodash-es'

export function getAllSampleIDs(this: ProType) {
  const pcb = this.options.hasPCBFromScreening ? ['ST-4'] : []

  return union(
    this.samples.sampleIDs,
    pcb,
    this.samples.manualSampleIDs,
    this.assessments.sampleIDs
  )
}

export function getTitle(this: ProType) {
  return this.options.isCustomType
    ? this.type.customTypeName
    : mixWB(this.getResourceType()?.translation ?? '')
}

export function getCategory(this: ProType) {
  const { categoriesAsObject } = resourcesStore()
  return categoriesAsObject[this.categoryID]
}

export function getMaterial(this: ProType) {
  const { materialsAsObject } = resourcesStore()
  const resourceType = this.getResourceType()

  if (!resourceType) {
    return null
  }

  return materialsAsObject[resourceType.materialIDs[0]]
}

export function getFraction(this: ProType, wdcID: string) {
  const { fractionsAsObject } = resourcesStore()
  const material = this.getMaterial()
  const resourceType = this.getResourceType()

  if (!material || !resourceType) {
    return null
  }

  // Get type fraction
  const typeFractionData =
    resourceType.materialData[material.id as unknown as string]

  const typeFraction = Object.values(typeFractionData.fractions.fractions).find(
    (fraction) => fraction.wdcID === wdcID
  )

  // Get material fraction
  const materialfraction = Object.values(material.fractions.fractions).find(
    (fraction) => fraction.wdcID === wdcID
  )

  if (!materialfraction) {
    return null
  }

  // If no type fraction exists, but material fraction does - use material fraction
  if (!typeFraction && materialfraction) {
    return fractionsAsObject[materialfraction.fractionID]
  }

  // Get fraction ID - type fraction overrules material fraction if present
  const fractionID = typeFraction?.fractionID || materialfraction.fractionID

  return fractionsAsObject[fractionID]
}

export function getEWCCode(this: ProType, wdcID: string) {
  const { ewcCodesAsObject } = resourcesStore()
  const material = this.getMaterial()
  const resourceType = this.getResourceType()

  if (!material || !resourceType) {
    return null
  }

  // Get type fraction
  const typeFractionData =
    resourceType.materialData[material.id as unknown as string]

  const typeFraction = Object.values(typeFractionData.fractions.fractions).find(
    (fraction) => fraction.wdcID === wdcID
  )

  // Get material fraction
  const materialfraction = Object.values(material.fractions.fractions).find(
    (fraction) => fraction.wdcID === wdcID
  )

  if (!materialfraction) {
    return null
  }

  // If no type fraction exists, but material fraction does - use material fraction
  if (!typeFraction && materialfraction) {
    return ewcCodesAsObject[materialfraction.ewcID]
  }

  // Get ewc ID - type fraction overrules material fraction if present
  const ewcID = typeFraction?.ewcID || materialfraction.ewcID

  return ewcCodesAsObject[ewcID]
}

export function getWDGID(this: ProType) {
  if (this.type.customTypeWDGID) {
    return this.type.customTypeWDGID
  }

  const rType = this.getResourceType()
  if (rType) {
    return rType.wdgID
  }

  return ''
}

export function getResourceType(this: ProType) {
  const { typesAsObject, categoryTypesAsObject } = resourcesStore()

  if (this.type.typeID) {
    return typesAsObject[this.type.typeID]
  }

  if (this.type.categoryTypeID) {
    const categoryType = categoryTypesAsObject[this.type.categoryTypeID]
    return typesAsObject[categoryType?.typeID] ?? null
  }

  return null
}

export function getDensity(this: ProType) {
  let density = NaN

  // Check amount
  const amount = this.amounts[0]
  if (!amount) {
    return NaN
  }

  // Get density
  const material = this.getMaterial()
  if (material) {
    density = material.metaData.density.value
  } else {
    density = amount?.data?.customDensity || NaN
  }

  return density
}

export function getCanBurnStatus(this: ProType) {
  const hazardousFraction = this.getFraction('WDC-3')
  return hazardousFraction && hazardousFraction.id === 'F-9'
}
