import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import { mixContaminationClass } from '@/globals/javascript/_utils/mixins'
import { resourcesStore } from '@/store'
import { IAnalysisTestGroupSummmary } from '@/__types__/proSample'

export function getAnalysisTestGroupSummaries(
  this: ProSample | ProSplitSample
): IAnalysisTestGroupSummmary[] {
  const usedAnalysisTestGroupsIDsForSample =
    this.getUsedAnalysisTestGroupsForSample()

  const analysisTestListResults = this.getAnalysisTestListResults()

  const { analysisTestGroupsAsArray } = resourcesStore()

  const summary = analysisTestGroupsAsArray.reduce(
    (prev, analysisTestGroup) => {
      const item: IAnalysisTestGroupSummmary = {
        id: analysisTestGroup.id,
        result: null,
        class: 'NotAnalysed',
        analysisTests: [],
      }

      prev.push(item)

      if (!usedAnalysisTestGroupsIDsForSample.includes(analysisTestGroup.id)) {
        return prev
      }

      let allHasValues = true
      let tempResult: null | 0 | 1 | 2 = null
      analysisTestGroup.analysisTestIDs.forEach((analysisTestID) => {
        if (!allHasValues) {
          return
        }

        const listResult = analysisTestListResults.find(
          (x) => x.id === analysisTestID
        )
        if (!listResult || listResult.result === null) {
          allHasValues = false
          return
        }

        if (tempResult === null) {
          tempResult = listResult.result
        } else if (listResult.result > tempResult) {
          tempResult = listResult.result
        }

        item.analysisTests.push({
          id: listResult.id,
          class: listResult.class,
          text: `${listResult.title}: ${listResult.text}`,
        })
      })

      if (allHasValues) {
        item.result = tempResult
        item.class = mixContaminationClass(tempResult)
      }

      return prev
    },
    [] as IAnalysisTestGroupSummmary[]
  )

  return summary
}
