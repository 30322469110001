import OnlineType, {
  ICombinedContamination,
} from '@/globals/javascript/models/onlineType/OnlineType'
import { ProCoatingType } from '@/globals/javascript/models/proCoatingType/ProCoatingType'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { deriveWDCID, isProType } from '@/globals/javascript/_utils/util'
import { IInfoText } from '@/__types__/utils/infoText'
import { IWDCID } from '@/__types__/utils/wdcID'
import { resourcesStore } from '@/store'
import { IResourcesEWCCode } from '@/__types__/_pro/resources/resources-ewcCode'
import { IResourcesFraction } from '@/__types__/_pro/resources/resources-fraction'
import { union } from 'lodash-es'

export function deriveFractionAndEWCCode(this: OnlineType) {
  const data = {
    ewcCode: null as IResourcesEWCCode | null,
    fraction: null as IResourcesFraction | null,
  }

  /**
   * If only a single ProType OR ProCoatingType is present in OnlineType
   */
  if (this.types.length === 1) {
    const [pType] = this.types

    data.fraction = pType.getFraction(this.finalWDCID)
    data.ewcCode = pType.getEWCCode(this.finalWDCID)

    return data
  }

  /**
   * Retrive list of ProTypes
   * - from this point on ProCoatingType has no influence on the fraction
   */

  // Find all ProTypes which CAN be alone
  let proTypes = this.types.filter(
    (x) => isProType(x) && !x.getResourceType()?.options.isNeverAlone
  ) as ProType[]

  // If all ProTypes available has "isNeverAlone" reset variable to contain all protypes
  if (proTypes.length === 0) {
    proTypes = this.types.filter((x) => isProType(x)) as ProType[]
  }

  /**
   * If only a single ProType is present in OnlineType
   */
  if (proTypes.length === 1) {
    const pType = proTypes[0]

    data.fraction = pType.getFraction(this.finalWDCID)
    data.ewcCode = pType.getEWCCode(this.finalWDCID)

    return data
  }

  /**
   * If ProTypes have different fractions
   */
  const { fractionsAsObject, ewcCodesAsObject } = resourcesStore()
  const relatedHazardousFractions = proTypes
    .map((p) => p.getFraction('WDC-3'))
    .filter(Boolean) as IResourcesFraction[]

  // Check if ProTypes Hazardous fraction is combustable
  const allFractionsCombustable = relatedHazardousFractions
    .map((f) => f.id)
    .every((x) => ['F-5', 'F-9'].includes(x))

  const containsAsbestos = ['WDC-4', 'WDC-5'].includes(this.finalWDCID)
  const isHazardous = ['WDC-3'].includes(this.finalWDCID)

  /**
   * Different fractions, with asbestos (combustable and non-combustable)
   * Fraktion: Deponi, Asbest (F-12)
   * EAK: 17 06 06
   */
  if (containsAsbestos) {
    data.fraction = fractionsAsObject['F-12']
    data.ewcCode = ewcCodesAsObject['170606']

    return data
  }

  /**
   * Different fractions, non-combustable fraction present, hazardous
   * Fraktion: Deponi, specialbehandling (F-8)
   * EAK: Andet bygnings- og nedrivningsaffald (herunder blandet affald) indeholdende farlige stoffer, (17 09 03)
   */
  if (!allFractionsCombustable && isHazardous) {
    data.fraction = fractionsAsObject['F-8']
    data.ewcCode = ewcCodesAsObject['170903']

    return data
  }

  /**
   * Different fractions, non-combustable fraction present, clean / contaminated
   * Fraktion: Deponi (F-7)
   * EAK: Blandet bygnings- og nedrivningsaffald, bortset fra affald henhørende under 17 09 01, 17 09 02 og 17 09 03, (17 09 04)
   */
  if (!allFractionsCombustable) {
    data.fraction = fractionsAsObject['F-7']
    data.ewcCode = ewcCodesAsObject['170904']

    return data
  }

  /**
   * Different fractions, all combustable, hazardous
   * Fraktion: Brandbart, specialbehandling (F-9)
   * EAK: Andet bygnings- og nedrivningsaffald (herunder blandet affald) indeholdende farlige stoffer, (17 09 03)
   */
  if (allFractionsCombustable && isHazardous) {
    data.fraction = fractionsAsObject['F-9']
    data.ewcCode = ewcCodesAsObject['170903']

    return data
  }

  /**
   * Different fractions, all combustable, clean / contaminated
   * Fraktion: Brandbart (F-5)
   * EAK: Blandet bygnings- og nedrivningsaffald, bortset fra affald henhørende under 17 09 01, 17 09 02 og 17 09 03, (17 09 04)
   */
  if (allFractionsCombustable) {
    data.fraction = fractionsAsObject['F-5']
    data.ewcCode = ewcCodesAsObject['170904']

    return data
  }

  return data
}

export function getContamination(
  this: OnlineType,
  adjecent: OnlineType,
  location: 'inner' | 'outer'
) {
  let isContaminatedByAdjecent = false

  // Check if type can be cleaned (Then it can't be contaminated)
  const wdgIDToCheck = location === 'inner' ? this.innerWDGID : this.outerWDGID

  if (wdgIDToCheck === 'WDG-2') {
    return isContaminatedByAdjecent
  }

  // Check if contamination is possible
  if (
    location === 'inner' &&
    !['both', 'right'].includes(this.innerEffectDirection)
  ) {
    return isContaminatedByAdjecent
  }
  if (
    location === 'outer' &&
    !['both', 'left'].includes(this.outerEffectDirection)
  ) {
    return isContaminatedByAdjecent
  }

  const otherWDCID =
    location === 'inner' ? adjecent.outerWDCID : adjecent.innerWDCID

  const returnObj: ICombinedContamination = {
    type: adjecent,
    wdcID: deriveWDCID([this.wdcID as IWDCID, otherWDCID as IWDCID]),
    side: location,
  }

  isContaminatedByAdjecent = returnObj.wdcID !== this.wdcID

  return isContaminatedByAdjecent ? returnObj : false
}

export function getInfoTextHeadlineFromTypes(
  this: OnlineType,
  pTypes: (ProType | ProCoatingType)[]
): IInfoText {
  const typeNames = pTypes.reduce((prev: string[], pType) => {
    prev = union(prev, [pType.getTitle()])
    return prev
  }, [])

  return {
    type: 'header',
    text: typeNames.join(' / '),
  }
}
