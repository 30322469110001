import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6052cf05"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ErrorPage" }
const _hoisted_2 = { class: "Content" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "Message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InlineSVG, {
        src: require('@/assets/logo/milva-logo-dark.svg')
      }, null, 8, ["src"]),
      _createElementVNode("strong", null, _toDisplayString(_ctx.mixWBPreRender('ONLINE_REPORT')), 1),
      (_ctx.errorState.message !== 'ROOT')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.errorState.message)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "Message",
                  innerHTML: _ctx.errorState.message
                }, null, 8, _hoisted_3))
              : (_ctx.errorState.message !== null)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.mixWBPreRender('AN_ERROR_OCCURRED_PLEASE_TRY_AGAIN_LATER')), 1))
                : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}