/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getCommentsOnProjectTitle,
  getProjectDateText,
  getProjectBeingConductedByText,
  getProjectDoneByText,
  getProjectStartingDateText,
  getProjectStartedStatusText,
} from '@/globals/javascript/models/ProProject/getAlternateTexts'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import ProTestResult from '@/globals/javascript/models/ProTestResult'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import Apartment from '@/globals/javascript/models/proUnit/Apartment'
import Building from '@/globals/javascript/models/proUnit/Building'
import Floor from '@/globals/javascript/models/proUnit/Floor'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { resourcesStore } from '@/store'
import { IProRawProject } from '@/__types__/_pro/raw-project/pro-raw-project'
import { IProRawProjectAssessedSamplesStatus } from '@/__types__/_pro/raw-project/pro-raw-project-assessedSamplesStatus'
import { IProRawProjectPostponedSamplesStatus } from '@/__types__/_pro/raw-project/pro-raw-project-postponedSamplesStatus'
import { IProRawProjectSample } from '@/__types__/_pro/raw-project/pro-raw-project-sample'
import { IProRawProjectStandardSamplesStatus } from '@/__types__/_pro/raw-project/pro-raw-project-standardSamplesStatus'
import { IProRawProjectTestResult } from '@/__types__/_pro/raw-project/pro-raw-project-testResult'
import { IProRawProjectType } from '@/__types__/_pro/raw-project/pro-raw-project-type'
import { IAnalysisTestGroup } from '@/__types__/_pro/resources/resources-analysisTestGroup'
import { sortBy, union } from 'lodash-es'

export class ProProject {
  id
  data
  types: ProType[]
  metaData
  units: (Building | Floor | Apartment)[]
  interConnections
  addressImages
  samples: ProSample[]
  splitSamples: ProSplitSample[]
  pcbScreenings
  testResults: ProTestResult[]
  standardSamplesStatus: null | IProRawProjectStandardSamplesStatus
  postponedSamplesStatus: null | IProRawProjectPostponedSamplesStatus
  assessedSamplesStatus: null | IProRawProjectAssessedSamplesStatus
  wasteItems
  mapper

  // Web data
  status = {
    text: '',
    className: '',
    code: '',
  }

  usedAnalysisTestGroups: IAnalysisTestGroup[] = []

  constructor(project: IProRawProject) {
    this.id = project.id
    this.data = project.data
    this.types = this.convertTypes(project.types, project.id)
    this.metaData = project.metaData

    this.units = this.convertUnits(project.units)

    this.interConnections = project.interConnections
    this.addressImages = project.addressImages
    this.samples = this.convertSamples(project.samples, project.id)
    this.splitSamples = []
    this.pcbScreenings = project.pcbScreenings
    this.testResults = this.convertTestResults(project.testResults, project.id)
    this.standardSamplesStatus = project?.standardSamplesStatus?.length
      ? project.standardSamplesStatus[0]
      : null
    this.postponedSamplesStatus = project?.postponedSamplesStatus?.length
      ? project.postponedSamplesStatus[0]
      : null
    this.assessedSamplesStatus = project?.assessedSamplesStatus?.length
      ? project.assessedSamplesStatus[0]
      : null
    this.wasteItems = project.wasteItems
    this.mapper = project.mapper
  }

  // Setters
  setProjectWebData() {
    this.setStatus()
    this.setUsedAnalysisGroups()
  }

  setStatus() {
    const { data } = this

    if (!data.isStarted) {
      this.status.text = mixWB('STATUS_NOT_STARTED')
      this.status.className = 'NotStarted'
      this.status.code = 'not-started'
    } else if (!data.isCompleted && !data.isTestDataUploaded) {
      this.status.text = this.getProjectStartedStatusText()
      this.status.className = 'Ongoing'
      this.status.code = 'ongoing'
    } else if (!data.isCompleted) {
      this.status.text = mixWB('UNDER_REVISION')
      this.status.className = 'UnderRevision'
      this.status.code = 'under-revision'
    } else if (!data.isTestDataUploaded) {
      this.status.text = mixWB('STATUS_AWAITING_TEST_RESULTS')
      this.status.className = 'AwaitingTestResults'
      this.status.code = 'awaiting-test-results'
    } else {
      this.status.text = mixWB('STATUS_REPORT_READY')
      this.status.className = 'ReportReady'
      this.status.code = 'report-ready'
    }
  }

  setUsedAnalysisGroups() {
    const { analysisTestGroupsAsArray } = resourcesStore()

    let combinedIDList: string[] = []

    // Get ID's from standard samples
    if (this.standardSamplesStatus) {
      combinedIDList = union(
        combinedIDList,
        this.standardSamplesStatus.usedAnalysisTestGroupsIDs
      )
    }

    // Get ID's from assessed samples
    if (this.assessedSamplesStatus) {
      combinedIDList = union(
        combinedIDList,
        this.assessedSamplesStatus.usedAnalysisTestGroupsIDs
      )
    }

    // Get ID's from external equivalent samples
    const externalEquivalentSamples = this.samples.filter(
      (x) => x.equivalentNumber && x.otherScreeningID
    )

    externalEquivalentSamples.forEach((sample) => {
      const standardSamplesItem =
        sample.getProjectStandardSamplesStatusSampleItem()

      if (!standardSamplesItem) {
        return
      }

      combinedIDList = union(
        combinedIDList,
        standardSamplesItem.requiredAnalysisTestGroupIDs
      )
    })

    // Get final list of used analysis groups
    const usedAnalysisGroups = combinedIDList.reduce(
      (prev, analysisTestGroupID) => {
        if (analysisTestGroupID === 'cpScreening') {
          return prev
        }

        const analysisTestGroup = analysisTestGroupsAsArray.find(
          (x) => x.id === analysisTestGroupID
        )

        if (analysisTestGroup) {
          prev.push(analysisTestGroup)
        }
        return prev
      },
      [] as IAnalysisTestGroup[]
    )

    // Order and set the list
    this.usedAnalysisTestGroups = sortBy(usedAnalysisGroups, 'order')
  }

  getCaseNumber() {
    const { data } = this
    return data.customCaseNumber || data.caseNumber
  }

  getProfileImage() {
    return this.addressImages[0]?.overview?.profile?.[0] ?? null
  }

  // Getters - alternate texts
  getCommentsOnProjectTitle = getCommentsOnProjectTitle.bind(this)
  getProjectBeingConductedByText = getProjectBeingConductedByText.bind(this)
  getProjectDateText = getProjectDateText.bind(this)
  getProjectDoneByText = getProjectDoneByText.bind(this)
  getProjectStartingDateText = getProjectStartingDateText.bind(this)
  getProjectStartedStatusText = getProjectStartedStatusText.bind(this)

  private convertTypes(types: IProRawProjectType[], projectID: string) {
    return types.map((x) => new ProType(x, projectID))
  }

  private convertUnits(units: any[]): (Building | Floor | Apartment)[] {
    return units.reduce((prev, unit) => {
      if (unit.type === 'building') {
        prev.push(new Building(unit, this.id))
      }
      if (unit.type === 'floor') {
        prev.push(new Floor(unit, this.id))
      }
      if (unit.type === 'apartment') {
        prev.push(new Apartment(unit, this.id))
      }

      return prev
    }, [])
  }

  private convertSamples(samples: IProRawProjectSample[], projectID: string) {
    return samples.map((x) => new ProSample(x, projectID))
  }

  private convertTestResults(
    testResults: IProRawProjectTestResult[],
    projectID: string
  ) {
    return testResults.map((x) => new ProTestResult(x, projectID))
  }
}
