/* eslint-disable no-use-before-define */
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import ProSplitSample from '@/globals/javascript/models/ProSplitSample'
import {
  mixContaminationClass,
  mixFormatNumber,
  mixWB,
} from '@/globals/javascript/_utils/mixins'
import { resourcesStore } from '@/store'
import { IProAnalysisTestListResult } from '@/__types__/_pro/raw-project/pro-raw-project-standardSamplesStatus'

interface IAnalysisTestListResult {
  id: string
  class: string
  result: null | 0 | 1 | 2
  title: string
  text: string
}

export function getAnalysisTestListResults(
  this: ProSample | ProSplitSample
): IAnalysisTestListResult[] {
  if (
    !['standard', 'equivalent', 'assessed', 'postponed'].includes(
      this.sampleType
    )
  ) {
    return []
  }

  const { cleanAnalysisTestsAsArray } = resourcesStore()

  let analysisTestListResults: IProAnalysisTestListResult[] | null = null

  if (['standard', 'equivalent'].includes(this.sampleType)) {
    const standardSamplesItem = this.getProjectStandardSamplesStatusSampleItem()

    analysisTestListResults =
      standardSamplesItem?.analysisTestListResults || null
  }

  if (this.sampleType === 'assessed') {
    const assessedSamplesItem = this.getProjectAssessedSamplesStatusSampleItem()
    analysisTestListResults =
      assessedSamplesItem?.analysisTestListResults || null
  }

  if (this.sampleType === 'postponed') {
    const postponedSamplesItem =
      this.getProjectPostponedSamplesStatusSampleItem()
    analysisTestListResults =
      postponedSamplesItem?.analysisTestListResults || null
  }

  const analysisTestResultList = cleanAnalysisTestsAsArray.reduce(
    (prev, analysisTest) => {
      const analysisTestListResult: IAnalysisTestListResult = {
        id: analysisTest.id,
        class: 'NotAnalysed',
        result: null,
        title: mixWB(analysisTest.translation),
        text: mixWB('NOT_ANALYZED_SHORT'),
      }
      prev.push(analysisTestListResult)

      if (!analysisTestListResults?.length) {
        return prev
      }

      // Ålholm Slots historiske støv prøver
      if (this.options.isAalholmSlotDustSample) {
        return prev
      }

      const listResult = analysisTestListResults.find(
        (x) => x.id === analysisTest.id
      )

      if (!listResult) {
        return prev
      }

      // Check for not analysed
      if (listResult.options.isNotAnalysed) {
        return prev
      }

      // Check for awaiting
      if (listResult.options.isAwaiting) {
        analysisTestListResult.class = 'Awaiting'
        analysisTestListResult.text = mixWB('AWAITING')

        return prev
      }

      // Check for postponed
      if (listResult.options.isPostponed) {
        analysisTestListResult.class = 'Postponed'
        analysisTestListResult.text = mixWB('POSTPONED')

        return prev
      }

      // Check for assessed
      if (listResult.options.isAssessed) {
        analysisTestListResult.class = 'Hazardous'
        analysisTestListResult.text = mixWB('ASSESSED')
        analysisTestListResult.result = listResult.result
        return prev
      }

      // Check for too low to measure
      if (listResult.options.isTooLowToMeasure) {
        analysisTestListResult.text = mixWB('BELOW_THE_LIMIT')
      }

      // Show proven / not proven
      else if (listResult.testType === 'prove-based') {
        analysisTestListResult.text = listResult.options.isProven
          ? mixWB('PROVEN')
          : mixWB('NOT_PROVEN')
      }

      // Show value of value-based tests
      else {
        let text = `${mixFormatNumber(listResult.value ?? NaN, 2)} ${
          listResult.unitType === 'mg/kg' ? mixWB('UNIT_MG_PR_KG') : ''
        }`

        if (listResult.options.isBelowValue) {
          text = `< ${text}`
        }
        if (listResult.options.isAboveValue) {
          text = `> ${text}`
        }

        analysisTestListResult.text = text
      }

      // Set contamination class
      analysisTestListResult.class = mixContaminationClass(listResult.result)
      analysisTestListResult.result = listResult.result

      return prev
    },
    [] as IAnalysisTestListResult[]
  )

  // Check for split pro sample - inner adhesive
  if (this.typeID.includes('adhesive-inner')) {
    return analysisTestResultList.reduce((prev, analysisTestResult) => {
      if (analysisTestResult.id !== 'ASB') {
        analysisTestResult.class = 'NotAnalysed'
        analysisTestResult.result = null
        analysisTestResult.text = mixWB('NOT_ANALYZED_SHORT')
      }

      prev.push(analysisTestResult)
      return prev
    }, [] as IAnalysisTestListResult[])
  }

  // Check for split pro sample - outer adhesive
  if (this.typeID.includes('adhesive-outer')) {
    return analysisTestResultList.reduce((prev, analysisTestResult) => {
      if (analysisTestResult.id === 'ASB') {
        analysisTestResult.class = 'NotAnalysed'
        analysisTestResult.result = null
        analysisTestResult.text = mixWB('NOT_ANALYZED_SHORT')
      }

      prev.push(analysisTestResult)
      return prev
    }, [] as IAnalysisTestListResult[])
  }

  return analysisTestResultList
}
