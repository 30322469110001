
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'LoadingTextChanger',
  props: {
    texts: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: -1,
    }
  },
  watch: {
    texts: {
      // Waiting for a new text to be added, then apply the active class
      handler(to) {
        this.updateActiveTextIndex(to)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateActiveTextIndex(texts: string[]) {
      const waitForNewElement = setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const innerWrap: any = this.$refs.InnerWrap

        if (!innerWrap) {
          return
        }

        const count = innerWrap.childElementCount

        if (count - 1 === this.activeIndex) {
          return
        }

        clearInterval(waitForNewElement)
        this.activeIndex = texts.length - 1
      }, 10)
    },
  },
  created() {
    if (this.texts.length) {
      this.updateActiveTextIndex(this.texts)
    }
  },
})
