import { ENV } from './env'

const useDevHost = ENV.isDevelopment

// Cloud function
const compileCloudFunctionHost = () => {
  // Get correct baseURL
  const emulators = process.env.VUE_APP_EMULATORS
  let baseURL = ''

  if (
    emulators &&
    (emulators.indexOf('all') > -1 || emulators.indexOf('functions') > -1)
  ) {
    baseURL = 'http://localhost:5001/milva-pro-dev/europe-west3'
  } else {
    baseURL = useDevHost
      ? 'https://europe-west3-milva-pro-dev.cloudfunctions.net'
      : 'https://europe-west3-milva-pro-prod.cloudfunctions.net'
  }

  return baseURL
}

// Cloudinary
const cloudinary = useDevHost
  ? 'https://milva.mo.cloudinary.net/dev'
  : 'https://milva.mo.cloudinary.net/prod'

// Firestore console
const firestore =
  'https://console.firebase.google.com/u/0/project/milva-pro-dev/firestore/data'

// Firestore console
const firestorage =
  'https://console.firebase.google.com/project/milva-pro-dev/storage/milva-pro-dev.appspot.com/files'

// Milva Online
const online = useDevHost
  ? 'https://dev-online.milva.dk'
  : 'https://online.milva.dk'

export const HOST = {
  cloudFunction: compileCloudFunctionHost(),
  cloudinary,
  firestore,
  firestorage,
  online,
} as const
