export const PRO_COLLECTIONS = {
  DB_ACCOUNTS: 'Accounts',
  DB_ADDRESS_IMAGES: 'AddressImages',
  DB_BUILDINGS: 'Buildings',
  DB_CHANGELOGS: 'Changelogs',
  DB_COUNTRIES: 'Countries',
  DB_INTER_CONNECTIONS: 'InterConnections',
  DB_META_DATA: 'MetaData',
  DB_PCB_SCREENINGS: 'PCBScreenings',
  DB_REPORTS: 'Reports',
  DB_SAMPLES: 'Samples',
  DB_SCREENINGS: 'Screenings',
  DB_STATS: 'Stats',
  DB_TEST_RESULTS: 'TestResults',
  DB_TYPES: 'Types',
  DB_UNITS: 'Units',
  DB_USERS: 'Users',
  DB_LANGUAGES: 'Languages',
  DB_TRANSLATIONS: 'Translations',
  DB_RESOURCES: 'Resources',
} as const
