import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { orderOTypesByFullTitle } from '@/store/modules/current/helpers/orderOTypesByFullTitle'
import { union } from 'lodash-es'

export const compileRemdiationGroups = (
  onlineTypes: OnlineType[]
): {
  withoutCleaning: OnlineType[]
  withCleaning: OnlineType[]
  potential: OnlineType[]
} => {
  let samplesBase: ProSample[] = []
  let withoutCleaning: OnlineType[] = []
  let withCleaning: OnlineType[] = []
  let potential: OnlineType[] = []

  onlineTypes.forEach((oType) => {
    // If no cleaning tasks present
    if (['prevention', 'reuse'].includes(oType.courseOfAction)) {
      return
    }

    // Contains potential cleaning task(s)
    if (
      oType.options.hasPostponedSample ||
      oType.options.hasMissingTestResults ||
      oType.options.isPotentiallyContaminated
    ) {
      potential = union(potential, [oType])
      return
    }

    // Determine if onlinetype is with cleaning or without cleaning
    const isToBeRemoved = oType.cleaningTasks.find(
      (x) => x.toBeRemoved.id === oType.id
    )

    // OnlineType is clean and has no toBeRemoved cleaning tasks
    if (oType.finalWDCID === 'WDC-1' && !isToBeRemoved) {
      return
    }

    // Get list of unique samples related to remediation onlinetypes
    samplesBase = union(samplesBase, oType.samples)

    isToBeRemoved
      ? (withCleaning = union(withCleaning, [oType]))
      : (withoutCleaning = union(withoutCleaning, [oType]))
  })

  return {
    withoutCleaning: orderOTypesByFullTitle(withoutCleaning),
    withCleaning: orderOTypesByFullTitle(withCleaning),
    potential: orderOTypesByFullTitle(potential),
  }
}
