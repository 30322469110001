import { createPinia } from 'pinia'

// Firebase data stores.
export { default as proProjectsStore } from '@/store/modules/pro-projects/pro-projects'
export { default as resourcesStore } from '@/store/modules/resources/resources'
export { default as translationsStore } from '@/store/modules/translations/translations'
export { default as countriesStore } from '@/store/modules/countries/countries'

// Features or page stores
export { default as paradigmStore } from '@/store/modules/paradigm/paradigm'
export { default as filterStore } from '@/store/modules/filter/filter'
export { default as pdfStore } from '@/store/modules/pdf/pdf'
export { default as paneStore } from '@/store/modules/pane/pane'
export { default as galleryStore } from '@/store/modules/gallery/gallery'

export { default as currentStore } from '@/store/modules/current/current'
export { default as appStore } from '@/store/app'

export const pinia = createPinia()
