import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { createHashID } from '@/globals/javascript/_utils/pane-util'

export interface INotice {
  id: 'IS_SHOWN_ALONE_NOTICE'
  typeTitle?: string
  fractionTranslation?: string
}

export class CleaningTask {
  id: string
  hashID: string
  toBeCleaned?: OnlineType
  toBeRemoved: OnlineType
  side: 'inner' | 'outer'
  notice?: INotice
  m2: number

  constructor({
    toBeCleaned,
    toBeRemoved,
    side,
    notice,
    m2,
  }: {
    toBeCleaned?: OnlineType
    toBeRemoved: OnlineType
    side: 'inner' | 'outer'
    notice?: INotice
    m2: number
  }) {
    this.id = toBeCleaned
      ? `cleaning-${toBeCleaned.id}+${toBeRemoved.id}`
      : `cleaning-no-type-to-be-cleaned+${toBeRemoved.id}`
    this.hashID = createHashID('CT', this.id)
    this.toBeCleaned = toBeCleaned
    this.toBeRemoved = toBeRemoved
    this.side = side
    this.notice = notice
    this.m2 = m2 ?? NaN
  }
}
