import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60cd8312"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "onload", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.selection.src)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "VideoPlayer",
        style: _normalizeStyle([{"padding":"51.4% 0 0 0","position":"relative"}, _ctx.width === 'full' ? 'width: 100%' : ''])
      }, [
        _createElementVNode("iframe", {
          src: _ctx.selection.src,
          onload: _ctx.onLoaded,
          frameborder: "0",
          allow: "autoplay; fullscreen; picture-in-picture",
          allowfullscreen: "",
          style: {"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},
          title: _ctx.selection.title
        }, null, 8, _hoisted_1)
      ], 4))
    : _createCommentVNode("", true)
}