import { proProjectsStore } from '@/store'
import { IProRawProjectCoatingType } from '@/__types__/_pro/raw-project/pro-raw-project-coatingType'

import {
  IProRawProjectTypeCoating,
  IProRawProjectTypeSamples,
} from '@/__types__/_pro/raw-project/pro-raw-project-type'
import {
  setMissingTestResultsStatus,
  setPCBFromScreeningStatus,
} from '@/globals/javascript/models/proCoatingType/setters'
import {
  getAllSampleIDs,
  getCanBurnStatus,
  getCategory,
  getDensity,
  getEWCCode,
  getFraction,
  getMaterial,
  getTitle,
  getWDGID,
} from '@/globals/javascript/models/proCoatingType/getters'
import { getTonsFromM2 } from '@/globals/javascript/models/proCoatingType/getTonsFromM2'
import { getTonsFromRegisteredAmount } from '@/globals/javascript/models/proCoatingType/getTonsFromRegisteredAmount'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { getPotentiallyCO2SavingAmount } from '@/globals/javascript/models/proCoatingType/getPotentiallyCO2SavingAmount'

export class ProCoatingType {
  id
  projectID
  originID
  unitIDs
  categoryID
  amounts
  images
  places
  comments
  tags
  samples: IProRawProjectTypeSamples
  answers

  coating: IProRawProjectTypeCoating

  wdgID = 'WDG-3'
  materialID = 'M-51'
  isOwnTypeAsWell = false

  // disposal hiearki
  condition
  courseOfAction: 'demolition' | 'reuse' | 'prevention'

  // Web data
  options = {
    hasPCBFromScreening: false,
    hasMissingTestResult: false,
    isSampleRequired: false,
  }

  constructor(obj: IProRawProjectCoatingType) {
    this.id = obj.id
    this.projectID = obj.projectID
    this.originID = obj.originID
    this.unitIDs = obj.unitIDs
    this.categoryID = obj.categoryID
    this.amounts = obj.amounts
    this.coating = {
      ...obj.coating,
      ...{
        samples: {
          ...{ manualSampleIDs: [] },
          ...obj.coating.samples,
        },
      },
    }
    this.images = obj.images
    this.places = obj.places
    this.comments = obj.comments
    this.tags = obj.tags
    this.samples = {
      ...{ manualSampleIDs: [] },
      ...obj.samples,
    }
    this.answers = obj.answers

    this.condition = obj.condition
    this.courseOfAction = obj.courseOfAction

    // Set web data
    this.setMissingTestResultsStatus()
    this.setPCBFromScreeningStatus()
  }

  getConditionText() {
    let text = `${this.condition} - `

    switch (this.condition) {
      case '1':
        text += `${mixWB('VERY_GOOD')}`
        break
      case '2':
        text += `${mixWB('GOOD')}`
        break
      case '3':
        text += `${mixWB('BAD')}`
        break
      case '4':
        text += `${mixWB('VERY_BAD')}`
        break
      default:
        text += `${mixWB('NOT_ASSESSED')}`
        break
    }

    return text
  }

  // Setters
  setMissingTestResultsStatus = setMissingTestResultsStatus.bind(this)
  setPCBFromScreeningStatus = setPCBFromScreeningStatus.bind(this)

  // Getters
  getAllSampleIDs = getAllSampleIDs.bind(this)
  getTitle = getTitle.bind(this)
  getCategory = getCategory.bind(this)
  getMaterial = getMaterial.bind(this)
  getFraction = getFraction.bind(this)
  getEWCCode = getEWCCode.bind(this)
  getWDGID = getWDGID.bind(this)
  getDensity = getDensity.bind(this)
  getCanBurnStatus = getCanBurnStatus.bind(this)
  getTonsFromRegisteredAmount = getTonsFromRegisteredAmount.bind(this)
  getTonsFromM2 = getTonsFromM2.bind(this)
  getPotentiallyCO2SavingAmount = getPotentiallyCO2SavingAmount.bind(this)

  getSamples() {
    return proProjectsStore().allSamples.filter(
      (s) => s.typeID === this.originID && s.kindOfSample === 'coating'
    )
  }

  // Helpers
  hasTags(tags: string[] = []) {
    return tags.length === 0
      ? false
      : tags.every((tag) => this.tags.tagIDs.includes(tag))
  }
}
