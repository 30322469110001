/* eslint-disable no-use-before-define */
import { ProType } from '@/globals/javascript/models/proType/ProType'
import {
  stringsAppearingOnce,
  uniqueStrings,
} from '@/globals/javascript/_utils/util'
import { IStack, IStackLayer } from '@/__types__/_pro/partials/stack'
import { IProRawProjectInterConnection } from '@/__types__/_pro/raw-project/pro-raw-project-interConnection'
import { union } from 'lodash-es'

export const compileStacks = (
  interConnections: IProRawProjectInterConnection[],
  pTypes: ProType[]
) => {
  let usedTypeIDs: string[] = []

  const uniqueInterConnectionIDs = interConnections
    .map((x) => x.groupID)
    .filter(uniqueStrings)

  const stacks: IStack[] = []

  // Find types that exists in a stack with other types
  uniqueInterConnectionIDs.forEach((stackID) => {
    const stackInterConnections = interConnections.filter(
      (x) => x.groupID === stackID
    )

    const firstTypeID = extractFirstTypeID(stackInterConnections)
    const stack = cleanStackData()
    const { layers, typeIDs, categoryIDs, unitIDs } = layerData(
      firstTypeID,
      stackInterConnections,
      pTypes
    )

    usedTypeIDs = union(usedTypeIDs, typeIDs)

    stack.stackID = stackID
    stack.stackType = 'group-isolated'
    stack.layers = layers
    stack.typeIDs = typeIDs
    stack.categoryIDs = categoryIDs
    stack.unitIDs = unitIDs
    stack.options.isGroup = true

    stacks.push(stack)
  })

  // Find types that are in a stack with other types, but also are by themselves
  usedTypeIDs.forEach((usedID) => {
    const pType = pTypes.find((pType) => pType.id === usedID)

    if (!pType || !pType.isOwnTypeAsWell) {
      return
    }

    const stack = cleanStackData()
    stack.stackID = pType.id
    stack.stackType = 'solo-partial'
    stack.options.isOwnTypeAsWell = true
    stack.layers.push({
      pType,
      innerInterConnection: null,
      outerInterConnection: null,
    })
    stack.typeIDs = [pType.id]
    stack.categoryIDs = [pType.categoryID]
    stack.unitIDs = [...pType.unitIDs]

    stacks.push(stack)
  })

  // Find types that are only by themselves in a stack
  pTypes.forEach((pType) => {
    if (usedTypeIDs.includes(pType.id)) {
      return
    }

    const stack = cleanStackData()
    stack.stackID = pType.id
    stack.stackType = 'solo-isolated'
    stack.options.isAllByItSelf = true
    stack.layers.push({
      pType,
      innerInterConnection: null,
      outerInterConnection: null,
    })
    stack.typeIDs = [pType.id]
    stack.categoryIDs = [pType.categoryID]
    stack.unitIDs = [...pType.unitIDs]

    stacks.push(stack)
  })

  // Update stackType for stacks containing types used in multiple stacks
  stacks.forEach((stack) => {
    if (!stack.stackType.includes('group-')) {
      return
    }

    const otherStack = stacks.find(
      (x) =>
        x.stackID !== stack.stackID &&
        stack.typeIDs.some((typeID) => x.typeIDs.includes(typeID))
    )

    if (otherStack) {
      stack.stackType = 'group-partial'
    }
  })

  return stacks
}

/**
 * HELPER FUNCTIONS
 */

const extractFirstTypeID = (
  interConnections: IProRawProjectInterConnection[]
): string => {
  const allConnectionsRelatedIDs = interConnections
    .map((x) => [x.innerTypeID, x.outerTypeID])
    .flat(1)

  const typeIDsAppearingOnce = stringsAppearingOnce(allConnectionsRelatedIDs)

  const innerMostTypeID = typeIDsAppearingOnce.find((id) =>
    interConnections.find((ic) => ic.innerTypeID === id)
  ) as string

  return innerMostTypeID
}

const cleanStackData = (): IStack => {
  const data: IStack = {
    stackID: '',
    stackType: '',
    layers: [],
    categoryIDs: [],
    typeIDs: [],
    unitIDs: [],
    options: {
      isGroup: false,
      isOwnTypeAsWell: false,
      isAllByItSelf: false,
      useStackAmount: false,
    },
    m2: NaN,
  }

  return JSON.parse(JSON.stringify(data))
}

const layerData = (
  typeID: string,
  interConnections: IProRawProjectInterConnection[],
  pTypes: ProType[],
  layers: IStackLayer[] = []
) => {
  let pType = pTypes.find((pType) => pType.id === typeID) ?? null
  let outerConnection: IProRawProjectInterConnection | null
  let innerConnection: IProRawProjectInterConnection | null

  while (pType) {
    outerConnection =
      interConnections.find((ic) => ic.innerTypeID === pType?.id) ?? null

    innerConnection =
      interConnections.find((ic) => ic.outerTypeID === pType?.id) ?? null

    layers.push({
      pType,
      innerInterConnection: innerConnection,
      outerInterConnection: outerConnection,
    } as IStackLayer)

    // Apply new type for next while iteration
    pType =
      pTypes.find((pType) => pType.id === outerConnection?.outerTypeID) ?? null
  }

  // Set ids based on types in layers
  const { typeIDs, categoryIDs, unitIDs } = layers.reduce(
    (prev, layer: IStackLayer) => {
      prev.typeIDs = union(prev.typeIDs, [layer.pType?.id ?? ''])
      prev.categoryIDs = union(prev.categoryIDs, [
        layer.pType?.categoryID ?? '',
      ])
      prev.unitIDs = union(prev.unitIDs, layer.pType?.unitIDs)

      return prev
    },
    {
      typeIDs: [] as string[],
      categoryIDs: [] as string[],
      unitIDs: [] as string[],
    }
  )

  return {
    layers,
    typeIDs,
    categoryIDs,
    unitIDs,
  }
}
